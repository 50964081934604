
import React, { useEffect, useState } from 'react'
import TagDetails from './TagDetails.js'
import API from 'util/Api'
import ActionsTable from 'components/ActionsTable/TableList'
import { Formik } from 'formik'
import CircularProgress from 'components/Loader';
import SnackBar from 'components/SnackBar';
import * as Yup from "yup";

const SignupSchema = Yup.object().shape({
    name: Yup.string()
        .required('Required'),
    location: Yup.array()
    .required('Location is Required').min(1),
  });

const Tags = (props) => {

    const [config,setConfig] = useState(null)
    const [values,setValues] = useState(null)
    const [tag,setTag] = useState(null)
    const [tagsList,setTagsList] = useState(null)
    const [snackBar,setSnackBar] = useState({open:false,msg:'',type:'error'});
    const [resetF,setResetF] = useState(false);
    const saveTag = (e,{setSubmitting}) => {
        console.log("save config",e)
        setSubmitting(true)
        API.post("locationTags",JSON.stringify(e)).then((res) => {
            console.log("saveTag", res.data);
            setSnackBar({open:true,msg:res.data.msg,type:'success'});
            setTagsList(res.data.data)
            resetTag()
            setSubmitting(false)
            
        })
        .catch((err) => {
            setSubmitting(false)

            try{
                setSnackBar({open:true,msg:err.response.data.msg,type:'error'});
            }catch{
                setSnackBar({open:true,msg:"Something went wrong",type:'error'});
            }
            // got an error
            console.log("configuration api error",err.response)
        })
    }

    const resetTag = () => {
        // todo: do we need to do anything here?
        setTag(null);
        setResetF(true);
        console.log("reset form ")        
    }
    useEffect(()=>{
        getLocTags()
    },[])
    const getLocTags = () =>{
        
        API.get("locationTags")
        .then((res) => {
            console.log("tags", JSON.stringify(res.data.data))
             setConfig(Object.values(res.data.data.config))
             setTagsList(res.data.data.Tags)

            let data = {}
            for(let key in res.data.data.config){
                data[key]  = res.data.data.config[key].value
            }
            console.log('values',data);
            setValues(data)
        })
        .catch((err) => {
            console.log("err",err);
        })
    }
    const handleTagEdit = (clickedUser) => {
        console.log("handleNfcAdminEdit ",clickedUser)
        setTag(clickedUser)
        // scroll to top 
        document.body.style.height = 'auto'            
            setTimeout(function(){
                document.body.style.height = '100%'
        },50)
    }

    return(
        <>
        { config && values &&
        <Formik
            onSubmit={saveTag}
            onReset={resetTag}
            initialValues={values}
            validationSchema={SignupSchema}
            enableReinitialize={true}
        >
            <>
            <TagDetails
                config={config}
                tag={tag}
                resetFormik={resetF}
                setFalse={()=>{setResetF(false)}}
            />        
            {tagsList &&
            <div className="row">
                <ActionsTable 
                    tableHeading = "Location Tags" 
                    table={tagsList} 
                    onEdit = {handleTagEdit}
                    actions={true} 
                />
            </div>
            }
            <SnackBar 
                open={snackBar.open} 
                handleClose={()=>{setSnackBar({open:false,msg:""})}}
                message={snackBar.msg}
                type={snackBar.type}
            />
            </>
        </Formik>        
        }
        {
            !config && !values && <CircularProgress/>
        }        
        </>
    )
}

export default Tags;