import React from 'react';
import {Route, Switch} from 'react-router-dom';

import ParkingLocation from "./routes/ParkingLocation";
import ParkingConfiguration from "./routes/ParkingLocation/ParkingConfiguration";
import TariffConfiguration from "./routes/ParkingLocation/Tariffs";
import ParkingSlots from "./routes/ParkingLocation/ParkingSlots";
import Printers from "./routes/Printers";
import BoomRelays from "./routes/BoomRelay";
import ParkingStaff from "./routes/ParkingStaff";
import ConsoleUsers from "./routes/ConsoleUsers";
import Holidays from "./routes/Holidays";
import NfcAdmin from "./routes/NfcAdmin";
import Tags from "./routes/Tags";
import LedConfig from "./routes/LEDConfig"
import Controller from "./routes/Controller"
import ControllerV2 from "./routes/ControllerV2"
import ControllerConfig from "./routes/ControllerV2/ControllerConfig"
import UpdateFirmware from "./routes/ControllerV2/UpdateFirmware"
import DeploymentHistory from './routes/ControllerV2/DeploymentHistory';
import DeviceData from "./routes/DeviceData"
import VisitorsPass from "./routes/VisitorsPass"
import UploadBuild from "./routes/UploadBuild"
import CloseActiveBookings from './routes/CloseActiveBookings';
import ParkingSearch from './routes/ParkingSearch';
import UpdateRateModel from './routes/UpdateRateModel';
import FastagTester from './routes/FastagTester';
import ParkingMaintenance from './routes/ParkingMaintenance';
import APIThrottle from './routes/APIThrottle'
import FasTagError from './routes/FasTagError'
import InvoiceTracking from './routes/InvoiceTracking';
import DeviceUsers from './routes/DeviceUsers';
import PragatiMaidan from './routes/PragatiMaidan';
import CheckinAppUser from './routes/CheckinAppUser';
import SearchTag from './routes/SearchTag';
import FormatReport from './routes/FormatReport';

const Resources = ({match}) => (
	  <div className="app-wrapper">
	    <Switch>
	    	<Route path={`${match.url}/parking-locations/tariffs`} component={TariffConfiguration}/>
            <Route path={`${match.url}/parking-locations/configuration`} component={ParkingConfiguration}/>
            <Route path={`${match.url}/parking-locations/slots/:id`} component={ParkingSlots}/>
	        <Route path={`${match.url}/parking-locations`} component={ParkingLocation}/>
	        <Route path={`${match.url}/printers`} component={Printers}/>
	        <Route path={`${match.url}/boom-relay`} component={BoomRelays}/>
	        <Route path={`${match.url}/parking-staff`} component={ParkingStaff}/>         
	        <Route path={`${match.url}/console-users`} component={ConsoleUsers}/>
			<Route path={`${match.url}/holidays`} component={Holidays}/>
			<Route path={`${match.url}/nfc-admin`} component={NfcAdmin}/>
			<Route path={`${match.url}/tags`} component={Tags}/>
			<Route path={`${match.url}/led-panel`} component={LedConfig}/>
			<Route path={`${match.url}/controller-v2/deployment-history`} component={DeploymentHistory}/>
			<Route path={`${match.url}/controller-v2/update-firmware`} component={UpdateFirmware}/>
			<Route path={`${match.url}/controller-v2/:controllerId/:name/:type`} component={ControllerConfig}/>
			<Route path={`${match.url}/controller-v2`} component={ControllerV2}/>
			<Route path={`${match.url}/controller`} component={Controller}/>
			<Route path={`${match.url}/device-data`} component={DeviceData}/>
			<Route path={`${match.url}/visitors-pass`} component={VisitorsPass}/>
			<Route path={`${match.url}/controller-firmwares`} component={UploadBuild}/>
			<Route path={`${match.url}/close-active-bookings`} component={CloseActiveBookings}/>
			<Route path={`${match.url}/parking-search`} component={ParkingSearch}/>
			<Route path={`${match.url}/update-rate`} component={UpdateRateModel}/>
			<Route path={`${match.url}/fastag-tester`} component={FastagTester}/>
			<Route path={`${match.url}/parking-maintenance`} component={ParkingMaintenance}/>
			<Route path={`${match.url}/api-throttle`} component={APIThrottle}/>
			<Route path={`${match.url}/fastag-error`} component={FasTagError}/>
			<Route path={`${match.url}/invoice-tracking`} component={InvoiceTracking}/>
			<Route path={`${match.url}/device-users`} component={DeviceUsers}/>
			<Route path={`${match.url}/pragati-maidan`} component={PragatiMaidan}/>
			<Route path={`${match.url}/checkinapp-users`} component={CheckinAppUser}/>
			<Route path={`${match.url}/search-tag`} component={SearchTag}/>
			<Route path={`${match.url}/format-report`} component={FormatReport}/>

			{/* <Route path={`${match.url}/rate-models`} component={EVChargingRateModel}/> */}
	     {/* <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/> */}
	    </Switch>

	     </div>
);

export default Resources;
