import React, { useEffect, useState } from "react";
import { Formik, Form, connect, FieldArray } from "formik";
import { Button, Box, Typography } from "@material-ui/core";
import FormField from "components/FormField";
import CardBox from "components/CardBox";
import SnackBar from "components/SnackBar";
import _ from "lodash";
import ContainerHeader from "components/ContainerHeader";
import * as Yup from "yup";
import API from "../../../../../util/Api";
import { saveAs } from "file-saver";

const ReleaseNotes = ({ notes }) => {
  console.log("release-notes", notes);
  return (
    <div className="row">
      <CardBox styleName="col-12">
        <>
          {notes.map((item, index) => (
            <div key={`v-${index}`}>
              <Typography variant="subtitle1">{item.title}</Typography>
              <Typography variant="caption">{item.date}</Typography>
              <ul className="mt-2">
                {item.notes.map(
                  (note, i) =>
                    note && <li key={`note-${i}`}>{_.upperFirst(note)}</li>
                )}
              </ul>
            </div>
          ))}
        </>
      </CardBox>
    </div>
  );
};

const ConfigDetails = connect((props) => {
  console.log("config-details-props", props);
  useEffect(() => {
    if (props.formik.values.version) {
      let config = _.cloneDeep(props.config);
      config.fields.type.options = props.formik.values.version.value;
      props.setConfig(config);
      props.formik.setFieldValue('type', '');
    }
  }, [props.formik.values.version]);

  const downloadFile = () => {
    console.log("download-props", props);
    const file = props.formik.values.type.value ? props.formik.values.type.value : {};
    props.formik.setTouched({ version: true, type: true });
    props.formik.validateForm();
    props.formik.setSubmitting(true);
    if (props.formik.dirty) {
      API.get("firmwareDownload", {
        params: { file_path: file.gz ? file.gz : file.bin },
      })
        .then((res) => {
          saveAs(res.data.data);
          props.formik.setSubmitting(false);
        })
        .catch((err) => {
          console.log("catch", err);
        });
    }
  };

  return (
    <div className="row">
      <CardBox styleName="col-12" heading="">
        <Form>
          <div className="row">
            {Object.values(props.config.fields).map((field, index) => (
              <div className="col-md-3 col-12" key={`field-${field.key}`}>
                <FormField field={{ ...field, ...{ name: field.key } }} />
              </div>
            ))}
          </div>
          {/* BUTTONS */}
          <div className="row mt-2">
            <div className="col-lg-6 col-sm-12 col-12">
              <Box mt="20px">
                <Box mr="10px" display="inline">
                  <Button
                    variant="contained"
                    color="primary"
                    mr="5px"
                    type="submit"
                  >
                    {" "}
                    Submit{" "}
                  </Button>
                </Box>
                <Box mr="10px" display="inline">
                  <Button
                    variant="outlined"
                    onClick={downloadFile}
                    color="secondary"
                    type="button"
                  >
                    Download
                  </Button>
                </Box>
                <Box mr="10px" display="inline">
                  <Button variant="outlined" color="secondary" type="reset">
                    Reset
                  </Button>
                </Box>
              </Box>
            </div>
          </div>
        </Form>
      </CardBox>
    </div>
  );
});

const UpdateFirmware = (props) => {
  console.log("update-firmware-props", props);
  const [config, setConfig] = useState();
  const [snackBar, setSnackBar] = useState({
    open: false,
    msg: "",
    type: "error",
  });
  const validations = Yup.object().shape({
    version: Yup.object().required("Required"),
    type: Yup.object().required("Required"),
  });

  const handleSubmit = (e, { setSubmitting }) => {
    setSubmitting(true);
    API.post(
      "controllerFirmware",
      JSON.stringify({ ...e, controller_id: props.location.state.id })
    )
      .then((res) => {
        setSubmitting(false);
        setSnackBar({ open: true, msg: res.data.msg, type: "success" });
      })
      .catch((err) => {
        setSnackBar({
          open: true,
          message: err?.response?.data?.msg
            ? err.response.data.msg
            : "Something went wrong",
          type: "error",
        });
        setSubmitting(false);
        console.log("catch", err);
      });
  };

  useEffect(() => {
    API.get("controllerFirmware", {
      params: { controller_id: props.location.state.id },
    })
      .then((res) => {
        setConfig(res.data.data);
      })
      .catch((err) => {
        console.log("catch", err);
      });
  }, []);

  return (
    <>
      {config && <ContainerHeader title={config?.title} match={props.match} />}
      {config && (
        <Formik
          onSubmit={handleSubmit}
          initialValues={config.values}
          validationSchema={validations}
        >
          <ConfigDetails
            setConfig={setConfig}
            config={config}
          />
        </Formik>
      )}
      {config && config.release_notes.length > 0 && (
        <ReleaseNotes notes={config.release_notes} />
      )}
      <SnackBar
        open={snackBar.open}
        handleClose={() => {
          setSnackBar({ open: false, msg: "" });
        }}
        message={snackBar.msg}
        type={snackBar.type}
      />
    </>
  );
};
export default UpdateFirmware;
