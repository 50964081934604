
import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import LedConfigDetails from './LedConfigDetails'
import SimpleTable from 'components/ActionsTable/SimpleTable'
import CircularProgress from 'components/Loader'
import SnackBar from 'components/SnackBar'
import CardBox from 'components/CardBox'
import {Box,TextField,Grid} from '@material-ui/core'
import MsgPopover from 'components/MsgPopover'
import {SwapHoriz} from '@material-ui/icons'
import API from 'util/Api'
import * as Yup from "yup";
import _ from "lodash";
import PopUpDialog from 'components/PopUpDialog';
import {demoData} from './demo'

const LedConfigSchema =(config)=>{
    return Yup.lazy(values=>{
        return Yup.object().shape({
            name: Yup.string()
                .min(2, 'Too Short!')
                .required('Required'),
            device_id: Yup.string()
                .required('Required'),
            // led_version: Yup.string()
            //     .required('Required'),
            location_id: Yup.object()
                 .required('Required'),
            type: Yup.string()
                .required('Required'),
            row: Yup.string()
                .required('Required'),
            col: Yup.string()
                .required('Required'),
            config:Yup.array().of(
                Yup.object().shape({
                    // desc: Yup.string().required("Desc is required"),
                    type: Yup.string()
                      .required("Type is requried"),
                  })
            ).min(1, "Need at least a row")
          
        });
    });

}

const DialogBox = (props) =>{
    console.log("Fversion props",props)
    const [ip, setIP]=useState(props.clicked.ip);
    return(
        <Grid container>
            <Grid item xs={5}>
            <TextField id="standard-basic" label="IP Address" name="ip" value={ip} onChange={e=>{
                console.log('dialog-form',e)
                setIP(e.target.value)
                props.setDialogData({[e.target.name]:e.target.value,ledId:props.clicked.id})
            }} />
            </Grid>
        </Grid>
    )
}

//fixme: currently a copy of console users , if no major changes required please use a single component
const LEDConfig = (props) => {
    console.log("LEDConfig",props)
    const [config,setConfig] = useState(null)
    const [values,setValues] = useState(null)
    const [ledConfig,setLedConfig] = useState(null)
    const [ledConfigList,setLedConfigList] = useState(null)
    const [resetF,setResetF] = useState(false);
    const [snackBar,setSnackBar] = useState({open:false,msg:'',type:'error'});
    const [dialog,setDialog] = useState({open:false,content:'',header:'',agreeBtnTxt:'',disAgreeBtnTxt:'',type:'',callback:null});
    const [dialogData, setDialogData]=useState({});
    const formatLedConfigList = (ledConfig)=>{
        let tBody = []
            for(let body of ledConfig.body) {
                console.log("tBody",body)
                tBody.push(body.tableList)
            }
            let table = {
                ...ledConfig,
                'tbody':tBody
            }
            setLedConfigList(table)
    }
    const resetLedConfig = () => {
        
        setLedConfig(null)
        setResetF(true)
        console.log("reset form ")        
    }
    const saveStaff = (e,{setSubmitting}) => {
        
        API.post("ledConfig",JSON.stringify(e))
        .then((res) => {
            console.log("ledpanelpost",res.data.data)
            resetLedConfig()
            formatLedConfigList(res.data.data.ledConfig)
            setSnackBar({open:true,msg:res.data.msg,type:'success'});
            
        })
        .catch((err) => {
            try{
                setSnackBar({open:true,msg:err.response.data.msg,type:'error'});
            }catch{
                setSnackBar({open:true,msg:"Something went wrong",type:'error'});
            }
            console.log("catch",err);
        })
        setSubmitting(false)
    }

    const getLedList=()=>{
        API.get("ledConfig").then((res) => {
            console.log("ledConfigGet",res.data.data)
            setConfig(res.data.data.config.sections)
            let sections = res.data.data.config.sections
            let data = {}
            for(let section of sections){
                console.log("section",section)
                if(section.type==='fields'){
                    section.fields.map(field=>{
                        data[field.key]  = field.value
                    })
                    
                }else if(section.type==='fieldArray'){
                    data[section.key] = []
                    section.multiValues.map(field=>{
                        console.log("section.key",section.key,field)
                        data[section.key].push(field)
                    })
                }
                    
            }
            console.log("setValues",data,sections)
            setValues(data)
            formatLedConfigList(res.data.data.ledConfig)
        })
        .catch((err) => {
            console.log("catch",err);
        })
    }
    useEffect(()=>{
        getLedList()
    },[])

    const handleStaffEdit = (clickedId) => {
        let ledConfSections = _.find(ledConfigList.body,{id:clickedId}).sections
        console.log("handleStaffEdit ",clickedId,ledConfSections)
        let data = {}
        for(let section of ledConfSections){
            console.log("section",section)
            if(section.type==='fields'){
                section.fields.map(field=>{
                    data[field.key]  = field.value
                })
                
            }else if(section.type==='fieldArray'){
                data[section.key] = []
                section.multiValues.map(field=>{
                    console.log("section.key",section.key,field)
                    data[section.key].push(field)
                })
            }
                
        }
        data.id=clickedId
        console.log("setValues",data)
        setLedConfig(data)
        // scroll to top 
        document.body.style.height = 'auto'            
            setTimeout(function(){
                document.body.style.height = '100%'
        },50)
    }
    const changeStatus = (id,status,cb) =>{

        API.post("changeLedStatus",{ledId:id,status:status}).then((res) => {
            // const list = _.cloneDeep(ledConfigList)
            // let led = _.find(list.tbody,{id:id})
            // led.active = status
            // const tBody = _.unionBy(led,list.tbody)
            // list.tbody = tBody
            // setLedConfigList(list)
            cb(status)
        }).catch(err=>{})
        handleDialogClose()
    }
    const onUpdate = (clicked)=>{
        console.log("sendCommand",clicked)
        setDialog({
            open:true,
            header:"Update IP Address",
            content:<DialogBox clicked={clicked} setDialogData={setDialogData} />,
            agreeBtnTxt:'Update',
            disAgreeBtnTxt:'Cancel',
            type: 'ledIPUpdate'
        })
    }

    const statusUpdate = (id, checked, cb) => {
        let ledPanel = _.find(ledConfigList.tbody, (ledConfig) => ledConfig.id === id);
        setDialog({
            open:true,
            header:`${checked == true ? 'Activate': 'Deactivate'} LED Panel`,
            content:`Are you sure you want to ${checked ? 'activate': 'deactivate'} ${ledPanel.name}?`,
            agreeBtnTxt:'Confirm',
            disAgreeBtnTxt:'Cancel',
            type:'ledPanelStatus',
            ledPanel: {id:id, active:checked},
            callback:cb
        })
    }

    const confirmDialogSubmit = (type) =>{
        if(type === 'ledIPUpdate'){
            handleDialogSubmit()
        } else if  (type === 'ledPanelStatus'){
            changeStatus(dialog.ledPanel.id, dialog.ledPanel.active, dialog.callback)
        }
    }

    const handleDialogSubmit=()=>{
        console.log("agreeClicked",dialogData)
        if(Object.keys(dialogData).length>0){
            API.put('ledConfig',JSON.stringify(dialogData))
            .then((res) => {
                handleDialogClose()
                if(res.data.msg){
                    setSnackBar({open:true,msg:res.data.msg,type:'success'});
                }
                getLedList()
            }).catch(err=>{
                handleDialogClose()
                setSnackBar({
                    open: true,
                    msg: err?.response?.data?.msg
                      ? err.response.data.msg
                      : "Something went wrong",
                    type: "error",
                  });
            })
        }        
    }

    const handleDialogClose = ()=>{
        setDialog({
            open:false,
            content:'',
            header:'',
            agreeBtnTxt:'',
            disAgreeBtnTxt:'',
            type:'',
            callback:null
        });
    }
    console.log("demoData.config",ledConfigList)
    return(
        <>
        { config && values &&
        <Formik
            onSubmit={saveStaff}
            onReset={resetLedConfig}
            initialValues={values}
            validationSchema={LedConfigSchema(config)}
            resetInitalValue={true}
        >
            <LedConfigDetails
                ledConfig={ledConfig}
                config={config}
                resetFormik={resetF}
                setFalse={()=>{console.log('setResetF');setResetF(false)}}
            />
        </Formik>        
        }
        {
            !config && !values && <CircularProgress/>
        }
        {
        ledConfigList&& 
            <div className="row">
                <CardBox styleName="col-12" headerOutside heading={
                <React.Fragment>
                {ledConfigList.title}
                    <MsgPopover msg='Big table, scroll horizontally' timeout='1500'>
                    <Box display={{xs:'inline',md:'none'}}><SwapHoriz color="primary" /></Box></MsgPopover>
                </React.Fragment>}>                        
                        <SimpleTable 
                            table={ledConfigList} 
                            onEdit={handleStaffEdit}
                            changeStatus={statusUpdate}
                            updateIP={onUpdate}
                        />
                </CardBox>
            </div>
        }
        <SnackBar 
            open={snackBar.open} 
            handleClose={()=>{setSnackBar({open:false,msg:""})}}
            message={snackBar.msg}
            type={snackBar.type}
        /> 
        <PopUpDialog 
            fullWidth
            open={dialog.open}
            header={dialog.header}
            content ={dialog.content}
            agreeBtnTxt ={dialog.agreeBtnTxt}
            disAgreeBtnTxt={dialog.disAgreeBtnTxt}
            agreeClicked ={ () =>confirmDialogSubmit(dialog.type, dialog.user)}
            handleDialogClose={handleDialogClose}
        />       
        </>
    )
}

export default LEDConfig