import React, { useEffect, useState } from "react";
import API from "util/Api";
import { Formik } from "formik";
import SnackBar from "components/SnackBar";
import { Form, connect } from "formik";
import { Button, Box } from "@material-ui/core";
import FormField from "components/FormField";
import ContainerHeader from "components/ContainerHeader";
import _ from "lodash";
import CardBox from "components/CardBox";
import * as Yup from "yup";
import ReportTable from "components/ReportTable/index";

const MeetingForm = connect((props) => {
    console.log('MeetingForm-props',props)
    useEffect(()=>{
        if(props.formik.values.location){
            let config = _.cloneDeep(props.config);
            config.fields.sub_location.options = props.formik.values.location.subLocations;
            props.setConfig(config);
            props.formik.setFieldValue('sub_location',"");
        }
    },[props.formik.values.location])
    return (
        <div className="row">
            <CardBox styleName="col-12" heading="Book Meeting Slot">
                <Form>
                    {/* FIRST ROW */}
                    <div className="row">
                        {Object.values(props.config.fields).map((field) => (
                            field && <div className="col-md-3 col-12" key={`field-${field.key}`}>
                                <FormField field={{ ...field, ...{ name: field.key } }} />
                            </div>
                        ))}
                    </div>
                    {/* BUTTONS */}
                    <div className="row mt-2">
                        <div className="col-lg-6 col-sm-12 col-12">
                            <Box mt="20px">
                                <Box mr="10px" display="inline">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        mr="5px"
                                        type="submit"
                                    >
                                        {` Submit `}
                                    </Button>
                                </Box>
                                <Box mr="10px" display="inline">
                                    <Button variant="outlined" color="secondary" type="reset">
                                        Reset
                                    </Button>
                                </Box>
                            </Box>
                        </div>
                    </div>
                </Form>
            </CardBox>
        </div>
    );
})

const MeetingSlot = (props) => {
    const [config, setConfig] = useState(null);
    const [meetingList, setMeetingList] = useState();
    const [snackBar, setSnackBar] = useState({
        open: false,
        message: "",
        type: "error",
    });
    const validations = Yup.object().shape({
        location: Yup.object().required("Required"),
        sub_location: Yup.object().required("Required"),
        start_time: Yup.string().required("Required"),
        duration: Yup.string().required("Required"),
    });

    const handleSubmit = (data, form) => {
        API.post("meetingSlots", JSON.stringify(data))
            .then((res) => {
                form.setSubmitting(false);
                form.resetForm();
                setMeetingList(res.data.data.meetingList)
                setSnackBar({
                    open: true,
                    message: res.data.msg,
                    type: "success",
                });
            })
            .catch((err) => {
                console.log("error", err);
                form.setSubmitting(false);
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong",
                    type: "error",
                });
            });
    };

    useEffect(() => {
        API.get("meetingSlots")
            .then((res) => {
                setConfig(res.data.data.config);
                setMeetingList(res.data.data.meetingList)
            })
            .catch((err) => {
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong",
                    type: "error",
                });
            });
    }, []);

    return (
        <>
            {config && <ContainerHeader
                title={config.title}
                match={props.match}
            />}
            {config && (
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={{...config.values, ['start_time']:new Date()}}
                    validationSchema={validations}
                >
                    <MeetingForm config={config} setConfig={setConfig} />
                </Formik>
            )}

            {meetingList && (
                <ReportTable
                    data={meetingList}
                />
            )}

            <SnackBar
                {...snackBar}
                handleClose={() => {
                    setSnackBar({ open: false, message: "" });
                }}
            />
        </>
    );
};
export default MeetingSlot;