import React from 'react';
import logov from 'assets/images/logo_v.png';

const Footer = () => {
  return (
    <footer className="app-footer">
      {/* <span className="d-inline-block">Copyright ValetEZ &copy; {new Date().getFullYear()}</span> */}
      <div className="powered-by">Powered By</div>
      <div className="mr-2">
        <img src={logov} alt="ValetEZ" title="ValetEZ" />
      </div>
    </footer>
  );
}
  ;

export default Footer;
