// src/components/MapWithCircles.js

import React from 'react';
import { GoogleApiWrapper } from 'google-maps-react';
import mapStyles from "./MISMapStyles.json"
import { GoogleMap, Marker, MarkerClusterer } from '@react-google-maps/api';
const mapOptions = {
  mapTypeControl: false, // Disable map type controls
  streetViewControl: false, // Disable street view controls (optional)
  fullscreenControl: true, // Disable fullscreen control (optional)
  styles: mapStyles,
  scrollwheel:false
};
const clustererOptions = {
    imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
    minimumClusterSize: 2, // Ensure single markers are not clustered
    styles: [{
        url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m2.png',
        height: 53,
        width: 53,
        textColor: '#fff',
        textSize: 14,
    }]
  };
// Function to convert SVG string to data URL
const getIconDataURL = (svgString) => {
    const encodedSVG = encodeURIComponent(svgString);
    return `data:image/svg+xml;charset=utf-8,${encodedSVG}`;
  };
  
  // Manually create an SVG string based on RoomIcon appearance
  const RoomIconSVG = `
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
      <path fill="#faa719" d="M12 0c-4.4 0-8 3.6-8 8.1 0 6 8 15.9 8 15.9s8-9.9 8-15.9c0-4.4-3.6-8.1-8-8.1zm0 11.9c-1.6 0-2.8-1.3-2.8-2.8s1.3-2.8 2.8-2.8 2.8 1.3 2.8 2.8-1.3 2.8-2.8 2.8z"/>
    </svg>
  `;
  
  const RoomIconDataURL = getIconDataURL(RoomIconSVG);
  const mapContainerStyle = {
    width: '100%',
    height: '424px',
    position: 'relative',
    margin: "20px auto 20px auto",
};

const MapWithCircles = (props) => {
    let Showlocations = []
    if(props.mapLocations.length > 0){
        const locations = props.mapLocations?.filter((data)=> { return data.lat!= null && data.long!=null})
            locations.map((data)=>{
            Showlocations.push({"lat":parseFloat(data.lat),"lng":parseFloat(data.long),"name":data.name})
        })
    }
  return (
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={4}
            options={mapOptions}
            center={{ lat: 20.5937, lng: 78.9629 }}
        >
        <MarkerClusterer options={clustererOptions}>
            {(clusterer) =>
            Showlocations.map((location, index) => (
                <Marker
                key={index}
                position={location}
                clusterer={clusterer}
                icon={{
                    url: RoomIconDataURL, // URL of the marker icon
                }}
                title={location.name}
                />
            ))
            }
        </MarkerClusterer>
    </GoogleMap>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyBXAhR770zn4Ms2xj7TXqlpSULkvXsq3aQ"
})(MapWithCircles);
