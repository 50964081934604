import React, { useEffect, useState } from "react";
import API from "util/Api";
import { Formik } from "formik";
import SnackBar from "components/SnackBar";
import { Form, connect } from "formik";
import { Button, Box } from "@material-ui/core";
import FormField from "components/FormField";
import ContainerHeader from "components/ContainerHeader";
import _ from "lodash";
import CardBox from "components/CardBox";
import * as Yup from "yup";
import ActionsTable from "components/ActionsTable/TableList";

const getFieldValues = (fields) => {
    const values = fields.reduce((obj, item) => {
        return { ...obj, [item.key]: item.value };
    }, {});
    return values;
}

const LocationForm = connect((props) => {
    console.log('valet-LocationForm-props', props);
    useEffect(() => {
        if (props.location) {
            const values = getFieldValues(Object.values(props.location));
            if (!_.isEqual(values, props.formik.values)) {
                props.formik.setValues(values);
            }
        }
    }, [props.location]);

    return (
        <div className="row">
            <CardBox styleName="col-12">
                <Form>
                    {/* FIRST ROW */}
                    <div className="row">
                        {props.fields?.map((field) => (
                            field && <div className="col-md-3 col-12" key={`field-${field.key}`}>
                                <FormField field={{ ...field, ...{ name: field.key } }} />
                            </div>
                        ))}
                    </div>
                    {/* BUTTONS */}
                    <div className="row mt-2">
                        <div className="col-lg-6 col-sm-12 col-12">
                            <Box mt="20px">
                                <Box mr="10px" display="inline">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        mr="5px"
                                        type="submit"
                                    >
                                        {` Submit `}
                                    </Button>
                                </Box>
                                <Box mr="10px" display="inline">
                                    <Button variant="outlined" color="secondary" type="reset">
                                        Reset
                                    </Button>
                                </Box>
                            </Box>
                        </div>
                    </div>
                </Form>
            </CardBox>
        </div>
    );
});

const ServiceLocations = (props) => {
    console.log('valet-ServiceLocations-props', props);
    const [config, setConfig] = useState();
    const [initialValues, setInitialValues] = useState();
    const [locations, setLocations] = useState();
    const [location, setLocation] = useState();
    const areaId = props.location.state.id.value;

    const validations = Yup.object().shape({
        name: Yup.string().required("Required"),
        map: Yup.object().required("Required"),
    });

    const [snackBar, setSnackBar] = useState({
        open: false,
        message: "",
        type: "error",
    });


    useEffect(() => {
        API.get("valetServiceLocations", { params: { area_id: areaId } })
            .then((res) => {
                const values = getFieldValues(res.data.data.config.fields);
                setInitialValues(values);
                setLocations(res.data.data.serviceLocations);
                setConfig(res.data.data.config);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleSubmit = (data, form) => {
        API.post("valetServiceLocations", JSON.stringify({ ...data, area_id: areaId }))
            .then((res) => {
                setLocations(res.data.data);
                form.setSubmitting(false);
                form.resetForm();
                setSnackBar({
                    open: true,
                    message: res.data.msg,
                    type: "success",
                });
            })
            .catch((err) => {
                console.log("error", err);
                form.setSubmitting(false);
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong",
                    type: "error",
                });
            });
    };

    const handleEdit = (clickedLoc) => {
        setLocation(clickedLoc);
        // scroll to top
        document.body.style.height = "auto";
        setTimeout(function () {
            document.body.style.height = "100%";
        }, 50);
    };

    const rateModel = (clickedLoc) => {
        console.log('rateModel-clicked', clickedLoc)
        props.history.push({
            pathname: `${props.match.url}/rate-model/${clickedLoc.name.value.replace(/\s+/g, "-").toLowerCase()}`,
            state: clickedLoc,
        });
    };

    return (
        config && initialValues &&
        <>
            <ContainerHeader title={`${config?.title} - ${props.location?.state.name.value}`} match={props.match} />
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={validations}
            >
                <LocationForm fields={config.fields} location={location} />
            </Formik>
            {locations && (
                <ActionsTable
                    tableHeading={locations.title}
                    table={locations}
                    onEdit={handleEdit}
                    rateModel={rateModel}
                    actions={true}
                />
            )}
            <SnackBar
                {...snackBar}
                handleClose={() => {
                    setSnackBar({ open: false, message: "" });
                }}
            />
        </>
    );
}
export default ServiceLocations;