import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ValetLocations from './routes/ValetLocations';
import ParkingLocations from './routes/ParkingLocations'
import RateModels from './routes/ValetLocations/RateModel';
import ValetStaff from './routes/ValetStaff';
import ClientSubscription from './routes/ClientSubscription';
import Configurations from './routes/ValetLocations/configs';

const ValetV2 = ({ match }) => (
    <div className="app-wrapper">
        <Switch>
            <Route path={`${match.url}/locations/configurations`} component={Configurations} />
            <Route path={`${match.url}/locations/rate-model`} component={RateModels} />
            <Route path={`${match.url}/locations`} component={ValetLocations} />
            <Route path={`${match.url}/parking-locations`} component={ParkingLocations} />
            <Route path={`${match.url}/valet-staff`} component={ValetStaff} />
            <Route path={`${match.url}/subscription`} component={ClientSubscription} />
        </Switch>
    </div>
);

export default ValetV2;