import { useEffect,useState } from "react"
import CardBox from "../../../../../components/CardBox"
import FormFilter from "../../../../../components/FormFilter"
import ContainerHeader from "../../../../../components/ContainerHeader"
import API from '../../../../../util/Api'
import SnackBar from '../../../../../components/SnackBar'
import PopUpDialog from '../../../../../components/PopUpDialog'
import _ from 'lodash'
import moment from 'moment'
import ReportTable from '../../../../../components/ReportTable/index'


const ClientsActiveBookings = (props)=>{
    const [dialog,setDialog] = useState({open:false,content:'',header:'',agreeBtnTxt:'',disAgreeBtnTxt:'',reset:null});
    const [snackBar,setSnackBar] = useState({open:false,message:'',type:'error'});
    const [formProps,setFormProps] = useState({
        locations:[],
        clients:[]
    })
    const [activeVehicles,setActiveVehicles] = useState(undefined)
    useEffect(()=>{
        API.get('renewSubscriptionData')
        .then((res) => {
            var clientList = []
            res.data.data.locations.map(loc=>{

                let locClients = loc.clients.map(c=>{
                    c.locId = loc.id
                    return c
                })
                clientList = [...clientList,...locClients]
            })
            setFormProps({...formProps,locations:res.data.data.locations,clients:clientList})
        }).catch(err=>{
        })
    },[])
    const onSubmit = (inputs)=>{
        const inputValues = {dateTime:moment(inputs.dateTime).format("X"),client:inputs.client,location:inputs.location}
        console.log("onSubmit",inputs.from,moment(inputValues.dateTime).format("X"))
        setActiveVehicles(undefined)
        if(!inputValues.location){
            setSnackBar({open:true,message:'Location is required',type:'error'});
        }else if(!inputValues.dateTime ){
            setSnackBar({open:true,message:'Date is required',type:'error'});
        }else if(inputValues.client.length==0){
            setSnackBar({open:true,message:'Client is required',type:'error'});
        }else{
            API.get(`getClientsActiveBookings?dateTime=${inputValues.dateTime}&client=${inputValues.client}&location=${inputValues.location}`).then(response=>{
                console.log("getClientsActiveBookings",response)
                setActiveVehicles(response.data.data)
            }).catch(error=>{
                setDialog({
                    open:true,
                    content:error?.response?.data?.msg?error.response.data.msg:`Failed`,
                    showMessage:true
                })
            })
        }
    }
    return(
        <div>
        <ContainerHeader title={'Clients Active Bookings'} match={props.match}/>    
        <div className="row">
            <CardBox styleName="col-12">
            {
                formProps.locations && formProps.locations.length>0?
                <FormFilter 
                    locations={formProps.locations}
                    clients={formProps.clients} 
                    minDate={moment(Date.now()).subtract(39, 'days')}
                    onFormSubmit={onSubmit}
                    formSubmitText={'Get Details'}
                    flags = {{
                        "location": true,
                        "type": false,
                        "noMinDate": false,
                        "showDate": false,
                        "amount":false,
                        "reason":false,
                        "clients":true,
                        "selectDate":true
                    }}
                />:<></>
            }     
            </CardBox>
        </div>
        {
            activeVehicles &&
            <ReportTable data = {activeVehicles}  />
        }
        
        <PopUpDialog 
            fullWidth
            {...dialog}
            agreeClicked ={()=>{}}
            handleDialogClose={e=>{
                setDialog({
                    open:false,
                    content:'',
                    header:'',
                    agreeBtnTxt:'',
                    disAgreeBtnTxt:'',
                    reset:null
                });
            }}
        />
        <SnackBar 
            {...snackBar} 
            handleClose={()=>{setSnackBar({open:false, message:""})}}
        />
    </div>
    )
}
export default ClientsActiveBookings