import { useEffect,useState } from "react"
import CardBox from "../../../../../components/CardBox"
import FormFilter from "../../../../../components/FormFilter"
import ContainerHeader from "../../../../../components/ContainerHeader"
import API from '../../../../../util/Api'
import PopUpDialog from '../../../../../components/PopUpDialog'
import SnackBar from '../../../../../components/SnackBar'
import _ from 'lodash'
import moment from 'moment'

const CloseActiveBookings = (props)=>{
    console.log("CloseActiveBookings",props.match)
    const [dialog,setDialog] = useState({open:false,content:'',header:'',agreeBtnTxt:'',disAgreeBtnTxt:'',reset:null});
    const [snackBar,setSnackBar] = useState({open:false,message:'',type:'error'});
    const [formProps,setFormProps] = useState({
        locations:[]
    })
    useEffect(()=>{
        API.get('location')
        .then((res) => {
            setFormProps({...formProps,locations:res.data.data})
        }).catch(err=>{
            setSnackBar({open:true,message:'api failed',type:'error'});
        })
    },[])
    const getConfirmationPopUp = (inputs) =>{
        console.log("getConfirmationPopUp",inputs)
        if(!inputs.location){
            setSnackBar({open:true,message:'Location required',type:'error'});
        }else if(!inputs.from || !inputs.to){
            setSnackBar({open:true,message:'start date, end date required',type:'error'});
        }else if(inputs.vehicle.length===0){
            setSnackBar({open:true,message:'vehicle type required',type:'error'});
        }else if(inputs.reason.length<10){
            setSnackBar({open:true,message:'reason required and min length should 10',type:'error'});
        }else if(isNaN(parseFloat(inputs.amount))){
            setSnackBar({open:true,message:'amount required',type:'error'});
        }else{
            const loc = _.find(formProps.locations,{id:inputs.location})
            setDialog({
                open:true,
                header:"Close Active Parking Bookings",
                content:`Are u sure you want to close active bookings for location :  ${loc.name} , date range b/w  ${moment.utc(inputs.from).local().format("DD-MM-YYYY HH:mm a")} to ${moment.utc(inputs.to).local().format("DD-MM-YYYY HH:mm a")} `,
                agreeBtnTxt:'Confirm',
                disAgreeBtnTxt:'Cancel',
                inputs:inputs
            })
        }
          
    }
    const onConfirmClicked = () =>{
        if(dialog.inputs){
            API.post("closeParkingActiveBookings",dialog.inputs).then(response=>{
                setDialog({
                    open:true,
                    content:response.data.msg,
                    showMessage:true
                })
            }).catch(error=>{
                setDialog({
                    open:true,
                    content:error?.response?.data?.msg?error.response.data.msg:`Failed`,
                    showMessage:true
                })
            })
        }else{
            setSnackBar({open:true,message:'Fields missing',type:'error'});
        }
        
    }
    const handleDialogClose = ()=>{
        setDialog({
            open:false,
            content:'',
            header:'',
            agreeBtnTxt:'',
            disAgreeBtnTxt:'',
            reset:null
        });
    }
    return(
        <div>
            <ContainerHeader title={'Close Active Bookings'} match={props.match}/>    
            <div className="row">
                    <CardBox styleName="col-12">
                        {
                            formProps.locations && formProps.locations.length>0?
                            <FormFilter 
                                formSubmitText={'Confirm'}
                                locations={formProps.locations}
                                parking_types={["Pay and park","Subscription","Both"]} 
                                onFormSubmit={getConfirmationPopUp}
                                flags = {{
                                    "vehicleType": true,
                                    "location": true,
                                    "multiLocation": false,
                                    "type": false,
                                    "noMinDate": true,
                                    "showDate": true,
                                    "amount":true,
                                    "reason":true,
                                    "parkingType":true
                                }}
                            />:<></>
                        }
                        
                    </CardBox>
                </div>
                <PopUpDialog 
                    fullWidth
                    {...dialog}
                    agreeClicked ={onConfirmClicked}
                    handleDialogClose={handleDialogClose}
                />
                <SnackBar 
                    {...snackBar} 
                    handleClose={()=>{setSnackBar({open:false, message:""})}}
                />
        </div>
    )
}
export default CloseActiveBookings