import CardBox from "components/CardBox"
import ContainerHeader from "components/ContainerHeader"
import ReportFilter from "components/ReportTable/ReportFilter"
import moment from "moment"
import { useEffect, useState } from "react"
import API from "util/Api";
import OverviewDetails from "./OveriewDetails"
import _ from "lodash"
import { connect } from "react-redux";
import LinearProgress from '@material-ui/core/LinearProgress';

const ParkingOverView = (props)=>{
    
    const [locations,setLocations] = useState([])
    const [data,setData] = useState(undefined)
    const [utilizationSata,setUtilizationSata] = useState(undefined)
    const [lengthOfStay,setLengthOfStay] = useState(undefined)
    const reportFlags = _.find(props?.reportFlags, { name: 'parking-overview' })?.flags
    reportFlags.showDate = false;
    const [loading,setLoading] = useState(false)
    useEffect(()=>{
        API.get("location", {params:{ loc_type: "parking" }}).then((res) => {
            setLocations(res.data.data)
        })
    },[])

    const getParkingOverview = (params, cb)=>{
        params.type = 'parking-overview';
        setLoading(true)
        Object.keys(params).map(function (item, i) {
          if (item === "from" || item === "to")
            params[item] = new Date(params[item]).toISOString();
        });
        // Get today's date
        const today = new Date();
        const from = new Date(today);
        from.setHours(0, 0, 0, 0);  // Set hours to 12 AM today
        const to = new Date(today);
        to.setHours(24, 0, 0, 0);// Set to 11:59 PM
        // Convert to IST by adjusting the time zone offset (+5:30 for IST)
        const ISTOffset = 5.5 * 60; // IST is UTC +5:30 hours
        const localFrom = new Date(from.getTime() + (ISTOffset * 60000));
        const localTo = new Date(to.getTime() + (ISTOffset * 60000));
        // Format the result in a string format
        const formatDate = (date) => {
        return date.toISOString().replace("T", " ").split(".")[0];  // Format to 'yyyy-mm-dd hh:mm:ss'
        };
        
        console.log("getParkingOverview-",params)
        setData(null)
        API.get("parking_overview?data=" + JSON.stringify(params)).then((res) => {
            setData(res.data.data)
            getAnalyticsData({ 'location': params.location, 'from': formatDate(localFrom), 'to': formatDate(localTo), 'vehicleTypes': ['bike','car'], 'format': 'hourly' },res.data.data)
            setLoading(false)
            cb()
        }).catch(err=>{
            cb()
            setLoading(false)
        })
    }

    const getAnalyticsData = (parms,data)=>{
        API.get('analyticDetails_v2?data=' + JSON.stringify(parms))
        .then((res) => {  
            // data.utilization = res.data.data.utilization;
            setUtilizationSata(res.data.data.utilization)
            setLengthOfStay(res.data.data.lengthOfStay ?? {data : [],legends:[]})
            setData(data)
        })
    }
    return(
        <div>
            <ContainerHeader title={"Overview"} match={props.match} />
            {
                reportFlags &&
                <div className="row">
                    <CardBox styleName="col-12">
                        <ReportFilter
                            locations={locations}
                            reportType={['overview']}
                            requestStatus={{ msg: "", success: true }}
                            getReportData={getParkingOverview}
                            flags={reportFlags}
                            vendors={[]}
                            minFromDate={moment(Date.now()).subtract(39, "days")} 
                            maxToDate={moment(Date.now()).add(1, "days")}
                            removeRequestReport={true}
                        />
                    </CardBox>
                    {
                        loading &&
                        <div className="w-100">
                            <LinearProgress color="primary" /><LinearProgress color="primary" />
                        </div>
                    }
                    
                    {
                        data && utilizationSata &&
                            <OverviewDetails data={data} utilizationSata={utilizationSata} lengthOfStay={lengthOfStay} ></OverviewDetails>
                    }
                </div>
            }
            
        </div>
    )
}
const mapStateToProps = function (state) {
  try {
    return {
      reportFlags: _.concat(
        state.auth.authUser.reports,
        state.auth.authUser.subsc,
        state.auth.authUser.ezcharge,
        state.auth.authUser.valet,
        state.auth.authUser.access
      ),
      dashboardActions: state.auth.authUser.dashboardactions,
    };
  } catch (err) { }
};
export default connect(mapStateToProps) (ParkingOverView)