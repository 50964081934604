/*
 * File: index.js
 * Project: parkezdashboard
 * File Created: Monday, 20th April 2020 5:43:18 pm
 * Author: Smit (smit@valetez.com)
 * -----
 * Last Modified: Wednesday, 6th May 2020 4:18:30 pm
 * Modified By: Smit (smit@valetez.com)
 * -----
 * component for MIS main page
 * -----
 * Copyright - 2020 , ValetEZ Services Pvt Ltd
 */

import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import MISTable from 'components/ActionsTable/MISTable'
import { DatePicker } from '@material-ui/pickers'

import ContainerHeader from 'components/ContainerHeader'
import IntlMessages from 'util/IntlMessages'
// import {Map,MapBox} from 'components/Map'
import { Typography } from '@material-ui/core'
import API from 'util/Api';
import _ from 'lodash'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress';
import SnackBar from 'components/SnackBar'
import {Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis,ResponsiveContainer,LineChart,Line} from 'recharts'
import MapWithCircles from 'components/Map/MapWithCircles';
import EZMap from 'components/Map/EZMap';
import rupee from 'assets/images/rupee-sign.png'
import liveLocation from 'assets/images/live-loaction.png'
import fastag from 'assets/images/fastag.png'
import download from 'assets/images/download.png'
import liveDevice from 'assets/images/live-devices.png'
import transactions from 'assets/images/transaction.png'
import html2canvas from 'html2canvas';
import { Alert } from "reactstrap";
import moment from 'moment'


class MIS extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appState:'active',
            loading:false,
            sectionLoading:false,
            apiLocations:[],
            location: [],
            locations: [],
            gmvCountData:null,
            gmvRevenueData:null,
            gmvLegends: [],  
            gmvCountMin:0,
            gmvCountMax:0,
            gmvRevenueMin:0,
            gmvRevenueMax:0,
            tags: [{'id':'0','name':'SELECT ALL','tags':'all_locations'}],
            locationTag:[],
            snackOpen:false,
            snackMessage:'',
            snackType:'error',
            cityHighLights:null,
            gmvCurve2Wheelers:false,
            gmvCurve4Wheelers:false,
            gmvCurveTotal:true,
            transactionCurveTotal:true,
            transaction2Wheelers:false,
            transaction4Wheelers:false,
            propertyTransCurveTotal:true,
            propertyTrans2Wheelers:false,
            propertyTrans4Wheelers:false,
            propertyGmvCurveTotal:true,
            propertyGmv2Wheelers:false,
            propertyGmv4Wheelers:false,
            paymentTransCurveTotal:true,
            paymentTrans2Wheelers:false,
            paymentTrans4Wheelers:false,
            paymentGmvCurveTotal:true,
            paymentGmv2Wheelers:false,
            paymentGmv4Wheelers:false,
            apiStates:[],
            state:[],
            states:[],
            apiCitys:[],
            city:[],
            citys:[],
            apiPropertyTypes:[],
            propertyType:[],
            propertyTypes:[],
            showPropertyTypes:[],
            from: this.getRoundedDate(15, new Date()),
            to: this.getRoundedDate(15, new Date()),
            staffList: null,
            MISReports : {},
            mapLocations : {} ,
            liveLocations : null,
            trendCurveTransaction : [],
            apiTrendCurveTransaction : [],
            trendCurveGmv : [],
            apiTrendCurveGmv : [],
            tagTransaction : [],
            apiTagTransaction : [],
            tagGmv : [],
            apiTagGmv : [],
            paymenttypeTransaction : [],
            paymenttypeGmv : [],
            apiLocationTags : [],
            apiPaymentTypes:[],
            apiCall : 0,
            // colours : ["#8CB369","#F4E285","#F4A259","#BC4B51","#BC4B51","#F4F1DE","8CB369","#5B8E7D","#BC4B51"],
            colours : ["#8CB369","#d8c76b","#F4A259","#BC4B51","#EB898E","#929ff0","8CB369","#5B8E7D","#C38EA7"],
            isAuthorized : false,
            maxTransactionValue : 100,
            maxGmvValue : 100,
            statesData  : null,
            topLocations : null,
            apiLocationTagsTransaction : [],
            apiLocationTagsGMV : [],
            apiPaymentTypesgmv : [],
            apiPaymentTypesTransaction : [],
            
        }
        // this.handleLocationChange = this.handleLocationChange.bind(this)
    }
    componentDidMount(){   
        this.generateData()    
    }
    // Sorting function to bring objects with id === 0 to the top
    sortByZeroIdFirst(a, b) {
        // Check if 'id' exists in both objects
        if (('id' in a) === ('id' in b)) {
            // Both have 'id' or both do not have 'id', maintain original order
            return 0;
        } else if ('id' in a) {
            // 'a' has 'id', move 'a' before 'b'
            return -1;
        } else {
            // 'b' has 'id', move 'b' before 'a'
            return 1;
        }
    }
    componentDidUpdate(prevProps, prevState) {
        // ADD State change
        if (prevState.state.length < this.state.state.length) {
            const addedItem = this.state.state.find(item => !prevState.state.includes(item));
            console.log(addedItem)
            if(addedItem.id == 0){
                this.setState({
                    city : [...this.state.apiCitys.filter((data)=> data.id != 0)],
                    citys : this.state.apiCitys,
                    location : [...this.state.apiLocations.filter((data)=> data.id != 0)],
                    locations :  this.state.apiLocations,
                    propertyType : [...this.state.apiPropertyTypes.filter((data)=> data.id != 0)],
                    propertyTypes : this.state.apiPropertyTypes,
                    state : [...this.state.apiStates.filter((data)=> data.id != 0)],
                    states : this.state.apiStates
                })
                return
            }
            let city = []
            city.push(...this.state.apiCitys.filter(cityData => { return cityData.stateId == addedItem.id}))
            city.push(...this.state.citys.filter(cityData => { return cityData.stateId != addedItem.id}))
            let location = []
            location.push(...this.state.apiLocations.filter(locationData => { return locationData.stateId?.includes(addedItem.id)}))
            location.push(...this.state.locations.filter(locationData => { return !locationData.stateId?.includes(addedItem.id)}))
            // let finalLocations  = []
            // this.state.propertyType.forEach((propertyData)=>{
            //     location.forEach((locationData)=>{
            //         if(locationData.tagId == propertyData.tagId)
            //             finalLocations.push(locationData)
            //     })
            // })
            let showTag = []
            showTag.push(...this.state.apiPropertyTypes.filter(tagData => { return tagData.stateId?.includes(addedItem.id)}))
            showTag.push(...this.state.propertyType.filter(tagData => { return !tagData.stateId?.includes(addedItem.id)}))
            // showTag = showTag.flat()
            let cFlag = 1
            let lFlag = 1
            let pFlag = 1
            city.forEach((data)=>{
                if(data.id == 0)
                    cFlag=0
            })
            location.forEach((data)=>{
                if(data.id == 0)
                    lFlag=0
            })
            showTag.forEach((data)=>{
                if(data.id == 0)
                    pFlag=0
            })
            if(cFlag == 1)
                city = [{id:'0',cityId: '0', stateId: '0', cityName: 'SELECT ALL'},...city]
            if(pFlag == 1)
                showTag = [{id:0,cityId: [], stateId: [], locationId: 0, tagId: '0', tagName: 'SELECT ALL'},...showTag]
            if(lFlag == 1)
                location = [{id:0,cityId: [], stateId: [], locationId: [], locationName: 'SELECT ALL', tagId: []},...location]
            city.sort(this.sortByZeroIdFirst)
            location.sort(this.sortByZeroIdFirst)
            showTag.sort(this.sortByZeroIdFirst)
            console.log("add state",city,showTag,location)
            this.setState({
                city : [...city.filter((data)=> data.id != 0)],
                citys : city,
                location : [...location.filter((data)=> data.id != 0)],
                locations : location,
                propertyType : [...showTag.filter((data)=> data.id != 0)],
                propertyTypes : showTag
            })
        } 
        // REMOVE STATE Change
        else if (prevState.state.length > this.state.state.length) {
            if(this.state.state.length == 0){
                this.setState({
                    city :[],
                    citys:[],
                    location:[],
                    locations:[],
                    propertyType:[],
                    propertyTypes:[]
                    // city : [{id:'0',cityId: '0', stateId: '0', cityName: 'SELECT ALL'}],
                    // citys : [{id:'0',cityId: '0', stateId: '0', cityName: 'SELECT ALL'}],
                    // location : [{id:0,cityId: [], stateId: [], locationId: [], locationName: 'SELECT ALL', tagId: []}],
                    // locations : [{id:0,cityId: [], stateId: [], locationId: [], locationName: 'SELECT ALL', tagId: []}],
                    // propertyType : [{id:0,cityId: [], stateId: [], locationId: 0, tagId: '0', tagName: 'SELECT ALL'}],
                    // propertyTypes : [{id:0,cityId: [], stateId: [], locationId: 0, tagId: '0', tagName: 'SELECT ALL'}]
                })
            }
            else{
                const removedItem = prevState.state.find(item => !this.state.state.includes(item));
                let city;
                let location;
                let showProperty = [];
                if(removedItem.id != 0){
                    city = this.state.city.filter(cityData => { return cityData.stateId != removedItem.id})
                    location = this.state.location.filter(locationData => { return locationData.stateId != removedItem.id})
                    let value = [...this.state.propertyType]
                    value = value.flat()
                    console.log("inside if,",value,removedItem.id)
                    value.forEach((data)=>{
                        if(data.stateId?.includes(removedItem.id) && data.stateId.length > 1)
                            showProperty.push(data)
                        else if(!data.stateId?.includes(removedItem.id))
                            showProperty.push(data)
                    })
                    // showProperty = value.filter(tagData => { return !tagData.stateId?.includes(removedItem.id)})
                }
                else{
                    city = [{id:'0',cityId: '0', stateId: '0', cityName: 'SELECT ALL'}]
                    location = [{id:0,cityId: [], stateId: [], locationId: [], locationName: 'SELECT ALL', tagId: []}]
                    console.log(this.state.propertyType,this.state.location)
                    showProperty = [{id:0,cityId: [], stateId: [], locationId: 0, tagId: '0', tagName: 'SELECT ALL'}]
                    this.setState({
                        state : []
                    })
                }
                showProperty = showProperty.flat()
                console.log(showProperty,location,"remove start",removedItem)

                this.setState({
                    city : [...city?.filter((data)=> data.id != 0)],
                    citys : city,
                    location : [...location?.filter((data)=> data.id != 0)],
                    locations : location,
                    showPropertyTypes : showProperty,
                    propertyType : [...showProperty?.filter((data)=> data.id != 0)],
                    propertyTypes : showProperty
                })
            }
        }
        // ADD CITY change
        else if(prevState.city.length < this.state.city.length){
            const addedItem = this.state.city.find(item => !prevState.city.includes(item));
            console.log("ADD CITY",addedItem)
            // IF SELECTED SELECT ALL IN CITY SELECT ALL PROPERTY AND LOCATSION BASED ON SELECTED STATE
            if(addedItem.id == 0){
                this.setAddCity()
                return
            }
            let location = []
            location.push(...this.state.apiLocations.filter(locationData => { return locationData.cityId?.includes(addedItem?.cityId)}))
            location.push(...this.state.location.filter(locationData => { return !locationData.cityId?.includes(addedItem?.cityId)}))
            let showTag = []
            this.state.apiPropertyTypes.forEach((apiData)=>{
                if(apiData.cityId.includes(addedItem.cityId))
                    if(apiData.stateId.includes(addedItem.stateId))
                        showTag.push(apiData)
            })
            let finalTag = [...showTag]
            this.state.propertyType.forEach((data)=>{
                let flag = 1
                showTag.forEach((res)=>{
                    if(res.tagId == data.tagId)
                        flag = 0
                })
                if(flag){
                    finalTag.push(data)
                }
            })
            // IF SELECT ALL IS REMOVED FROM THE FILTERS ABOVE ADD THE ALL LOCATION TO THE FILTERS
            let lFlag = 1
            let pFlag = 1
            location.forEach((data)=>{
                if(data.id == 0)
                    lFlag=0
            })
            finalTag.forEach((data)=>{
                if(data.id == 0)
                    pFlag=0
            })
            if(pFlag == 1)
                finalTag = [{id:0,cityId: [], stateId: [], locationId: 0, tagId: '0', tagName: 'SELECT ALL'},...finalTag]
            if(lFlag == 1)
                location = [{id:0,cityId: [], stateId: [], locationId: [], locationName: 'SELECT ALL', tagId: []},...location]

            // THE ADDED ALL LOCATION MAY COME INBETWEEN THE LIST SO PUSH IT TO THE TOP OF THE LIST
            location.sort(this.sortByZeroIdFirst)
            finalTag.sort(this.sortByZeroIdFirst)
            // showTag.push(...this.state.apiPropertyTypes.filter(tagData => { return tagData.cityId.includes(addedItem.cityId)}))
            // showTag.push(...this.state.propertyType?.filter(tagData => { return !tagData.cityId.includes(addedItem.cityId)}))
            this.setState({
                location : [...location?.filter((data)=> data.id != 0)],
                locations : location,
                propertyType  : [...finalTag?.filter((data)=> data.id != 0)],
                propertyTypes : finalTag
            }) 
        }
        // REMOVE CITY Change
        else if (prevState.city.length > this.state.city.length) {
            if(this.state.city.length == 0 ){
                // this.setState({
                //     locations : [{id:0,cityId: [], stateId: [], locationId: [], locationName: 'SELECT ALL', tagId: []}],
                //     propertyTypes : [{id:0,cityId: [], stateId: [], locationId: 0, tagId: '0', tagName: 'SELECT ALL'}]
                // })
                this.setState({
                    locations:[],
                    propertyTypes:[]
                })

            }
            else{
                const removedItem = prevState.city.find(item => !this.state.city.includes(item));
                let location = []
                let filteredpropertyData = []
                if(removedItem.id != 0){
                    this.state.location.forEach(locationData => {
                        if(!locationData.cityId?.includes(removedItem.cityId))
                            location.push(locationData)
                    });
                    // Deep copy of propertyType
                    let propertyData = this.state.propertyType.map(data1 => ({
                        ...data1,
                        cityId: data1.cityId?.filter(proData => proData !== removedItem.cityId)
                    }));
                    let cities = this.state.city.map((data)=> { return data.cityId})
                    propertyData.forEach(data => {
                        let flag = 0
                        data.cityId?.map((city)=>{
                            if(cities.includes(city))
                                flag =1
                        })
                        if(flag == 1)
                            filteredpropertyData.push(data)
                    });
                }
                else{
                    console.log("remove city")
                    let stateId = this.state.state.map(a => a.id)
                    let cityId = this.state.city.map(a => a.cityId)
                    location = this.state.location.filter(locationData => { return stateId.includes(locationData.stateId) && cityId?.includes(locationData.citytId)})
                    filteredpropertyData = this.state.propertyType.filter(tagData => { return stateId.includes(tagData.stateId) && cityId?.includes(tagData.citytId)})
                    this.setState({
                        city : []
                    })
                }
                let lFlag = 1
                let pFlag = 1
                location.forEach((data)=>{
                    if(data.id == 0)
                        lFlag=0
                })
                filteredpropertyData.forEach((data)=>{
                    if(data.id == 0)
                        pFlag=0
                })
                if(pFlag == 1)
                    filteredpropertyData = [{id:0,cityId: [], stateId: [], locationId: 0, tagId: '0', tagName: 'SELECT ALL'},...filteredpropertyData]
                if(lFlag == 1)
                    location = [{id:0,cityId: [], stateId: [], locationId: [], locationName: 'SELECT ALL', tagId: []},...location]
                location.sort(this.sortByZeroIdFirst)
                filteredpropertyData.sort(this.sortByZeroIdFirst)
                this.setState({
                    location: [...location?.filter((data)=> data.id != 0)],
                    locations: location,
                    propertyType: [...filteredpropertyData?.filter((data)=> data.id != 0)],
                    propertyTypes: filteredpropertyData
                });

            }
        }
        // ADD PROPERTY Change
        else if(prevState.propertyType.length < this.state.propertyType.length){
            const addedItem = this.state.propertyType.find(item => !prevState.propertyType.includes(item));
            if(addedItem.id == 0){
                // IF SELECTED ALL LOCATION IN PROPERTY SELECT LOCATIONS BASED ON THE SELECTE STATE CITY AND PROPERTY
                this.setAddProperty()
                return
            }
            let location = []

            location.push(...this.state.apiLocations.filter(locationData => { return locationData.tagId?.includes(addedItem?.tagId)}))
            location.push(...this.state.location.filter(locationData => { return !locationData.tagId?.includes(addedItem?.tagId)}))
            let stateLocations = [] 
            this.state.state.forEach((stateData)=>{
                location.forEach((locationData)=>{
                    if(locationData.stateId.includes(stateData.id))
                        stateLocations.push(locationData)
                })
            })
            let filteredData = []
            this.state.city.forEach((cityData)=>{
                stateLocations.forEach((locationData)=>{
                    if(locationData.cityId.includes(cityData.cityId))
                        filteredData.push(locationData)
                })
            })
            let lFlag = 1
            filteredData.forEach((data)=>{
                if(data.id == 0)
                    lFlag=0
            })
        
            if(lFlag == 1)
                filteredData = [{id:0,cityId: [], stateId: [], locationId: [], locationName: 'SELECT ALL', tagId: []},...filteredData]
            filteredData.sort(this.sortByZeroIdFirst)
            this.setState({
                location : [...filteredData?.filter((data)=> data.id != 0)],
                locations : filteredData
            })
        }
        // REMOVE PROPERTY Change
        else if(prevState.propertyType.length > this.state.propertyType.length){
            console.log("Remove property")
            if(this.state.propertyType.length == 0 ){
                this.setState({
                    // location : [{id:0,cityId: [], stateId: [], locationId: [], locationName: 'SELECT ALL', tagId: []}],
                    // locations : [{id:0,cityId: [], stateId: [], locationId: [], locationName: 'SELECT ALL', tagId: []}],
                    location:[],
                    locations:[],
                })
            }
            else{
                // const removedItem = prevState.propertyType.find(item => !this.state.propertyType.includes(item));
                const removedItem = prevState.propertyType.filter(item => !this.state.propertyType.some(newStateItem =>
                    newStateItem.tagId === item.tagId
                ));
                console.log("remove prop",removedItem)
                let location = []
                const deepCopy = (obj) => {
                    return JSON.parse(JSON.stringify(obj));
                };
                if(removedItem[0].id != 0){
                    this.state.location.forEach((locationData) => {
                        const locationDataCopy = deepCopy(locationData);
                    
                        if (!locationDataCopy.tagId.includes(removedItem[0]?.tagId)) {
                            location.push(locationDataCopy);
                        } else {
                            const index = locationDataCopy.tagId.indexOf(removedItem[0]?.tagId);
                    
                            if (locationDataCopy.tagId.length > 1) {
                                if (index !== -1) {
                                    locationDataCopy.tagId = locationDataCopy.tagId.filter((element) => element !== removedItem[0]?.tagId);
                                    location.push(locationDataCopy);
                                }
                            } else {
                            }
                        }
                    });
                }
                else{
                    console.log("remove city")
                    let stateId = this.state.state.map(a => a.id)
                    let cityId = this.state.city.map(a => a.cityId)
                    location = this.state.location.filter(locationData => { return stateId.includes(locationData.stateId) && cityId.includes(locationData.citytId)})
                    this.setState({
                        propertyType : [],
                    })
                }
                // location.push(...this.state.location.filter(locationData => { return locationData.tagId != locationData.tagId}))
                this.setState({
                    location : [...location?.filter((data)=> data.id != 0)],
                    locations : location
                })
            }
        }
        // ADD LOCATION
        else if(prevState.location.length < this.state.location.length){
            const addedItem = this.state.location.find(item => !prevState.location.includes(item));
            console.log("ADDED LOCATION",addedItem)

            if(addedItem.id == 0){
                this.setState({
                    location: [...this.state.locations?.filter((data)=> data.id != 0)]
                })
            }
        }
        // REMOVE LOCATION
        else if(prevState.location.length > this.state.location.length){
            const removedItem = prevState.location.filter(item => !this.state.location.some(newStateItem =>
                newStateItem.locationName == item.locationName
            ));
            console.log("REMOVE LOCATION",removedItem)
            if(removedItem[0].id == 0){
                this.setState({
                    location:[]
                })
            }
        }
        
    }
    
    getRoundedDate(minutes, d = new Date(), low = true) {
        let ms = 1000 * 60 * minutes; // convert minutes to ms
        let roundedDate = new Date((Math.round(d.getTime() / ms) - (low ? 0 : -1)) * ms);

        return roundedDate
    }
    getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }
    setAddCity(){
        let filteredTag = []
        let filteredLocation = []
        this.state.state.forEach((state)=>{
            this.state.citys.forEach((city)=>{
                this.state.apiPropertyTypes.forEach((tag)=>{
                    if(tag.stateId.includes(state.id) && tag.cityId.includes(city.cityId)){
                        filteredTag.push(tag)
                    }
                    this.state.apiLocations.forEach((location)=>{
                        if(location.stateId.includes(state.id) && location.cityId.includes(city.cityId) && location.tagId.includes(tag.tagId)){
                            filteredLocation.push(location)
                        }
                    })
                })
            })  
        })
        filteredLocation = this.removeDuplicates(filteredLocation,'locationName')
        filteredTag = this.removeDuplicates(filteredTag,'tagName')
        let lFlag = 1
        let pFlag = 1
        filteredLocation.forEach((data)=>{
            if(data.id == 0)
                lFlag=0
        })
        filteredTag.forEach((data)=>{
            if(data.id == 0)
                pFlag=0
        })
        if(pFlag == 1)
            filteredTag = [{id:0,cityId: [], stateId: [], locationId: 0, tagId: '0', tagName: 'SELECT ALL'},...filteredTag]
        if(lFlag == 1)
            filteredLocation = [{id:0,cityId: [], stateId: [], locationId: [], locationName: 'SELECT ALL', tagId: []},...filteredLocation]
        filteredLocation.sort(this.sortByZeroIdFirst)
        filteredTag.sort(this.sortByZeroIdFirst)
        console.log("add city all",filteredLocation,filteredTag)
        this.setState({
            location : [...filteredLocation?.filter((data)=> data.id != 0)],
            locations:filteredLocation,
            propertyType:[...filteredTag?.filter((data)=> data.id != 0)],
            propertyTypes:filteredTag,
            city:[...this.state.citys?.filter((data)=> data.id != 0)]
        })
    }
    setAddProperty(){
        let filteredLocation = []
        this.state.state.forEach((state)=>{
            this.state.city.forEach((city)=>{
                this.state.apiPropertyTypes.forEach((tag)=>{
                    this.state.apiLocations.forEach((location)=>{
                        if(location.stateId.includes(state.id) && location.cityId.includes(city.cityId) && location.tagId.includes(tag.tagId)){
                            filteredLocation.push(location)
                        }
                    })
                })
            })  
        })
        filteredLocation = this.removeDuplicates(filteredLocation,'locationName')
        let lFlag = 1
        filteredLocation.forEach((data)=>{
            if(data.id == 0)
                lFlag=0
        })
        if(lFlag == 1)
            filteredLocation = [{id:0,cityId: [], stateId: [], locationId: 0, tagId: '0', tagName: 'SELECT ALL'},...filteredLocation]
        filteredLocation.sort(this.sortByZeroIdFirst)
        this.setState({
            location : [...filteredLocation?.filter((data)=> data.id != 0)],
            locations:filteredLocation,
            propertyType:[...this.state.propertyTypes?.filter((data)=> data.id != 0)],
        })
    }
    removeDuplicates(array, prop) {
        let uniqueIds = new Set();
        return array.filter(obj => {
            if (!uniqueIds.has(obj[prop])) {
                uniqueIds.add(obj[prop]);
                return true;
            }
            return false;
        });
    }
    generateData(){
        let states = []
        let locations = []
        let tags = []
        let city = []
        API.get('parkingMISConfig').then(res=>{
            this.setState({
                MISReports : res.data.data.summary,
                mapLocations : res.data.data.mapData,
                liveLocations : {"headers" :res.data.data.liveLocations.headers,"body" : res.data.data.liveLocations.tbody },
                isAuthorized : true,
                topLocations : {"headers" :res.data.data.topLocations.headers,"body" : res.data.data.topLocations.tbody },

            })
            res.data.data.config[0].options.forEach((stateData)=>{
                states.push({"id":stateData.value,"stateName":stateData.title})
                stateData.cities?.forEach((cityData)=>{
                    city.push({"cityId":cityData.value,"stateId":stateData.value,"cityName":cityData.title})
                    cityData.tag?.forEach((tagData)=>{
                        let flag = 0
                        tags.forEach((tData)=>{
                            if(tData.tagName == tagData.title){
                                tData.cityId = [...tData.cityId,cityData.value]
                                tData.stateId = [...tData.stateId,stateData.value]
                                flag = 1
                            }
                        })
                        if(flag == 0){
                            tags.push({"cityId":[cityData.value],"stateId":[stateData.value],"locationId":1,"tagId":tagData.value,"tagName":tagData.title})
                        }
                        tagData.locations?.forEach((locationData)=>{
                            let locationFlag = 0
                            locations.forEach((lData)=>{
                                if(lData.locationId.includes(locationData.value)){
                                    lData.cityId = [...lData.cityId,cityData.value]
                                    lData.stateId = [...lData.stateId,stateData.value]
                                    lData.locationId = [...lData.locationId,locationData.value]
                                    lData.tagId = [...lData.tagId , tagData.value]
                                    locationFlag = 1
                                }
                            })
                            if(locationFlag == 0)
                                locations.push({"cityId":[cityData.value],"stateId":[stateData.value],"locationId":[locationData.value],"locationName":locationData.title,"tagId":[tagData.value]})
                        })
                    })
                })
            })
            this.setState({
                apiLocations : [{id:0,cityId: [], stateId: [], locationId: [], locationName: 'SELECT ALL', tagId: []},...locations],
                states : [{id:'0', stateName: 'SELECT ALL'},...states],
                apiStates : [{id:'0', stateName: 'SELECT ALL'},...states],
                apiCitys : [{id:'0',cityId: '0', stateId: '0', cityName: 'SELECT ALL'},...city],
                apiPropertyTypes : [{id:0,cityId: [], stateId: [], locationId: 0, tagId: '0', tagName: 'SELECT ALL'},...tags],
            })
        }).catch((err) => {
        })
    }

    handleChange = name => event => {
        this.setState({[name]: event});
    };
    handleOnChange=(event)=>{
        const {name, value} = event.target
        if(value == 0){
                this.setState({
                    [name]:value})
            } else {
                this.setState({
                    [name]:value
                })
            }   
    } 
    getAnalyticsData(params){
        if(params.loc_tag && params.loc_tag.length>0){
            this.setState({loading:true});
            API.get('gmvDetails?data='+JSON.stringify(params))
            .then((res)=> {
                // rcvd reports data
                let data = res.data.data;
                this.setState({
                    gmvCountData:data.gmvData,
                    gmvLegends:data.gmvLegends,
                    gmvCountMin:data.gmvCountMin,
                    gmvCountMax:data.gmvCountMax,
                    gmvRevenueData:data.gmvRevenueData,
                    gmvRevenueMin:data.gmvRevenueMin,
                    gmvRevenueMax:data.gmvRevenueMax,
                    loading:false,
                    })
            })
            .catch((err)=> {
                this.setState({errorMessage: "Please Try Again",loading:false});
            })
        }else{
            this.setSnackState(true,'Please select location',"error");
        }

    }
    setSnackState=(open,msg,type)=>{
        this.setState({snackOpen:open,snackMessage:msg,snackType:type})
    }
    handleSnackClose = ()=>{
        this.setState({
          snackOpen:false,
          snackMessage:""
        });
    }
    resetField=()=>{
        this.setState({
            location : [],
            locations : [],
            city : [],
            citys : [],
            state : [],
            states : [...this.state.apiStates],
            propertyType : [],
            propertyTypes : [],
            from: this.getRoundedDate(15, new Date()),
            to: this.getRoundedDate(15, new Date()),
        })
    }
    getTagPaymentDetails = (newState)=>{
        const payment = this.state.apiLocationTags.map(item => ({ name: item, value: 0 }));
        console.log(payment)
        let tagData =  JSON.parse(JSON.stringify(this.state.apiTagTransaction));
                    tagData.map((entry)=>{
                        this.state.apiLocationTags.map((keys)=>{
                            let object = entry[keys]
                            if(object){
                                let value = 0
                                if(newState.propertyTrans2Wheelers)
                                    value += parseInt(entry[keys]['2wheeler'])
                                if(newState.propertyTrans4Wheelers)
                                    value+= parseInt(entry[keys]['4wheeler'])
                                if(newState.propertyTransCurveTotal){
                                    value = 0
                                    value = parseInt(entry[keys]['2wheeler']) + parseInt(entry[keys]['4wheeler'])
                                }
                                entry[keys] =  value
                                const objToUpdate = payment.find(obj => obj.name === keys);
                                if (objToUpdate) {
                                    objToUpdate.value = objToUpdate.value + value;
                                }
                            }
                        })
                    })  
        payment.sort((a, b) => b.value - a.value);
        this.setState({
            tagTransaction : tagData,
            apiLocationTagsTransaction : payment.map((item) => item.name)
        })
    }
    getTagGmvDetails = (newState)=>{
        const gmv = this.state.apiLocationTags.map(item => ({ name: item, value: 0 }));
        let tagData =  JSON.parse(JSON.stringify(this.state.apiTagGmv));
                    tagData.map((entry)=>{
                        this.state.apiLocationTags.map((keys)=>{
                            let object = entry[keys]
                            if(object){
                                let value = 0
                                if(newState.propertyGmv2Wheelers)
                                    value += parseInt(entry[keys]['2wheeler'])
                                if(newState.propertyGmv4Wheelers)
                                    value+= parseInt(entry[keys]['4wheeler'])
                                if(newState.propertyGmvCurveTotal){
                                    value = 0
                                    value = parseInt(entry[keys]['2wheeler']) + parseInt(entry[keys]['4wheeler'])
                                }
                                entry[keys] =  value
                                const objToUpdate = gmv.find(obj => obj.name === keys);
                                if (objToUpdate) {
                                    objToUpdate.value = objToUpdate.value + value;
                                }
                            }
                        })
                    })  
        gmv.sort((a, b) => b.value - a.value);
        this.setState({
            tagGmv : tagData,
            apiLocationTagsGMV : gmv.map((item) => item.name)

        })
    }
    getPaymentTransDetails = (newState)=>{
        const payment = this.state.apiPaymentTypes.map(item => ({ name: item, value: 0 }));
        let tagData =  JSON.parse(JSON.stringify(this.state.apiPaymenttypeTransaction));
        tagData.map((entry)=>{
            this.state.apiPaymentTypes.map((keys)=>{
                let object = entry[keys]
                if(object){
                    let value = 0
                    if(newState.paymentTrans2Wheelers)
                        value += parseInt(entry[keys]['2wheeler'])
                    if(newState.paymentTrans4Wheelers)
                        value+= parseInt(entry[keys]['4wheeler'])
                    if(newState.paymentTransCurveTotal){
                        value = 0
                        value = parseInt(entry[keys]['2wheeler']) + parseInt(entry[keys]['4wheeler'])
                    }
                    entry[keys] =  value
                    const objToUpdate = payment.find(obj => obj.name === keys);
                    if (objToUpdate) {
                        objToUpdate.value = objToUpdate.value + value;
                    }
                }
            })
        })  
        payment.sort((a, b) => b.value - a.value);
        this.setState({
            paymenttypeTransaction : tagData,
            apiPaymentTypesTransaction : payment.map((item) => item.name)
        })
    }
    getPaymentGmvDetails = (newState)=>{
        const gmv = this.state.apiPaymentTypes.map(item => ({ name: item, value: 0 }));
        console.log(gmv)
        let tagData =  JSON.parse(JSON.stringify(this.state.apiPaymenttypeGmv));
        tagData.map((entry)=>{
            this.state.apiPaymentTypes.map((keys)=>{
                let object = entry[keys]
                if(object){
                    let value = 0
                    if(newState.paymentGmv2Wheelers)
                        value += parseInt(entry[keys]['2wheeler'])
                    if(newState.paymentGmv4Wheelers)
                        value+= parseInt(entry[keys]['4wheeler'])
                    if(newState.paymentGmvCurveTotal){
                        value = 0
                        value = parseInt(entry[keys]['2wheeler']) + parseInt(entry[keys]['4wheeler'])
                    }
                    entry[keys] =  value
                    const objToUpdate = gmv.find(obj => obj.name === keys);
                    if (objToUpdate) {
                        objToUpdate.value = objToUpdate.value + value;
                    }
                }
            })
        })  
        gmv.sort((a, b) => b.value - a.value);
        console.log(gmv,'ldldldl')
        this.setState({
            paymenttypeGmv : tagData,
            apiPaymentTypesgmv : gmv.map((item) => item.name)

        })
    }
    submitFilter = ()=>{
        if(this.state.state.length <= 0 ){
            this.setSnackState(true,"Please select the State","error")
            return
        }
        if(this.state.city.length <= 0 ){
            this.setSnackState(true,"Please select the City","error")
            return
        }
        if(this.state.propertyType.length <= 0 ){
            this.setSnackState(true,"Please select Property Type","error")
            return
        }
        if(this.state.location.length <= 0 ){
            this.setSnackState(true,"Please select the Location","error")
            return
        }
        let data = {
                "stateId": this.state.state.map(state => state.id),
                "cityId" : this.state.city.map(city => city.cityId),
                "tagId" : this.state.propertyType.map(property => property.tagId),
                "LocationId" : this.state.location.map(location => [...location.locationId]).flat(),
                "fromDate" : new Date(this.state.from).toISOString().slice(0, 7),
                "toDate" : new Date(this.state.to).toISOString().slice(0, 7)
        }
        this.setState({
            loading:true,
            gmvCurve2Wheelers:false,
            gmvCurve4Wheelers:false,
            gmvCurveTotal:true,
            transactionCurveTotal:true,
            transaction2Wheelers:false,
            transaction4Wheelers:false,
            propertyTransCurveTotal:true,
            propertyTrans2Wheelers:false,
            propertyTrans4Wheelers:false,
            propertyGmvCurveTotal:true,
            propertyGmv2Wheelers:false,
            propertyGmv4Wheelers:false,
            paymentTransCurveTotal:true,
            paymentTrans2Wheelers:false,
            paymentTrans4Wheelers:false,
            paymentGmvCurveTotal:true,
            paymentGmv2Wheelers:false,
            paymentGmv4Wheelers:false,
            statesData:null
        });
        API.post('parkingMISData' ,data).then(res=>{
            let data = res.data.data
            let tags = []
            let payment = []
            data.tagTypeData.txn.forEach(element => {
                let temp = Object.keys(element);
                temp.forEach(keys => {
                    if(!tags.includes(keys.toLowerCase()) && keys.toLowerCase() != 'name')
                        tags.push(keys.toLowerCase())
                })
            })
            data.tagTypeData.gmv.forEach(element => {
                let temp = Object.keys(element);
                temp.forEach(keys => {
                    if(!tags.includes(keys.toLowerCase()) && keys.toLowerCase() != 'name')
                        tags.push(keys.toLowerCase())
                })
            })
            data.paymentTypeData.txn.forEach(element => {
                let temp = Object.keys(element);
                temp.forEach(keys => {
                    if(!payment.includes(keys.toLowerCase()) && keys.toLowerCase() != 'name')
                        payment.push(keys.toLowerCase())
                })
            })
            data.paymentTypeData.gmv.forEach(element => {
                let temp = Object.keys(element);
                temp.forEach(keys => {
                    if(!payment.includes(keys.toLowerCase()) && keys.toLowerCase() != 'name')
                        payment.push(keys.toLowerCase())
                })
            })
            let maxVisibleTransaction = Math.max(
                this.state.transaction2Wheelers ? Math.max(...data.trendData.txn.map(item => item['2wheeler'])) : 0,
                this.state.transaction4Wheelers ? Math.max(...data.trendData.txn.map(item => item['4wheeler'])) : 0,
                this.state.transactionCurveTotal ? Math.max(...data.trendData.txn.map(item => item['total'])) : 0
            );
            let maxVisibleGmv = Math.max(
                this.state.gmvCurve2Wheelers ? Math.max(...data.trendData.gmv.map(item => item['2wheeler'])) : 0,
                this.state.gmvCurve4Wheelers ? Math.max(...data.trendData.gmv.map(item => item['4wheeler'])) : 0,
                this.state.gmvCurveTotal ? Math.max(...data.trendData.gmv.map(item => item['total'])) : 0
            );

            let headers = ['States','Active Count']
            this.setState({
                maxTransactionValue : maxVisibleTransaction,
                maxGmvValue : maxVisibleGmv,
                trendCurveTransaction : data.trendData.txn,
                apiTrendCurveTransaction : data.trendData.txn,
                trendCurveGmv : data.trendData.gmv,
                apiTrendCurveGmv : data.trendData.gmv,
                tagTransaction : data.tagTypeData.txn,
                apiTagTransaction : data.tagTypeData.txn,
                tagGmv : data.tagTypeData.gmv,
                apiTagGmv : data.tagTypeData.gmv,
                paymenttypeTransaction : data.paymentTypeData.txn,
                apiPaymenttypeTransaction : data.paymentTypeData.txn,
                paymenttypeGmv : data.paymentTypeData.gmv,
                apiPaymenttypeGmv : data.paymentTypeData.gmv,
                loading : false,
                apiLocationTags : tags,
                apiLocationTagsTransaction : tags,
                apiLocationTagsGMV : tags,
                apiPaymentTypes : payment,
                apiPaymentTypesGmv : payment,
                apiPaymentTypesTransaction : payment,
                apiCall : 1,
                statesData : { headers:headers ,body:data.locSummary}
            })
            setTimeout(() => {
                this.getPaymentGmvDetails(this.state)
                this.getTagPaymentDetails(this.state)
                this.getTagGmvDetails(this.state)
                this.getPaymentTransDetails(this.state)
            }, 0);
        }).catch((error)=>{
            this.setState({errorMessage: "Please Try Again",loading:false});
        })
    }
    async download(from){
        let element;
        if('trendTransaction' === from)
            element = document.getElementById('trendTransaction')
        else if('trendGmv' === from)
            element = document.getElementById('trendGmv')
        else if('tagTransaction')
            element = document.getElementById('tagTransaction')
        else if('tagGmv')
            element = document.getElementById('tagGmv')
        else if('paymentGmv')
            element = document.getElementById('paymentGmv')
        else if('paymentTransaction')
            element = document.getElementById('paymentTransaction')
        let canvas = await html2canvas(element);
        // Set padding values
        let padding = 40; // Change this value to your desired padding
        // Create a new off-screen canvas with padding
        let paddedCanvas = document.createElement('canvas');
        paddedCanvas.width = canvas.width + padding * 2;
        paddedCanvas.height = canvas.height + padding * 2;
        let paddedContext = paddedCanvas.getContext('2d');
        // Fill the padded canvas with a white background (optional)
        paddedContext.fillStyle = 'white';
        paddedContext.fillRect(0, 0, paddedCanvas.width, paddedCanvas.height);
        // Draw the original canvas onto the padded canvas
        paddedContext.drawImage(canvas, padding, padding);
        // Convert the padded canvas to a data URL
        let paddedData = paddedCanvas.toDataURL('image/png');
        // Create a download link
        let link = document.createElement('a');
        link.href = paddedData;
        link.download = from + '.png';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
     handleFilterClick = (filter,from,type=null) => {            
        this.setState( (prevState) => {
            if(type == null){
                if(from === 'transaction'){
                    const newState = { ...prevState, [filter]: !prevState[filter] };
                    if (!newState.transaction2Wheelers && !newState.transaction4Wheelers && !newState.transactionCurveTotal) {
                        newState[filter] = true; // Ensure one filter remains active
                    }
                    const maxVisibleValue = Math.max(
                        newState.transaction2Wheelers ? Math.max(...this.state.trendCurveTransaction.map(item => item['2wheeler'])) : 0,
                        newState.transaction4Wheelers ? Math.max(...this.state.trendCurveTransaction.map(item => item['4wheeler'])) : 0,
                        newState.transactionCurveTotal ? Math.max(...this.state.trendCurveTransaction.map(item => item['total'])) : 0
                    );
                    this.setState({
                        maxTransactionValue : maxVisibleValue
                    })
                    return newState;
                }
                else if(from === 'gmv'){
                    let gmvData = this.state.apiTrendCurveGmv
                    const newState = { ...prevState, [filter]: !prevState[filter] };
                    if (!newState.gmvCurve2Wheelers && !newState.gmvCurve4Wheelers && !newState.gmvCurveTotal) {
                        newState[filter] = true; // Ensure one filter remains active
                    }
                    const maxVisibleValue = Math.max(
                        newState.gmvCurve2Wheelers ? Math.max(...this.state.trendCurveGmv.map(item => item['2wheeler'])) : 0,
                        newState.gmvCurve4Wheelers ? Math.max(...this.state.trendCurveGmv.map(item => item['4wheeler'])) : 0,
                        newState.gmvCurveTotal ? Math.max(...this.state.trendCurveGmv.map(item => item['total'])) : 0
                    );
                    this.setState({
                        maxGmvValue : maxVisibleValue
                    })
                    return newState;
                }
            }
            else if(type == 'property'){
                if(from === 'transaction'){
                    var newState = { ...prevState, [filter]: !prevState[filter] };
                    newState = {
                        propertyTrans2Wheelers: false,
                        propertyTrans4Wheelers: false,
                        propertyTransCurveTotal: false,
                        [filter]: true
                    };
                    // if (!newState.propertyTrans2Wheelers && !newState.propertyTrans4Wheelers && !newState.propertyTransCurveTotal) {
                    //     newState[filter] = true; // Ensure one filter remains active
                    // }
                    this.getTagPaymentDetails(newState)
                    
                    return newState;
                }
                else if(from === 'gmv'){
                    let gmvData = this.state.apiTrendCurveGmv
                    var newState = { ...prevState, [filter]: !prevState[filter] };
                    newState = {
                        propertyGmv2Wheelers: false,
                        propertyGmv4Wheelers: false,
                        propertyGmvCurveTotal: false,
                        [filter]: true
                    };
                    // if (!newState.propertyGmv2Wheelers && !newState.propertyGmv4Wheelers && !newState.propertyGmvCurveTotal) {
                    //     newState[filter] = true; // Ensure one filter remains active
                    // }
                    this.getTagGmvDetails(newState)
                    return newState;
                }
            }
            else if(type == 'payment'){
                if(from === 'transaction'){
                    var newState = { ...prevState, [filter]: !prevState[filter] };
                    newState = {
                        paymentTrans2Wheelers: false,
                        paymentTrans4Wheelers: false,
                        paymentTransCurveTotal: false,
                        [filter]: true
                    };
                    this.getPaymentTransDetails(newState)
                    return newState;
                }
                else if(from === 'gmv'){
                    let gmvData = this.state.apiTrendCurveGmv
                    var newState = { ...prevState, [filter]: !prevState[filter] };
                    newState = {
                        paymentGmv2Wheelers: false,
                        paymentGmv4Wheelers: false,
                        paymentGmvCurveTotal: false,
                        [filter]: true
                    };
                    this.getPaymentGmvDetails(newState)
                    return newState;
                }
            }
        });
      }
    render (){
        const DataFormater = (number) => {
            if (number >= 1e9) {
              return (number / 1e9).toFixed(1) + 'B';
            } else if (number >= 1e6) {
              return (number / 1e6).toFixed(1) + 'M';
            } else if (number >= 1e3) {
              return (number / 1e3).toFixed(1) + 'K';
            } else {
              return number.toString();
            }
          };
          const TrendCurveTooltip = ({ active, payload, label }) => {
            if (active) {
              return (
                <div className='tooltipContainer'>
                  <div className='tooltipElements'> <b>{payload[0].payload.name}</b></div>
                  <div className='tooltipElements'><p>2-Wheeler : </p> <p><b>{new Intl.NumberFormat('en-IN').format((payload[0].payload['2wheeler']))}</b></p></div>
                  <div className='tooltipElements'><p>4-Wheeler : </p> <p><b>{new Intl.NumberFormat('en-IN').format((payload[0].payload['4wheeler']))}</b></p></div>
                  <div className='tooltipElements'><p>Total : </p><p> <b>{new Intl.NumberFormat('en-IN').format((payload[0].payload['total']))}</b></p></div>
                </div>
              );
            }
        }
        const PaymentTooltip = ({ active, payload, keys }) => {
            if (active) {
              return (
                <div className='tooltipContainer'>
                  <div className='tooltipElements'> <b>{payload[0].payload.name}</b></div>
                  {/* <div className='tooltipElements'><p>Cash : </p> <p><b>{new Intl.NumberFormat('en-IN').format((payload[0].payload['cash'] ? payload[0].payload['cash'] : 0))}</b></p></div>
                  <div className='tooltipElements'><p>Fast Tag : </p> <p><b>{new Intl.NumberFormat('en-IN').format((payload[0].payload['fastag'] ? payload[0].payload['fastag'] : 0))}</b></p></div>
                  <div className='tooltipElements'><p>HW : </p><p> <b>{new Intl.NumberFormat('en-IN').format((payload[0].payload['hw'] ? payload[0].payload['hw'] : 0))}</b></p></div>
                  <div className='tooltipElements'><p>Paytm : </p><p> <b>{new Intl.NumberFormat('en-IN').format((payload[0].payload['paytm'] ? payload[0].payload['paytm'] : 0))}</b></p></div>
                  <div className='tooltipElements'><p>UPI/Paytm : </p><p> <b>{new Intl.NumberFormat('en-IN').format((payload[0].payload['upi/paytm'] ? payload[0].payload['upi/paytm'] : 0))}</b></p></div>
                  <div className='tooltipElements'><p>UPI : </p><p> <b>{new Intl.NumberFormat('en-IN').format((payload[0].payload['upi'] ? payload[0].payload['upi'] : 0))}</b></p></div>
                  <div className='tooltipElements'><p>Card : </p><p> <b>{new Intl.NumberFormat('en-IN').format((payload[0].payload['card'] ? payload[0].payload['card'] : 0))}</b></p></div> */}                 
                    {
                    keys.map((data)=>{
                        return (
                            <div className='tooltipElements'><p>{data.charAt(0).toUpperCase() + data.slice(1)} : </p> <p><b>{new Intl.NumberFormat('en-IN').format((payload[0].payload[data] ? payload[0].payload[data] : 0 ))}</b></p></div>
                        )
                    })
                  }
                </div>
              );
            }
        }
        const PropertyTooltip = ({ active, payload, keys }) => {
            if (active) {
              return (
                <div className='tooltipContainer'>
                  <div className='tooltipElements'> <b>{payload[0].payload.name}</b></div>
                  {
                    keys.map((data)=>{
                        return (
                            <div className='tooltipElements'><p>{data.charAt(0).toUpperCase() + data.slice(1)} : </p> <p><b>{new Intl.NumberFormat('en-IN').format((payload[0].payload[data] ? payload[0].payload[data] : 0 ))}</b></p></div>
                        )
                    })
                  }
                </div>
              );
            }
        }
        
       return (
            <div>
                <ContainerHeader title={<IntlMessages id="MIS Reports"/>} match={this.props.match}/>
                {
                    this.state.isAuthorized ? 
                    <>
                        {
                        this.state.MISReports.totalGMV ? 
                        <>
                            <div className="row row justify-content-center">
                            <div className="col-xl-3 col-lg-6 col-md-10 col-sm-10">
                                <div style={{borderRadius:"10px"}} className="jr-card p-0 overflow-hidden card-entire-box1">
                                    <div className='card-designer'></div>
                                    <div className='row m-0 card-body box1'>
                                        <div className='rounded-container-parent card-entire-box1'>
                                            <div className='rounded'>
                                                    <img className='rounded-image' alt='' src={rupee}/>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-flex-start justify-content-center flex-column w-50 ml-2'>
                                            <Typography variant='h6' className='card-header-title text-white'>Total GMV</Typography>
                                            <Typography variant='h5' className='text-white'>₹{ new Intl.NumberFormat('en-IN').format((this.state.MISReports.totalGMV))}</Typography>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-10 col-sm-10">
                                <div style={{borderRadius:"10px"}} className="jr-card p-0 overflow-hidden card-entire-box2">
                                    <div className='card-designer'></div>
                                    <div className='row m-0 card-body box2'>
                                        <div className='rounded-container-parent card-entire-box2'>
                                            <div className='rounded'>
                                                    <img className='rounded-image' alt='' src={transactions}/>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-flex-start justify-content-center flex-column w-50 ml-2'>
                                            <Typography variant='h6' className='card-header-title text-white'>Total Transactions</Typography>
                                            <Typography variant='h5' className='text-white'>{new Intl.NumberFormat('en-IN').format(this.state.MISReports.totalTxns)}</Typography>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-10 col-sm-10">
                                <div style={{borderRadius:"10px"}} className="jr-card p-0 overflow-hidden card-entire-box3">
                                    <div className='card-designer'></div>
                                    <div className='row m-0 card-body box3'>
                                        <div className='rounded-container-parent card-entire-box3'>
                                            <div className='rounded'>
                                                    <img className='rounded-image' alt='' src={liveDevice}/>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-flex-start justify-content-center flex-column w-50 ml-2'>
                                            <Typography variant='h6' className='card-header-title text-white'>Live Devices</Typography>
                                            <Typography variant='h5' className='text-white'>{new Intl.NumberFormat('en-IN').format(this.state.MISReports.liveDevices)}</Typography>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        {/* </div> 
                        <div className='row justify-content-center'>  */}
                        <div className="col-xl-3 col-lg-6 col-md-10 col-sm-10">
                            <div style={{borderRadius:"10px"}} className="jr-card p-0 overflow-hidden card-entire-box4">
                                    <div className='card-designer'></div>
                                    <div className='row m-0 card-body box4'>
                                        <div className='rounded-container-parent card-entire-box4'>
                                            <div className='rounded'>
                                                    <img className='rounded-image' alt='' src={liveLocation}/>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-flex-start justify-content-center flex-column w-50 ml-2'>
                                            <Typography variant='h6' className='card-header-title text-white'>Live Locations</Typography>
                                            <Typography variant='h5' className='text-white'>{new Intl.NumberFormat('en-IN').format(this.state.MISReports.liveLocations)}</Typography>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className="row row justify-content-start">
                                <div className="col-xl-3 col-lg-6 col-md-10 col-sm-10">
                                    <div style={{borderRadius:"10px"}} className="jr-card p-0 overflow-hidden card-entire-box5">
                                        <div className='card-designer'></div>
                                        <div className='row m-0 card-body box5'>
                                            <div className='rounded-container-parent card-entire-box5'>
                                                <div className='rounded'>
                                                        <img className='rounded-image' alt='' src={fastag}/>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-flex-start justify-content-center flex-column w-50 ml-2'>
                                                <Typography variant='h6' className='card-header-title text-white'>FASTag Transactions</Typography>
                                                <Typography variant='h5' className='text-white'>{new Intl.NumberFormat('en-IN').format(this.state.MISReports.fastagTxns)}</Typography>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            </>
                        
                        :
                            <></>
                        }              
                       <div className='row'>
                       <div className='col-xl-6 col-sm-12'>
                            <div className="jr-card">
                                <h3 className="mb-3 text-center">
                                    Our Presence (City wise)
                                </h3>
                                <div className='position-relative' style={{minHeight:'26rem'}}>
                                        <MapWithCircles mapLocations={this.state.mapLocations} />

                                </div>
                            </div>
                        </div>
                        <div className='col-xl-6 col-sm-12'>
                            <div className="jr-card">
                                <h3 className="mb-3 text-center">
                                    System Footprint
                                </h3>
                                <div className='position-relative' style={{minHeight:'26rem'}}>
                                        <EZMap markers={this.state.mapLocations} />

                                </div>
                            </div>
                        </div>
                       </div>
                        <div className="row justify-content-between">
                            <div className='col-xl-6 col-md-12 col-sm-12'>
                                <div className="jr-card position-relative height-auto mapContainer">
                                    <h3 className="mb-3 text-center">
                                        Leading Locations (Since {moment().subtract(1, 'months').format('MMM-YY')})
                                    </h3> 
                                    <div style={{height:'26rem',overflow:"auto"}} >
                                        { this.state.topLocations && 
                                            <MISTable
                                                tableHeading="MIS Live Location"
                                                table={this.state.topLocations}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-6 col-sm-12'>
                                <div className="jr-card position-relative height-auto mapContainer">
                                    <h3 className="mb-3 text-center">
                                        Recent Locations (Last 30 days)
                                    </h3> 
                                    <div style={{height:'26rem',overflow:"auto"}} >
                                        { this.state.liveLocations && 
                                            <MISTable
                                                tableHeading="MIS Live Location"
                                                table={this.state.liveLocations}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                            <div className='col-12 p-0'>
                                <div className='jr-card'>
                                    <div className=''>
                                        <Typography variant='h6'>MIS REPORT</Typography>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-3 col-sm-6">
                                        <FormControl className="w-100 mb-2">
                                            <InputLabel htmlFor="locationTag"  >State</InputLabel>
                                            <Select
                                                value={this.state.state}
                                                onChange={this.handleOnChange}
                                                name="state"
                                                input={<Input style={{textAlign:'left'}} id="state"/>}
                                                multiple
                                            >
                                                {this.state.states && this.state.states.length > 0 &&this.state.states.map((item, i) => (
                                                <MenuItem
                                                    key={"state-"+item.id}
                                                    value={item}
                                                    data-id={item.id}
                                                >
                                                    {item.stateName}
                                                </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        </div>
                                        <div className="col-md-3 col-sm-6">
                                        <FormControl className="w-100 mb-2">
                                            <InputLabel htmlFor="locationTag">City</InputLabel>
                                            <Select
                                                value={this.state.city}
                                                onChange={this.handleOnChange}
                                                name="city"
                                                input={<Input style={{textAlign:'left'}} id="city"/>}
                                                multiple
                                            >
                                                {this.state.citys && this.state.citys.length > 0 &&this.state.citys.map((item, i) => (
                                                <MenuItem
                                                    key={"city-"+item.cityId}
                                                    value={item}
                                                    data-id={item.cityId}
                                                >
                                                    {item.cityName}
                                                </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        </div>
                                        <div className="col-md-3 col-sm-6">
                                        <FormControl className="w-100 mb-2">
                                            <InputLabel htmlFor="locationTag"  >Property Type</InputLabel>
                                            <Select
                                                value={this.state.propertyType}
                                                onChange={this.handleOnChange}
                                                name="propertyType"
                                                input={<Input style={{textAlign:'left'}} id="propertyType"/>}
                                                multiple
                                            >
                                                {this.state.propertyTypes && this.state.propertyTypes.length > 0 &&this.state.propertyTypes.map((item, i) => (
                                                <MenuItem
                                                    key={"location_tag-"+item.tagId}
                                                    value={item}
                                                    data-id={item.tagId}
                                                >
                                                    {item.tagName}
                                                </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        </div>
                                        <div className="col-md-3 col-sm-6">
                                        <FormControl className="w-100 mb-2">
                                            <InputLabel htmlFor="locationTag"  >Location</InputLabel>
                                            <Select
                                                value={this.state.location}
                                                onChange={this.handleOnChange}
                                                name="location"
                                                input={<Input style={{textAlign:'left'}} id="location"/>}
                                                multiple
                                            >
                                                {this.state.locations && this.state.locations.length > 0 &&this.state.locations.map((item, i) => (
                                                <MenuItem
                                                    key={"location_tag-"+item.locationName}
                                                    value={item}
                                                    data-id={item.locationId}
                                                >
                                                    {item.locationName}
                                                </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        {/* START DATE */}
                                        <div className="col-md-3 col-sm-6">
                                            {/* <FormHelperText >Start Date</FormHelperText> */}
                                            <DatePicker
                                                fullWidth
                                                label="Start Date"
                                                format="MMM YY"
                                                name="from"
                                                // openTo="year"
                                                views={[ "month","year"]}
                                                minDate={new Date(2016, 0, 1)}
                                                maxDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())}
                                                value={this.state.from}
                                                onChange={this.handleChange('from')}
                                                animateYearScrolling = {true}
                                            />
                                        </div>
                                        {/* END DATE */}
                                        <div className="col-md-3 col-sm-6">
                                            <DatePicker
                                                fullWidth
                                                label="End Date"
                                                format="MMM YY"
                                                name="to"
                                                value={this.state.to}
                                                views={[ "month","year"]}
                                                // minDate={this.state.from ? this.getRoundedDate(15, new Date(this.state.from)) : this.getRoundedDate(15, new Date())}
                                                minDate={new Date(2016, 0, 1)}
                                                maxDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())}
                                                onChange={this.handleChange('to')}
                                                animateYearScrolling
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2 mb-4">   
                                    <div className="col-lg-6 col-sm-12 col-12">
                                        <Box mt="20px">
                                            <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" onClick={() => this.submitFilter()}> Get Details </Button></Box>
                                            <Box mr="10px" display="inline"><Button variant="contained" color="secondary" mr="5px" onClick={() =>this.resetField() }> Reset </Button></Box>
                                        </Box>
                                    </div> 
                                </div>  
                                </div>
                            </div>
                        {
                            this.state.loading ? 
                        
                            <div><LinearProgress color="primary" /><LinearProgress color="primary" /></div>
                            : 
                            
                                this.state.tagTransaction.length > 0 || this.state.tagGmv.length > 0 || this.state.paymenttypeTransaction.length > 0 || this.state.paymenttypeGmv.length > 0  ||
                                this.state.trendCurveTransaction.length > 0 || this.state.trendCurveGmv.length > 0 ? 
                                <>
                                    
                                    {
                                        this.state.statesData ? 
                                            <div className='jr-card'>
                                                <MISTable
                                                        tableHeading="state statan"
                                                        table={this.state.statesData}
                                                        isState = 'true'
                                                    />
                                            </div>
                                            :
                                            <></>
                                    }                                    
                                        
                                    <Typography className='mb-3'>Trend Curve</Typography>
                                    <div className='d-flex justify-content-center'>
                                        <div className='col-12 row justify-content-between'>
                                            {/* <div className=' row'> */}
                                                <div className='row  col-xl-6  col-sm-12 jr-card'>
                                                    <div className='row justify-content-between w-100 mb-5'>
                                                        <div className='d-flex justify-content-center align-items-center mb-2'>
                                                            <h3 className="text-left m-0 p-0">
                                                                    TRANSACTION
                                                            </h3> 
                                                            <div className={` mr-1 ml-1  align-items-center p-0 d-flex justify-content-center`} onClick={()=>{this.download('trendTransaction')}} ><img style={{width:"20px",marginLeft : "10px", cursor:"pointer"}} alt='' src={download}/></div>

                                                        </div>
                                                        <div className='d-flex justify-content-around'>
                                                            <div className={`graph-filters mr-1 ${this.state.transaction2Wheelers? 'activeFilter' : ''}`}  onClick={() => this.handleFilterClick('transaction2Wheelers','transaction')} >2 Wheeler</div>
                                                            <div className={`graph-filters mr-1 ${this.state.transaction4Wheelers? 'activeFilter' : ''}`} onClick={() => this.handleFilterClick('transaction4Wheelers','transaction')} >4 Wheeler</div>
                                                            <div className={`graph-filters mr-1 ${this.state.transactionCurveTotal? 'activeFilter' : ''}`} onClick={() => this.handleFilterClick('transactionCurveTotal','transaction')} >TOTAL</div>
                                                        </div>
                                                    </div>
                                                    <ResponsiveContainer width="100%" height={200} id="trendTransaction">
                                                        <LineChart  data={this.state.trendCurveTransaction} margin={{top: 10, right: 10, left: 10, bottom: 0}}>
                                                            <XAxis  dataKey="name"/>
                                                            <YAxis domain={[0, this.state.maxTransactionValue]} tickFormatter={DataFormater}/>
                                                            <CartesianGrid strokeDasharray="3 3"/>
                                                            <Tooltip content={<TrendCurveTooltip />} labelStyle={{color: 'black'}} itemStyle={{color: 'black'}} cursor={false}/>
                                                            <Legend/>
                                                            {
                                                                this.state.transactionCurveTotal ?
                                                                <Line isAnimationActive={false}  type="monotone" dataKey="total" stroke={"#BC4B51"} activeDot={{r: 8}} />
                                                                :<></>
                                                            }
                                                            {
                                                                this.state.transaction2Wheelers ?
                                                                <Line isAnimationActive={false} type="monotone" dataKey="2wheeler" stroke={"#8CB369"}  activeDot={{r: 8}} />
                                                                :<></>
                                                            }
                                                            {
                                                                this.state.transaction4Wheelers ?
                                                                <Line isAnimationActive={false} type="monotone" dataKey="4wheeler" stroke={"#F4A259"} activeDot={{r: 8}} />
                                                                :<></>
                                                            }
                                                            
                                                        </LineChart>
                                                    </ResponsiveContainer>
                                                </div>
                                                <div className='row col-xl-6  col-sm-12 jr-card'>
                                                    <div className='mb-5 row justify-content-between align-items-center  w-100'>
                                                        <div className='d-flex justify-content-center align-items-center mb-2'>
                                                            <h3 className="text-left m-0 p-0">
                                                                    GMV
                                                            </h3> 
                                                            <div className={`ml-1 mr-1 align-items-center p-0 d-flex justify-content-center`} onClick={()=>{this.download('trendGmv')}} ><img style={{width:"20px",marginLeft : "10px", cursor:"pointer"}} alt='' src={download}/></div>
                                                        </div>
                                                        <div className='row justify-content-between align-items-center'>
                                                            {/* <div className={`graph-filters mr-1 ${this.state.transaction2Wheelers? 'activeFilter' : ''}`} onClick={()=>{this.setState((prevState)=>({transaction2Wheelers : !prevState.transaction2Wheelers}))}}>2 Wheeler</div>
                                                            <div className={`graph-filters mr-1 ${this.state.transaction4Wheelers? 'activeFilter' : ''}`} onClick={()=>{this.setState((prevState)=>({transaction4Wheelers : !prevState.transaction4Wheelers}))}}>4 Wheeler</div>
                                                            <div className={`graph-filters mr-1 ${this.state.transactionCurveTotal? 'activeFilter' : ''}`} onClick={()=>{this.setState((prevState)=>({transactionCurveTotal : !prevState.transactionCurveTotal}))}}>TOTAL</div> */}
                                                            <div className={`graph-filters mr-1 ${this.state.gmvCurve2Wheelers? 'activeFilter' : ''}`}  onClick={() => this.handleFilterClick('gmvCurve2Wheelers','gmv')} >2 Wheeler</div>
                                                            <div className={`graph-filters mr-1 ${this.state.gmvCurve4Wheelers? 'activeFilter' : ''}`} onClick={() => this.handleFilterClick('gmvCurve4Wheelers','gmv')} >4 Wheeler</div>
                                                            <div className={`graph-filters mr-1 ${this.state.gmvCurveTotal? 'activeFilter' : ''}`} onClick={() => this.handleFilterClick('gmvCurveTotal','gmv')} >TOTAL</div>
                                                       
                                                        </div>
                                                    </div>
                                                    <ResponsiveContainer width="100%" height={200} id="trendGmv">
                                                        <LineChart data={this.state.trendCurveGmv} margin={{top: 10, right: 10, left: 10, bottom: 0}}>
                                                            <XAxis dataKey="name"/>
                                                            <YAxis  domain={[0,this.state.maxGmvValue]} tickFormatter={DataFormater}/>
                                                            <CartesianGrid strokeDasharray="3 3"/>
                                                            <Tooltip content={<TrendCurveTooltip />} labelStyle={{color: 'black'}} itemStyle={{color: 'black'}} cursor={false}/>
                                                            <Legend/>
                                                            {
                                                                this.state.gmvCurve2Wheelers ?
                                                                <Line isAnimationActive={false} type="monotone" dataKey="2wheeler" stroke={"#8CB369"}  activeDot={{r: 8}} />
                                                                : <></>
                                                            }
                                                            {
                                                                this.state.gmvCurve4Wheelers ?
                                                                <Line isAnimationActive={false} type="monotone" dataKey="4wheeler" stroke={"#F4A259"} activeDot={{r: 8}} />
                                                                : <></>
                                                            }
                                                            {
                                                                this.state.gmvCurveTotal ?
                                                                <Line isAnimationActive={false} type="monotone" dataKey="total" stroke={"#BC4B51"} activeDot={{r: 8}} />
                                                                :<></>
                                                            }
                                                        </LineChart>
                                                    </ResponsiveContainer>
                                                </div>
                                            {/* </div> */}
                                        </div>
                                    </div>
                                    <Typography className='mb-3'>Property Type</Typography>
                                    <div className='d-flex justify-content-center'>
                                        <div className='col-12 row justify-content-between'>       
                                            <div className='row  col-xl-6  col-sm-12 jr-card'>
                                                <div className='row justify-content-between w-100 mb-5'>
                                                        <div className='d-flex justify-content-center align-items-center'>
                                                            <h3 className="text-left m-0 p-0">
                                                                TRANSACTION
                                                            </h3> 
                                                            <div className={` ml-1 mr-1 align-items-center p-0 d-flex justify-content-center`} onClick={()=>{this.download('tagTransaction')}} ><img style={{width:"20px",marginLeft : "10px", cursor:"pointer"}} alt='' src={download}/></div>
                                                        </div>
                                                        <div className='d-flex justify-content-around'>
                                                            <div className={`graph-filters mr-1 ${this.state.propertyTrans2Wheelers? 'activeFilter' : ''}`}  onClick={() => this.handleFilterClick('propertyTrans2Wheelers','transaction','property')} >2 Wheeler</div>
                                                            <div className={`graph-filters mr-1 ${this.state.propertyTrans4Wheelers? 'activeFilter' : ''}`} onClick={() => this.handleFilterClick('propertyTrans4Wheelers','transaction','property')} >4 Wheeler</div>
                                                            <div className={`graph-filters mr-1 ${this.state.propertyTransCurveTotal? 'activeFilter' : ''}`} onClick={() => this.handleFilterClick('propertyTransCurveTotal','transaction','property')} >TOTAL</div>
                                                        </div>
                                                    </div>
                                                <ResponsiveContainer width="100%" height={200} id="tagTransaction">
                                                    <BarChart data={this.state.tagTransaction} margin={{top: 10, right: 10, left: 10, bottom: 0}}>
                                                        <XAxis dataKey="name"/>
                                                        <YAxis tickFormatter={DataFormater}/>
                                                        <CartesianGrid strokeDasharray="3 3"/>
                                                        <Tooltip content={<PropertyTooltip keys = {this.state.apiLocationTagsTransaction} />} labelStyle={{color: 'black'}} itemStyle={{color: 'black'}} cursor={false}/>
                                                        <Legend/>
                                                        <defs>
                                                            <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                                                                <stop offset="5%" stopColor="red" stopOpacity={1}/>
                                                                <stop offset="95%" stopColor="black" stopOpacity={1}/>
                                                            </linearGradient>
                                                        </defs>
                                                        <defs>
                                                            <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
                                                                <stop offset="5%" stopColor="#1ABBDE" stopOpacity={1}/>
                                                                <stop offset="95%" stopColor="#09BCA7" stopOpacity={1}/>
                                                            </linearGradient>
                                                        </defs>
                                                        {
                                                            this.state.apiLocationTagsTransaction.map( (tags,index) =>{
                                                                return (
                                                                    <Bar maxBarSize={30}  dataKey={tags} stackId="a" fill={this.state.colours[index%9]}/>
                                                                )
                                                            })
                                                        }                                      
                                                    </BarChart>  
                                                </ResponsiveContainer>
                                            </div>
                                            <div className='row  col-xl-6  col-sm-12 jr-card'>
                                                <div className='row justify-content-between w-100 mb-5'>
                                                        <div className='d-flex justify-content-center align-items-center'>
                                                            <h3 className="text-left p-0 m-0">
                                                                GMV
                                                            </h3> 
                                                            <div className={`ml-1 mr-1 align-items-center p-0 d-flex justify-content-center`} onClick={()=>{this.download('tagGmv')}} ><img style={{width:"20px",marginLeft : "10px" , cursor:"pointer"}} alt='' src={download}/></div>
                                                        </div>
                                                        <div className='d-flex justify-content-around'>
                                                            <div className={`graph-filters mr-1 ${this.state.propertyGmv2Wheelers? 'activeFilter' : ''}`}  onClick={() => this.handleFilterClick('propertyGmv2Wheelers','gmv','property')} >2 Wheeler</div>
                                                            <div className={`graph-filters mr-1 ${this.state.propertyGmv4Wheelers? 'activeFilter' : ''}`} onClick={() => this.handleFilterClick('propertyGmv4Wheelers','gmv','property')} >4 Wheeler</div>
                                                            <div className={`graph-filters mr-1 ${this.state.propertyGmvCurveTotal? 'activeFilter' : ''}`} onClick={() => this.handleFilterClick('propertyGmvCurveTotal','gmv','property')} >TOTAL</div>
                                                        </div>
                                                    </div>
                                                <ResponsiveContainer width="100%" height={200} id="tagGmv">
                                                    <BarChart data={this.state.tagGmv} margin={{top: 10, right: 10, left: 10, bottom: 0}}>
                                                        <XAxis dataKey="name"/>
                                                        <YAxis tickFormatter={DataFormater}/>
                                                        <CartesianGrid strokeDasharray="3 3"/>
                                                        <Tooltip content={<PropertyTooltip keys = {this.state.apiLocationTagsGMV} />} labelStyle={{color: 'black'}} itemStyle={{color: 'black'}} cursor={false}/>
                                                        <Legend/>
                                                        <defs>
                                                            <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                                                                <stop offset="5%" stopColor="#6200EE" stopOpacity={1}/>
                                                                <stop offset="95%" stopColor="#B819D2" stopOpacity={1}/>
                                                            </linearGradient>
                                                        </defs>
                                                        <defs>
                                                            <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
                                                                <stop offset="5%" stopColor="#1ABBDE" stopOpacity={1}/>
                                                                <stop offset="95%" stopColor="#09BCA7" stopOpacity={1}/>
                                                            </linearGradient>
                                                        </defs>
                                                        {
                                                            this.state.apiLocationTagsGMV.map( (tags,index) =>{
                                                                return (
                                                                    <Bar maxBarSize={30} dataKey={tags} stackId="a" fill={this.state.colours[index%9]}/>
                                                                )
                                                            })
                                                        }
                                                    </BarChart>
                                                </ResponsiveContainer>
                                            </div>
                                        </div>
                                    </div>
                                    <Typography className='mb-3'>Payment Type</Typography>
                                    <div className='d-flex justify-content-center'>
                                        <div className='col-12 row justify-content-between'>       
                                        <div className='row  col-xl-6  col-sm-12 jr-card'>
                                                <div className='row justify-content-between w-100 mb-5'>
                                                        <div className='d-flex justify-content-center align-items-center'>
                                                            <h3 className="text-left m-0 p-0">
                                                                TRANSACTION
                                                            </h3> 
                                                            <div className={`ml-1 mr-1 align-items-center p-0 d-flex justify-content-center`} onClick={()=>{this.download('paymentTransaction')}} ><img style={{width:"20px",marginLeft : "10px", cursor:"pointer"}} alt='' src={download}/></div>
                                                        </div>
                                                        <div className='d-flex justify-content-around'>
                                                            <div className={`graph-filters mr-1 ${this.state.paymentTrans2Wheelers? 'activeFilter' : ''}`}  onClick={() => this.handleFilterClick('paymentTrans2Wheelers','transaction','payment')} >2 Wheeler</div>
                                                            <div className={`graph-filters mr-1 ${this.state.paymentTrans4Wheelers? 'activeFilter' : ''}`} onClick={() => this.handleFilterClick('paymentTrans4Wheelers','transaction','payment')} >4 Wheeler</div>
                                                            <div className={`graph-filters mr-1 ${this.state.paymentTransCurveTotal? 'activeFilter' : ''}`} onClick={() => this.handleFilterClick('paymentTransCurveTotal','transaction','payment')} >TOTAL</div>
                                                        </div>
                                                    </div>
                                                <ResponsiveContainer width="100%" height={200} id="paymentTransaction">
                                                <BarChart data={this.state.paymenttypeTransaction} margin={{top: 10, right: 10, left: 10, bottom: 0}}>
                                                    <XAxis dataKey="name"/>
                                                    <YAxis tickFormatter={DataFormater}/>
                                                    <CartesianGrid strokeDasharray="3 3"/>
                                                    <Tooltip content={<PaymentTooltip keys={this.state.apiPaymentTypesTransaction} />} labelStyle={{color: 'black'}} itemStyle={{color: 'black'}} cursor={false}/>
                                                    <Legend/>
                                                        {/* <Bar maxBarSize={30} dataKey="upi" stackId="a" fill="#8CB369" />
                                                        <Bar maxBarSize={30} dataKey="fastag" stackId="a" fill="#F4E285" />
                                                        <Bar maxBarSize={30} dataKey="cash" stackId="a" fill="#F4A259" />
                                                        <Bar maxBarSize={30} dataKey="paytm" stackId="a" fill="#BC4B51" />
                                                        <Bar maxBarSize={30} dataKey="hw" stackId="a" fill="#BC4B51" />
                                                        <Bar maxBarSize={30} dataKey="card" stackId="a" fill="#929ff0" />
                                                        <Bar maxBarSize={30} dataKey="upi/paytm" stackId="a" fill="#5B8E7D" /> */}
                                                        {
                                                            this.state.apiPaymentTypesTransaction.map( (tags,index) =>{
                                                                return (
                                                                    <Bar maxBarSize={30}  dataKey={tags} stackId="a" fill={this.state.colours[index%9]}/>
                                                                )
                                                            })
                                                        }    
                                                </BarChart>
                                            </ResponsiveContainer>
                                            </div>
                                            <div className='row  col-xl-6  col-sm-12 jr-card'>
                                                <div className='row justify-content-between w-100 mb-5'>
                                                        <div className='d-flex justify-content-center align-items-center'>
                                                            <h3 className="text-left m-0 p-0">
                                                                GMV 
                                                            </h3> 
                                                            <div className={`ml-1 mr-1 align-items-center p-0 d-flex justify-content-center`} onClick={()=>{this.download('paymentGmv')}} ><img style={{width:"20px",marginLeft : "10px", cursor:"pointer"}} alt='' src={download}/></div>
                                                        </div>
                                                        <div className='d-flex justify-content-around'>
                                                            <div className={`graph-filters mr-1 ${this.state.paymentGmv2Wheelers? 'activeFilter' : ''}`}  onClick={() => this.handleFilterClick('paymentGmv2Wheelers','gmv','payment')} >2 Wheeler</div>
                                                            <div className={`graph-filters mr-1 ${this.state.paymentGmv4Wheelers? 'activeFilter' : ''}`} onClick={() => this.handleFilterClick('paymentGmv4Wheelers','gmv','payment')} >4 Wheeler</div>
                                                            <div className={`graph-filters mr-1 ${this.state.paymentGmvCurveTotal? 'activeFilter' : ''}`} onClick={() => this.handleFilterClick('paymentGmvCurveTotal','gmv','payment')} >TOTAL</div>
                                                        </div>
                                                    </div>
                                                <ResponsiveContainer width="100%" height={200} id="paymentGmv">
                                                <BarChart data={this.state.paymenttypeGmv} margin={{top: 10, right: 10, left: 10, bottom: 0}}>
                                                    <XAxis dataKey="name"/>
                                                    <YAxis tickFormatter={DataFormater}/>
                                                    <CartesianGrid strokeDasharray="3 3"/>
                                                    <Tooltip content={<PaymentTooltip  keys = {this.state.apiPaymentTypesgmv}/>}  labelStyle={{color: 'black'}} itemStyle={{color: 'black'}} cursor={false}/>
                                                    <Legend/>
                                                        {/* <Bar maxBarSize={30} dataKey="upi" stackId="a" fill="#8CB369" />
                                                        <Bar maxBarSize={30} dataKey="fastag" stackId="a" fill="#F4E285" />
                                                        <Bar maxBarSize={30} dataKey="cash" stackId="a" fill="#F4A259" />
                                                        <Bar maxBarSize={30} dataKey="paytm" stackId="a" fill="#BC4B51" />
                                                        <Bar maxBarSize={30} dataKey="hw" stackId="a" fill="#BC4B51" />
                                                        <Bar maxBarSize={30} dataKey="card" stackId="a" fill="#929ff0" />
                                                        <Bar maxBarSize={30} dataKey="upi/paytm" stackId="a" fill="#5B8E7D" /> */}
                                                        {
                                                            this.state.apiPaymentTypesgmv.map( (tags,index) =>{
                                                                return (
                                                                    <Bar maxBarSize={30}  dataKey={tags} stackId="a" fill={this.state.colours[index%9]}/>
                                                                )
                                                            })
                                                        }   
                                                </BarChart>
                                            </ResponsiveContainer>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                    this.state.apiCall == 1 ?                            
                                    <div><Alert className="shadow-lg" color="primary">No data for this period !</Alert></div>     
                                    :<></>                           
                        }
                    </>
                    :
                    <></>
                }

                <SnackBar 
                open={this.state.snackOpen} 
                handleClose={this.handleSnackClose.bind(this)}
                message={this.state.snackMessage}
                type={this.state.snackType}
                />
            </div>
        )
    }
}


export default  MIS;