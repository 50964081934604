import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';
import LinearProgress from "@material-ui/core/LinearProgress";
import { Formik, Form, connect } from "formik";
import FormField from "components/FormField";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  console.log("AlertDialogSlide",props)
  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={props.fullWidth}
        onClose={props.handleDialogClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <LinearProgress style={{visibility:props.showProgress ? 'visible' : 'hidden'}} color="primary" />
        <DialogTitle id="alert-dialog-slide-title">{props.header}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.content}
          </DialogContentText>
        </DialogContent>
        {!props.showMessage && <DialogActions>
          {props.disAgreeBtnTxt && <Button variant="outlined"  color="secondary" onClick={props.handleDialogClose}>
            {props.disAgreeBtnTxt}
          </Button>}
          {
            props.agreeBtnTxt && <Button variant="contained" color="primary" onClick={props.agreeClicked} disabled={props.isBtnDisabled}>
            {props.agreeBtnTxt}
            </Button>
          }
        </DialogActions>}
        {props.showMessage && <DialogActions>
          <Box display="inline-block">
              <Button onClick={props.handleDialogClose} variant="outlined">
                  OK
              </Button>                                     
          </Box>
        </DialogActions>}
      </Dialog>
    </div>
  );
}

export const DialogBox=(props)=>{
  return(
      <Dialog
          open={props.open}
          fullWidth
          maxWidth={'md'}
          disableBackdropClick
          onClose={props.onClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
      >
          <DialogTitle id="alert-dialog-slide-title">{props.header}
          </DialogTitle>
          <DialogContent>
              {props.children}
          </DialogContent>
          <DialogActions>
              <Button variant="outlined"   color="secondary" onClick={props.onClose}>
                  {'Close'}
              </Button>
              <Button variant="outlined" type="submit" onClick={props.onSubmit}  color="primary">
                  {'Submit'}
              </Button>
          </DialogActions>
    </Dialog>
  )
}

const EZFields = connect((props) => {
  useEffect(() => {
    if (props.onChange) {
      props.onChange(props.formik);
    }
  }, [props.formik.values])

  return (
    <div className="row">
      {props.fields?.map((field) => (
        field && !field.hidden &&
        <div className={`col-md-${field.size == "lg" ? '3' : field.size} col-12`} key={`field-${field.key}`}>
          <FormField field={{ ...field, ...{ name: field.key } }} />
        </div>
      ))}
    </div>
  )
})

export const DialogForm = (props) => {
  const [initValues, setInitValues] = useState();

  const getFieldValues = (fields) => {
    const values = fields.reduce((obj, item) => {
      return { ...obj, [item.key]: item.value };
    }, {});
    return values;
  }

  useEffect(() => {
    if (props.fields) {
      setInitValues(getFieldValues(props.fields));
    }
  }, [props.fields])

  return (
    <Dialog
      open={props.open}
      fullWidth
      maxWidth={'md'}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      {initValues &&
        <Formik
          onSubmit={props.onSubmit}
          initialValues={initValues}
          validationSchema={props.validationSchema}
        >
          <Form>
            <DialogTitle id="alert-dialog-slide-title">{props.header}</DialogTitle>
            <DialogContent>
              <EZFields fields={props.fields} onChange={props.handleChange} />
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" color="secondary" onClick={props.onClose}>
                {'Close'}
              </Button>
              <Button variant="outlined" type="submit" color="primary">
                {'Submit'}
              </Button>
            </DialogActions>
          </Form>
        </Formik>}
    </Dialog>
  )
}
