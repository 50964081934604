
import React, { useEffect, useState } from 'react'
import API from 'util/Api'
import SimpleTable from 'components/ActionsTable/SimpleTable'
import ContainerHeader from 'components/ContainerHeader/index'
import { Formik,Form,connect } from 'formik'
import { Button,Box } from '@material-ui/core'
import MsgPopover from 'components/MsgPopover'
import {SwapHoriz} from '@material-ui/icons'
import FormField from 'components/FormField'
import SnackBar from 'components/SnackBar'
import * as Yup from "yup"
import CardBox from 'components/CardBox'
import _ from 'lodash'
import UpdatePass from './UpdatePass'

const SignupSchema = Yup.object().shape({
    location: Yup.object()
    .required('Location is Required'),
  });

const EZField = (props) =>{
    console.log('EZField',props)
    let field= props.field
    useEffect(()=>{
        props.refresh()
    },[props.formik.values.location])
    return(
        <div className="col-md-4 col-12" key={`field-${field.key}`}>
            <FormField field={{...field,...{name:field.key}}}/>
        </div>
    )
}
const EZElement = connect(EZField)
const VisitorsPass = (props) => {
    const [field,setField] = useState({
        "value": "",
        "title": "Location",
        "key": "location",
        "help": "",
        "type": "autocomplete",
        "size":2,
        "options":[]
      })
    const [visitorsPass,setVisitorsPass] = useState({
        attached:null,
        unattached:null
    })
    const [snackBar,setSnackBar] = useState({open:false,msg:'',type:'error'});
    const [updateDialog,setUpdateDialog] = useState({
        open:false,
        loading:false,
        errorMsg:'',
        message:'',
        formValues:{vehicleNo:'',passId:''}
    })
    const categorizePass = (data)=>{
        let activePasses = data.table.tbody
        let attachedPass = {
            'title':'Active Passes',
            'headers': ['Vehicle No','End Date','Type'],
            'tbody':[]
        }
        let unAttachedPass = {
            'title':'Unattached Passes',
            'headers': data.table.headers,
            'tbody':[]
        }
        _.forEach(activePasses,pass=>{
            if(pass.tag_attached==0){
                unAttachedPass.tbody.push(pass)
            }else{
                attachedPass.tbody.push(pass)
            }
        })

        console.log("getActivePasses",activePasses,attachedPass,unAttachedPass )
        setVisitorsPass({...visitorsPass,attached:attachedPass,unAttached:unAttachedPass})
    }
    const submitPass = (e,{setSubmitting}) => {
        console.log("save config",e)
        setSubmitting(true)
        API.get("getActivePasses?locId="+e.location.value)
        .then((res) => {
            categorizePass(res.data.data.passes)
        })
        .catch((err) => {
            console.log("err",err);
        })
    }
    useEffect(()=>{
        getLocations()
    },[])
    const getLocations = () =>{
        
        API.get("location")
        .then((res) => {
            console.log("locations", JSON.stringify(res.data.data))
            let options = []
            _.forEach(res.data.data,loc=>{
                options.push({value:loc.id,title:loc.name})
            })
            setField({...field,options:options})
        })
        .catch((err) => {
            console.log("err",err);
        })
    }
    const handleEdit =(e)=>{
        console.log('handleEdit',e)
        setUpdateDialog({...updateDialog,
            open:true,
            formValues:{vehicleNo:e.vehicle_no,passId:e.id}
        })
    }
    const handleClose = (e)=>{
        setUpdateDialog({
            ...updateDialog,
            open:false,
            loading:false,
            errorMsg:'',
            message:''
        })
    }
    const updateVehicle = (params) =>{
        
        API.post("updateFastagPass", JSON.stringify(params))
        .then((res)=> {
            setUpdateDialog({...updateDialog,message:res.data.data.msg,loading:false});
            categorizePass(res.data.data.passes)
            setTimeout(function(){
                handleClose()
            },3000);
        })
        .catch((err)=>{
            try{
                setUpdateDialog({...updateDialog,errorMessage:err.response.data.msg,loading:false});
                if(err.response.data.data){
                    categorizePass(err.response.data.data.passes)
                    setTimeout(function(){
                        handleClose()
                    },3000);
                }
                
            }catch{
                console.log("post config error ", err)
            }
        })
    }
    const resetData = () =>{
        setVisitorsPass({
            attached:null,
            unattached:null
        })
        setUpdateDialog({
            open:false,
            loading:false,
            errorMsg:'',
            message:'',
            formValues:{vehicleNo:'',passId:''}
        })
    }
    console.log("VisitorsPass-fields",props)
    return(
        <>
        <ContainerHeader title="Fastag Pass" match={props.match}/>
        {
        <Formik
            onSubmit={submitPass}
            initialValues={{location:''}}
            validationSchema={SignupSchema}
            enableReinitialize={true}
        >
            <>
            <CardBox styleName="col-12">
                <Form>
                <div className="row">
                    {
                        <EZElement field={field} refresh={resetData}/>
                    }
                </div>
                {/* BUTTONS */}
                <div className="col-lg-6 col-sm-12 col-12">                    
                    <Box mt="20px">
                        <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" type="submit" > Submit </Button></Box>
                    </Box>
                </div>
                </Form>
            </CardBox>
            {visitorsPass.attached &&
            <div className="row">
                <CardBox styleName="col-12" headerOutside heading={
                <React.Fragment>
                {visitorsPass.attached.title}
                    <MsgPopover msg='Big table, scroll horizontally' timeout='1500'>
                    <Box display={{xs:'inline',md:'none'}}><SwapHoriz color="primary" /></Box></MsgPopover>
                </React.Fragment>}>                        
                        <SimpleTable 
                            type={'visitorSpasses'} 
                            table={visitorsPass.attached} 
                            onEdit = {handleEdit} 
                        />
                </CardBox>
            </div>
            }
            {visitorsPass.unAttached &&
            <div className="row">
                <CardBox styleName="col-12" headerOutside heading={
                <React.Fragment>
                {visitorsPass.unAttached.title}
                    <MsgPopover msg='Big table, scroll horizontally' timeout='1500'>
                    <Box display={{xs:'inline',md:'none'}}><SwapHoriz color="primary" /></Box></MsgPopover>
                </React.Fragment>}>                        
                        <SimpleTable 
                            type={'visitorSpasses'} 
                            table={visitorsPass.unAttached} 
                            onEdit = {handleEdit} 
                        />
                </CardBox>
            </div>
            }
            <SnackBar 
                open={snackBar.open} 
                handleClose={()=>{setSnackBar({open:false,msg:""})}}
                message={snackBar.msg}
                type={snackBar.type}
            />
            </>
        </Formik>
        }
        <UpdatePass 
            handleClose={handleClose}
            {
                ...updateDialog
            }  
            updateApi={updateVehicle} 
            dialogTitle={'Update Vehicle'}
            /> 
        </>
    )
}

export default VisitorsPass;