import React, { useEffect, useState } from "react";
import API from "util/Api";
import { Formik } from "formik";
import SnackBar from "components/SnackBar";
import { Form, connect } from "formik";
import { Button, Box } from "@material-ui/core";
import FormField from "components/FormField";
import ContainerHeader from "components/ContainerHeader";
import _ from "lodash";
import CardBox from "components/CardBox";
import * as Yup from "yup";
import ReportTable from "components/ReportTable/index";

const MaintenanceForm = (props) => {
    return (
        <div className="row">
            <CardBox styleName="col-12">
                <Form>
                    {/* FIRST ROW */}
                    <div className="row">
                        {Object.values(props.fields).map((field) => (
                            field && <div className="col-md-3 col-12" key={`field-${field.key}`}>
                                <FormField field={{ ...field, ...{ name: field.key } }} />
                            </div>
                        ))}
                    </div>
                    {/* BUTTONS */}
                    <div className="row mt-2">
                        <div className="col-lg-6 col-sm-12 col-12">
                            <Box mt="20px">
                                <Box mr="10px" display="inline">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        mr="5px"
                                        type="submit"
                                    >
                                        {` Submit `}
                                    </Button>
                                </Box>
                                <Box mr="10px" display="inline">
                                    <Button variant="outlined" color="secondary" type="reset">
                                        Reset
                                    </Button>
                                </Box>
                            </Box>
                        </div>
                    </div>
                </Form>
            </CardBox>
        </div>
    );
}

const ParkingMaintenance = (props) => {
    const [config, setConfig] = useState(null);
    const [maintenanceList, setMaintenanceList] = useState();
    const [snackBar, setSnackBar] = useState({
        open: false,
        message: "",
        type: "error",
    });
    const validations = Yup.object().shape({
        location_id: Yup.object().required("Required"),
        pallet_no: Yup.number().min(1, "Invalid pallet no").max(100, "Invalid pallet no").required("Required"),
        parking_type: Yup.string().required("Required"),
    });

    const getMaintenanceList = () => {
        API.get("parkingMaintenance")
            .then((res) => {
                setConfig(res.data.data.config);
                setMaintenanceList(res.data.data.maintenanceList)
            })
            .catch((err) => {
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong",
                    type: "error",
                });
            });
    }

    const handleSubmit = (data, form) => {
        API.post("parkingMaintenance", JSON.stringify(data))
            .then((res) => {
                form.setSubmitting(false);
                form.resetForm();
                getMaintenanceList();
                setSnackBar({
                    open: true,
                    message: res.data.msg,
                    type: "success",
                });
            })
            .catch((err) => {
                console.log("error", err);
                form.setSubmitting(false);
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong",
                    type: "error",
                });
            });
    };

    const handleDelete = (data) => {
        API.put("parkingMaintenance", JSON.stringify(data))
            .then((res) => {
                getMaintenanceList();
                setSnackBar({
                    open: true,
                    message: res.data.msg,
                    type: "success",
                });
            })
            .catch((err) => {
                console.log("error", err);
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong",
                    type: "error",
                });
            });
    }

    useEffect(() => {
        getMaintenanceList();
    }, []);

    return (
        <>
            {config && <ContainerHeader
                title={config.title}
                match={props.match}
            />}
            {config && (
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={config.values}
                    validationSchema={validations}
                >
                    <MaintenanceForm fields={config.fields} />
                </Formik>
            )}

            {maintenanceList && (
                <ReportTable
                    data={maintenanceList}
                    deleteMaintenance={handleDelete}
                />
            )}

            <SnackBar
                {...snackBar}
                handleClose={() => {
                    setSnackBar({ open: false, message: "" });
                }}
            />
        </>
    );
};
export default ParkingMaintenance;