
import React, { useEffect, useState } from 'react'
import NfcAdminDetails from './NfcAdminDetails.js'
import API from 'util/Api'
import ActionsTable from 'components/ActionsTable/TableList'
import { Formik } from 'formik'
import CircularProgress from 'components/Loader';
import SnackBar from 'components/SnackBar';
import PopUpDialog from 'components/PopUpDialog';
import _ from 'lodash';
import * as Yup from "yup";

const SignupSchema =()=>{
    return Yup.lazy(values=>{
        console.log("SignupSchema",values)
        return Yup.object().shape({
            fname: Yup.string()
                .min(2, 'Too Short!')
                .required('Required'),
            lname: Yup.string()
                .min(2, 'Too Short!')
                .required('Required'),
            location: Yup.string()
                .required('Required'),
            type: Yup.string()
                .required('Required'),
            email: Yup.string()
                .email('Invalid email')
                .required('Required'),
            password:('user_id' in values && values.password === undefined)?
                Yup.string()
                .notRequired(): Yup.string()
                .required('Password is required'),
            confirm_password:('user_id' in values && values.password === undefined)?Yup.string()
                .oneOf([Yup.ref('password')],"Passwords do not match"):
                 Yup.string()
                .oneOf([Yup.ref('password')],"Passwords do not match")
                .required('Confirm password is required')
        });
    });

}

const NfcAdmin = (props) => {

    const [config,setConfig] = useState(null)
    const [values,setValues] = useState(null)
    const [nfcAdmin,setNfcAdmin] = useState(null)
    const [nfcAdminList,setNfcAdminList] = useState(null);
    const [snackBar,setSnackBar] = useState({open:false,msg:'',type:'error'});
    const [dialog,setDialog] = useState({open:false,content:'',header:'',agreeBtnTxt:'',disAgreeBtnTxt:'',reset:null});
    const [refresh,setRefresh] = useState(false);
    
const saveNfcAdmin = (e,{setSubmitting}) => {
    setSubmitting(true)

        API.post("nfcAdmin",JSON.stringify(e)).then((res) => {
            console.log("saveTag", res.data);
            setSnackBar({open:true,msg:res.data.msg,type:'success'});
            setNfcAdminList(res.data.data);
            setSubmitting(false)
            setRefresh(true);
        }).catch((err) => {
            try{
                setSnackBar({open:true,msg:err.response.data.msg,type:'error'});
            }catch{
                setSnackBar({open:true,msg:"Something went wrong",type:'error'});
            }
            // got an error
             setSubmitting(false)

            console.log("configuration api error",err.response)
        })
}

    const resetNfcAdmin = () => {
        // todo: do we need to do anything here?
        setNfcAdmin(null)
        console.log("reset form ")        
    }
    useEffect(()=>{
        getNfcAdmin();
    },[])
    
    const getNfcAdmin = () => {
        API.get("nfcAdmin")
        .then((res) => {
            console.log("nfcadmin", JSON.stringify(res.data.data))
            setConfig(Object.values(res.data.data.config))
            setNfcAdminList(res.data.data.nfc);
            let data = {}
            for(let key in res.data.data.config){
                data[key]  = res.data.data.config[key].value
            }
            console.log('values',data);
            setValues(data)
        })
        .catch((err) => {
            console.log("err",err);
        })
    }
    const handleNfcAdminEdit = (clickedUser) => {
        console.log("handleNfcAdminEdit ",clickedUser)
        setNfcAdmin(clickedUser)
         // scroll to top 
         document.body.style.height = 'auto'            
         setTimeout(function(){
             document.body.style.height = '100%'
     },50)
    }
    const resetMobile = (e) => {
        
        //reset_mobile
        var user_id = _.find(Object.values(dialog.reset),{key:"user_id"}).value;
        API.get("reset_mobile/"+user_id)
        .then((res) => {
            setSnackBar({open:true,msg:res.data.msg,type:'success'});
        })
        .catch((err) => {
            try{
                setSnackBar({open:true,msg:err.response.data.msg,type:'error'});
            }catch{
                setSnackBar({open:true,msg:"Something went wrong",type:'error'});
            }
            console.log("err",err);
        })
        handleDialogClose();
    }
    const handleDialogClose = ()=>{
        setDialog({
            open:false,
            content:'',
            header:'',
            agreeBtnTxt:'',
            disAgreeBtnTxt:'',
            reset:null});
    }
    return(
        <>
        { config && values &&
        <Formik
            onSubmit={saveNfcAdmin}
            onReset={resetNfcAdmin}
            initialValues={values}
            validationSchema={SignupSchema}
        >
            <>
            <NfcAdminDetails
                config={config}
                nfcAdmin={nfcAdmin}
                refresh={refresh}
                resetRefresh={()=>{setRefresh(false)}}
            />        
            {nfcAdminList &&
            <div className="row">
                <ActionsTable 
                    tableHeading = "Nfc Admin" 
                    table={nfcAdminList} 
                    onEdit = {handleNfcAdminEdit}
                    actions={true} 
                    resetMobile = {(e)=>{
                        setDialog({
                            open:true,
                            header:"Reset Login",
                            content:'Are you sure you want to reset the device for this login ?',
                            agreeBtnTxt:'Yes',
                            disAgreeBtnTxt:'No',
                            reset:e
                        })
                    }} 
                />
            </div>
            }
            </>
        </Formik>       
        }
        {
            !config && !values && <CircularProgress/>
        }
        <SnackBar 
            open={snackBar.open} 
            handleClose={()=>{setSnackBar({open:false,msg:""})}}
            message={snackBar.msg}
            type={snackBar.type}
        />
        <PopUpDialog 
            open={dialog.open}
            header={dialog.header}
            content ={dialog.content}
            agreeBtnTxt ={dialog.agreeBtnTxt}
            disAgreeBtnTxt={dialog.disAgreeBtnTxt}
            agreeClicked ={resetMobile}
            handleDialogClose={handleDialogClose}
        />        
        </>
    )
}

export default NfcAdmin