import React, { useEffect, useState } from "react";
import API from "util/Api";
import ActionsTable from "components/ActionsTable/TableList";
import { connect, Formik,Form } from "formik";
import SnackBar from "components/SnackBar";
import * as Yup from "yup";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import CardBox from "components/CardBox";
import { Card, CardHeader, Box, Grid, Button } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import FormField from "components/FormField";
import _ from "lodash";

const ChargerConfig=connect((props)=>{
    console.log('charger-config-props',props)
    useEffect(() => {
      if (props.charger) {
        let data = {};
        for (let key in props.charger) {
          data[key] = props.charger[key].value;
        }
        if (!_.isEqual(data, props.formik.values)) {
          props.formik.setValues(data);
        }
      }
      if (props.charger === null) {
        props.formik.resetForm();
      }
    }, [props.charger]);
    
    return (
      <CardBox styleName="col-12" heading={props.config.title}>
        <Form>
          {/* FIRST ROW */}
          <div className="row">
            {props.config.fields.map((field) => (
              <div className="col-md-3 col-12" key={`field-${field.key}`}>
                <FormField field={{ ...field, ...{ name: field.key } }} />
              </div>
            ))}
          </div>
          {/* BUTTONS */}
          <div className="row mt-2">
            <div className="col-lg-6 col-sm-12 col-12">
              <Box mt="20px">
                <Box mr="10px" display="inline">
                  <Button
                    variant="contained"
                    color="primary"
                    mr="5px"
                    type="submit"
                  >
                    {" "}
                    Submit{" "}
                  </Button>
                </Box>
                <Box mr="10px" display="inline">
                  <Button variant="outlined" color="secondary" type="reset">
                    Reset
                  </Button>
                </Box>
              </Box>
            </div>
          </div>
        </Form>
      </CardBox>
    );
})

const OCPPChargers = (props) => {
  const [config, setConfig] = useState(null);
  const [charger, setCharger] = useState(null);
  const [chargers, setChargers] = useState(null);
  const [snackBar, setSnackBar] = useState({
    open: false,
    msg: "",
    type: "error",
  });
  const validations = Yup.object().shape({
    name: Yup.string().required("Required"),
  });

  const handleSubmit = (data, form) => {
    API.post("ocppChargers", JSON.stringify(data))
      .then((res) => {
        setChargers(res.data.data.chargers);
        setSnackBar({ open: true, msg: res.data.msg, type: "success" });
        form.setSubmitting(false);
        form.resetForm();
      })
      .catch((err) => {
        console.log("error", err);
        form.setSubmitting(false);
        setSnackBar({
          open: true,
          msg: err?.response?.data?.msg
            ? err.response.data.msg
            : "Something went wrong",
          type: "error",
        });
      });
  };

  const handleEdit = (clicked) => {
    setCharger(clicked);
    // scroll to top
    document.body.style.height = "auto";
    setTimeout(function () {
      document.body.style.height = "100%";
    }, 50);
  };

  const updateStatus = (id, status, cb) => {
    API.put("ocppChargerStatus", JSON.stringify({ id: id, active: status }))
      .then((res) => {
        cb(status);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    API.get("ocppChargers")
      .then((res) => {
        setConfig(res.data.data.config);
        setChargers(res.data.data.chargers);
      })
      .catch((err) => {});
  }, []);

  return (
    <>
      {config && (
        <Formik
          onSubmit={handleSubmit}
          initialValues={{ name: "" }}
          validationSchema={validations}
        >
          <>
            <ChargerConfig config={config} charger={charger} />
            {chargers && (
              <ActionsTable
                tableHeading="OCPP Chargers"
                table={chargers}
                onEdit={handleEdit}
                updateStatus={updateStatus}
                actions={true}
              />
            )}
          </>
        </Formik>
      )}
      <SnackBar
        open={snackBar.open}
        handleClose={() => {
          setSnackBar({ open: false, msg: "" });
        }}
        message={snackBar.msg}
        type={snackBar.type}
      />
    </>
  );
};
export default OCPPChargers;
