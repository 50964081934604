import { Grid,Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core"
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import "../ParkingOverview/overview.css"
import { BarChartt, ChartSales, EZDoughnut, EZDoughnutSolid, HorizontalBarChart, StackedBarChart } from "components/Chart"; 
import CardBox from "components/CardBox";
import rupee from 'assets/images/rupee-sign.png'
import exit from 'assets/images/exit.png'
import entry from 'assets/images/entry.png'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import liveDevice from 'assets/images/live-devices.png'
import BeenhereIcon from '@material-ui/icons/Beenhere';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CallReceivedIcon from '@material-ui/icons/CallReceived';

import { useEffect, useState } from "react";
const useStyles = makeStyles( (theme) =>(
    {
        labelText:{
            // margin: 'auto',
            marginLeft: 0,
            padding: 12,
            lineHeight:1.2,
            fontSize:20
        },
        text:{
            // margin: 'auto',
            color: '#f9a825'
        },
        aurbisText:{
            color: '#000',
        }
    }
))
const summary = [
    {
        key : "entry",
        title : "Total Entry",
        totalCount : 2000,
        data : [
            {type:"bike",count:1000},
            {type:"car",count:100},
            {type:"lcv",count:100},
            {type:"hcv",count:50},
        ]
    },
    {
        key : "exit",
        title : "Total Exit",
        totalCount : 2000,
        data : [
            {type:"bike",count:1000},
            {type:"car",count:100},
            {type:"lcv",count:100},
            {type:"hcv",count:50},
        ]
    },
    {
        key : "active",
        title : "Total Active",
        totalCount : 2000,
        data : [
            {type:"bike",count:1000},
            {type:"car",count:100},
            {type:"lcv",count:100},
            {type:"hcv",count:50},
        ]
    }
]

const paymentMode = [
    {
        title:"Bike",
        key:"bike", 
        data:{
            labels: [
              'UPI',
              'CASH'
            ],
            datasets: [{
              label: '',
              data: [50, 200],
              backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)'
              ],
              hoverOffset: 4
            }]
        }
    },
    {
        title:"Car",
        key:"car", 
        data:{
            labels: [
              'UPI',
              'CASH'
            ],
            datasets: [{
              label: '',
              data: [50, 200],
              backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)'
              ],
              hoverOffset: 4
            }]
        }
    },
    {
        title:"Truck",
        key:"truck", 
        data:{
            labels: [
              'UPI',
              'CASH'
            ],
            datasets: [{
              label: '',
              data: [50, 200],
              backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)'
              ],
              hoverOffset: 4
            }]
        }
    }
]

const parkingAvailibity=[
    {
        client_name:"Doninos",
        avalibility:[
            {
                title:"Bike",
                key:"bike", 
                totleSlot:"100",
                data:{
                    labels: [
                      'Occupied',
                      'Available'
                    ],
                    datasets: [{
                      label: '',
                      data: [50, 200],
                      backgroundColor: [
                        'rgb(255, 99, 132)',
                        'rgb(54, 162, 235)'
                      ],
                      hoverOffset: 4
                    }]
                }
            },
            {
                title:"Car",
                totleSlot:"100",
                key:"car", 
                data:{
                    labels: [
                      'Occupied',
                      'Available'
                    ],
                    datasets: [{
                      label: '',
                      data: [50, 200],
                      backgroundColor: [
                        'rgb(255, 99, 132)',
                        'rgb(54, 162, 235)'
                      ],
                      hoverOffset: 4
                    }]
                }
            }
        ]
    },
    {
        client_name:"Trends",
        avalibility:[
            {
                title:"Bike",
                key:"bike", 
                totleSlot:"100",
                data:{
                    labels: [
                      'Occupied',
                      'Available'
                    ],
                    datasets: [{
                      label: '',
                      data: [50, 200],
                      backgroundColor: [
                        'rgb(255, 99, 132)',
                        'rgb(54, 162, 235)'
                      ],
                      hoverOffset: 4
                    }]
                }
            },
            {
                title:"Car",
                key:"car", 
                totleSlot:"100",
                data:{
                    labels: [
                      'Occupied',
                      'Available'
                    ],
                    datasets: [{
                      label: '',
                      data: [50, 200],
                      backgroundColor: [
                        'rgb(255, 99, 132)',
                        'rgb(54, 162, 235)'
                      ],
                      hoverOffset: 4
                    }]
                }
            }
        ]
    }
] 
const ParkingAvailibity = (props)=>{
    
const formattedData = (data)=> { 
    return data.map((item) => {
        const availabilityData = item.avalibility.reduce((acc, vehicle) => {
          const [occupied, available] = vehicle.data.datasets[0].data;
          acc[`${vehicle.key}Total`] = parseInt(vehicle.totalSlots, 10);
          acc[`${vehicle.key}Available`] = available;
          acc[`${vehicle.key}Occupied`] = occupied;
          acc[`vehicleType`] = vehicle.title; // Add vehicle type

          return acc;
        }, {});
    
        return {
          clientname: item.name,
          ...availabilityData
        };
      });
}

    return(
        <CardBox  styleName="col-12">
            <Grid container className="align-items-center mb-3" direction='row' >
                <Typography  variant={'h6'} style={{fontSize:'16px',marginBottom:15}}>Parking Availability</Typography>
                <StackedBarChart data={formattedData(props.clientSlots)} />
            </Grid>
        </CardBox>
    )
}
const PaymentModeCard =(props)=>{
    console.log("PaymentModeCard-",props.paymentMode.paymentMode[0].data,'aaa')

    const [selectedCarState,setSelectedCarState] = useState('hourly');
    const [selectedBikeState,setSelectedBikeState] = useState('hourly');
    const [availableStates,setAvailableStates] = useState(['daily','hourly']);
    const [utilizationBikeData,setUtilizationBikeData] = useState(undefined);
    const [lengthOfStayBikeData,setLengthOfStayBikeData] = useState(undefined);
    const [lengthOfStayCarData,setLengthOfStayCarData] = useState(undefined);
    const [utilizationCarData,setUtilizationCarData] = useState(undefined);
    const [hasRevenue,sethasRevenue] = useState();
    const labels = props.paymentMode?.paymentMode?.[0]?.data.labels;
    const data = props.paymentMode?.paymentMode?.[0]?.data.datasets[0].data;

    const bikeIndex = labels?.indexOf('bike');
    const bikeData = bikeIndex !== -1 ? data[bikeIndex] : null;
    const carIndex = labels?.indexOf('car');
    const carData = bikeIndex !== -1 ? data[carIndex] : null;
    const handleChange = (event) => {
        const { name, value } = event.target;
        if(name == 'bike')
            setSelectedBikeState(value);
        else
            setSelectedCarState(value);
    }
    const colourPalette = [
        '#bdade4', // Light Yellow
        '#9ee9d1', // Soft Yellow
        '#f9c880', // Golden Yellow
        '#f399a3', // Dark Yellow
        '#ebdc78', // Amber Yellow
        '#FFCA28', // Mustard Yellow
        '#FFD54F', // Light Gold
        '#FF6F00', // Dark Gold
      ];
    useEffect(()=>{
        // console.log(props.utilizationSata,selectedBikeState,selectedCarState,'stateee')
        setutilizationData()
        setLengthOfStayData()
        sethasRevenue(checkPaymentMode(props.paymentMode?.paymentMode[0].data?.datasets[0]?.data))
    },[selectedCarState,selectedBikeState,props.utilizationSata])
    
    const setutilizationData = ()=>{
        let UtBikeData = props.utilizationSata?.[selectedBikeState]?.data;
        let UtCarData = props.utilizationSata?.[selectedCarState]?.data;
        const bikeDataArray = UtBikeData.map(item => ({
            type: "bike",
            count: item.bike ? item.bike : 0,
            name: item.name,
            name_sort: item.name_sort
          }));
          
          const carDataArray = UtCarData.map(item => ({
            type: "car",
            count: item.car ? item.car : 0,
            name: item.name,
            name_sort: item.name_sort
          }));
          console.log(bikeDataArray,'bikedata')
        setUtilizationBikeData(bikeDataArray)
        setUtilizationCarData(carDataArray)
    }
    const setLengthOfStayData = ()=> {
        let LSBikeData = props.lengthOfStay?.data;
        const bikeDataArray = LSBikeData.map(item => ({
            type: "bike",
            count: item.bike ? item.bike : 0,
            name: item.hour,
            color: props.lengthOfStay?.legends.filter(vehicle => vehicle.name === 'bike')[0]?.color
          }));
          const carDataArray = LSBikeData.map(item => ({
            type: "car",
            count: item.car ? item.bike : 0,
            name: item.hour,
            color: props.lengthOfStay?.legends.filter(vehicle => vehicle.name === 'car')[0]?.color
          }));
        setLengthOfStayBikeData(bikeDataArray)
        setLengthOfStayCarData(carDataArray)

    }
    const checkPaymentMode = (data) => {
        console.log(data,'checkPaymentMode')
        if (data.length === 0)
            return false
        return data.some(num => num > 0);
    };
    const makePieChartData = (data)=>{
        let finalData = []
        data?.labels?.map((label,index)=>{
            let tempData = {
                data  : parseInt(data.datasets[0].data[index]),
                label : label,
                color : data.datasets[0].backgroundColor[index]
            }
            finalData.push(tempData)
          })
          return finalData
    }
    return(
        <>
            {/* <Typography variant={'h5'}>Payment Mode</Typography> */}
            {/* <CardBox styleName="col-12 mt-5" style={{padding:0,margin:0,background:'black'}}>
                <Grid container  direction="row" spacing={4} style={{padding:0,margin:0,background:'red'}}>
                    {
                        props.paymentMode.paymentMode?.map(item=>{
                            const total = item.data.datasets[0].data.reduce((acc, value) => Number(acc) + Number(value), 0);
                            return(
                                <Grid item xs={12} sm={6} md={4} >
                                    <div className="jr-card" style={{padding:10,display:'flex',height:'100%',width:'100%'}}>

                                        {
                                            item.title.toUpperCase() === 'REVENUE'  ? 
                                                <div style={{width:'100%'}}>
                                                    {
                                                        <>
                                                            <div class="d-flex align-items-center align-self-start mt-5">
                                                                {(item.title==='bike')?<MotorcycleIcon className="type-icon" />:(item.title==='car')?<DriveEtaIcon className="type-icon"/>:<></>}
                                                                <Typography variant="h5" style={{textAlign:'right',marginLeft:'10px',display:'flex',alignItems:'center'}} >
                                                                    {item.title.toUpperCase()} <div style={{color:'green',fontSize:28,marginLeft:'20px'}}>₹</div>
                                                                </Typography>
                                                            </div>
                                                            <div style={{display:'flex',height:'6rem'}}>
                                                                <BarChartt data={item.data} style={{padding:4,alignSelf:'center'}}/>
                                                            </div>
                                                            <div class="d-flex flex-direction-column ml-3">
                                                                {item.data.labels.map((label, i)=>(
                                                                    <div style={{display:'flex',marginRight:'10px'}}>
                                                                        <div style={{width: '12px', height: '12px', backgroundColor: item.data.datasets[0].backgroundColor[i], alignSelf:'center', fontSize:'1rem', marginRight:'5px'}}></div>
                                                                        {label.charAt(0).toUpperCase() +label.slice(1) }
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            :
                                                <Grid container direction="row" >
                                                    <Grid item xs={8}>
                                                        <EZDoughnut vehicleType={item.title}  data={item.data} style={{padding:10}}/>                                                
                                                    </Grid>
                                                    <Grid item xs={4} style={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                                                        <div class="d-flex align-items-center align-self-end">
                                                            {(item.title==='bike')?<MotorcycleIcon className="type-icon" />:(item.title==='car')?<DriveEtaIcon className="type-icon"/>:<></>}
                                                            <Typography variant="h5" style={{textAlign:'right',marginLeft:'10px'}} >
                                                                {item.title.toUpperCase()}
                                                            </Typography>
                                                        </div>
                                                        <div>
                                                            {item.data.labels.map((label, i)=>(
                                                                <div style={{display:'flex'}}>
                                                                    <div style={{width: '12px', height: '12px', backgroundColor: item.data.datasets[0].backgroundColor[i], alignSelf:'center', fontSize:'1rem', marginRight:'5px'}}></div>
                                                                    {label}-{item.data.datasets[0].data[i]}
                                                                </div>
                                                            ))}
                                                            <div style={{display:'flex'}}>
                                                                <div style={{width: '12px', height: '12px', backgroundColor:'#999999', alignSelf:'center', fontSize:'1rem', marginRight:'5px'}}></div>
                                                                {'Total'}-{total}
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                        }
                                    </div> 
                                </Grid>
                            )
                        })
                    }
                    
                </Grid>
            </CardBox> */}
            <Grid container className=" col-12"   >
                <Grid item  className="jr-card  col-lg-12  col-md-12 p-0 mt-5  " style={{borderRadius:10}}  >
                    {/* BIKE PAYMENT MODE */}
                    <Grid container  direction="column" className="paymentModeContainer">
                        <Grid container className="paymentModeHeader" >
                            <Grid item style={{fontSize:'1.5rem'}} className="d-flex align-items-center">
                                <MotorcycleIcon className="icons" /> Bikes 
                            </Grid>
                            <Grid container direction="row" className="paymentModeHeaderContent mt-1 col-12" spacing={2}>
                                <Grid item xs={12} sm={6} md={2}>
                                <div className="d-flex flex-column align-items-end" style={{maxWidth: 'max-content'}}>
                                    <div className="d-flex align-items-center"><CallMadeIcon className="headericons"/><span style={{fontSize:'1.5rem'}}>{new Intl.NumberFormat('en-IN').format(props.paymentMode?.summary[0]?.data?.find(vehicle => vehicle.type === 'bike')?.count ?? 0)}</span></div>
                                    <div> ENTRY</div>
                                </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={2}>
                                <div className="d-flex flex-column align-items-end" style={{maxWidth: 'max-content'}}>
                                    <div className="d-flex align-items-center"><CallReceivedIcon className="headericons"/><span style={{fontSize:'1.5rem'}}>{new Intl.NumberFormat('en-IN').format(props.paymentMode?.summary[1]?.data?.find(vehicle => vehicle.type === 'bike')?.count ?? 0)}</span></div>
                                    <div> EXIT</div>
                                </div>
                                </Grid>
                                
                                <Grid item xs={12} sm={6} md={2}>
                                    <div className="d-flex align-items-end flex-column" style={{maxWidth: 'max-content'}}>
                                        <div className="d-flex align-items-center"><BeenhereIcon  className="headericons" /><span style={{fontSize:'1.5rem'}}>{new Intl.NumberFormat('en-IN').format(props.paymentMode?.summary[2]?.data?.find(vehicle => vehicle.type === 'bike')?.count ?? 0)}</span></div>
                                        <div> ACTIVE</div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} >
                                <div className="d-flex align-items-end flex-column" style={{maxWidth: 'max-content'}}>
                                    <div className="d-flex align-items-center"><div className="rupeeIcon">₹</div><div  style={{padding:'10px 0px 6px 0px',fontSize:'1.5rem'}}>{new Intl.NumberFormat('en-IN').format(bikeData)}</div></div> 
                                    <div > REVENUE</div>
                                </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className="paymentBody col-12 p-0">
                            <Grid item direction='column' style={{borderRight:'2px solid rgb(222, 226, 230)',padding:'0 0 10px 0'}} className="col-lg-6 col-md-12 p-2 d-flex align-items-center mb-4">
                                <Grid container className="align-items-center mb-3" direction='row' >
                                <Typography  variant={'h6'} style={{fontSize:'16px'}}>Utilization</Typography>
                                <FormControl  FormControl className="col-md-2  stateSelection " style={{ marginLeft: "auto" }}>
                                    <InputLabel >PERIOD</InputLabel>
                                    <Select
                                        name="bike"
                                        value={selectedBikeState}
                                        onChange={handleChange}
                                        input={<Input id="ageSimple1" />}
                                    >
                                        {availableStates.map(period => (
                                            <MenuItem value={period}>
                                                {period}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                </Grid>
                                {
                                    utilizationBikeData && 
                                    // <div className="d-flex align-self-center">
                                        
                                        <ChartSales data={utilizationBikeData} height={hasRevenue? 250 :400}/>
                                    // </div>
                                }
                                
                            </Grid>
                            {
                                hasRevenue ?
                                <Grid container direction="column"  className="col-xl-6 col-lg-6 col-md-12 pb-3 d-flex align-items-center justify-content-center">
                                
                                    <>
                                        <Typography  variant={'h6'} style={{fontSize:'16px',alignSelf:'flex-start',marginTop:18}}>Payment Modes</Typography>
                                        <Grid > 
                                            <EZDoughnutSolid type='bike' data={makePieChartData(props.paymentMode?.paymentMode?.find(vehicle => vehicle.title === 'bike')?.data)}/>  
                                        </Grid>
                                        <Grid item style={{ width: '100%',marginTop:10 }}>
                                            <div className="d-flex flex-wrap justify-content-center w-100">
                                                {props.paymentMode?.paymentMode?.find(vehicle => vehicle.title === 'bike')?.data.labels.map((label, i) => (
                                                <div key={i} className="d-flex align-items-center" style={{ margin: '0 6px 8px 0' }}>
                                                    <div style={{ width: '12px', height: '12px', backgroundColor: colourPalette[i], marginRight: '5px', borderRadius: '50%' }} />
                                                    <span>{label.toUpperCase()} - {props.paymentMode?.paymentMode?.find(vehicle => vehicle.title === 'bike')?.data.datasets[0].data[i]}</span>
                                                </div>
                                                ))}

                                                <div className="d-flex align-items-center" style={{ margin: '0 6px 8px 0' }}>
                                                <div style={{ width: '12px', height: '12px', backgroundColor: '#999999', marginRight: '5px', borderRadius: '50%' }} />
                                                <span>Total - {props.paymentMode?.paymentMode?.find(vehicle => vehicle.title === 'bike')?.data.datasets[0].data.reduce((acc, val) => acc + parseInt(val), 0)}</span>
                                                </div>
                                            </div>
                                        </Grid>
                                    </>
                                    </Grid>
                                    :
                                    <Grid item direction='column' style={{padding:'0 0 10px 0'}} className="col-lg-6 col-md-12 p-2 d-flex align-items-center justify-content-center">
                                        <Typography  variant={'h6'} style={{fontSize:'16px',alignSelf:'flex-start',marginTop:18,marginBottom:10}}>Length of Stay</Typography>

                                        <HorizontalBarChart data={lengthOfStayBikeData}/>
                                    </Grid>
                                }     
                                {/* </Grid>  */}
                                {/* // <BarChartt data={props.paymentMode?.paymentMode?.find(vehicle => vehicle.title === 'bike')?.data} style={{padding:4,alignSelf:'center'}}/> */}
                        </Grid>
                        
                    </Grid>
                </Grid>
                <Grid item className="jr-card  col-lg-12  col-md-12 p-0 mt-5 mb-4" >
                    <Grid container  direction="column" className="paymentModeContainer">
                        <Grid container className="paymentModeHeader" >
                            <Grid item style={{fontSize:'1.5rem'}} className="d-flex align-items-center">
                                  <DriveEtaIcon className="icons"/>  Cars
                            </Grid>
                             <Grid container direction="row" className="paymentModeHeaderContent mt-1 col-12" spacing={2}>
                                <Grid item xs={12} sm={6} md={2}>
                                     <div className="d-flex align-items-end flex-column" style={{maxWidth: 'max-content'}}>
                                    <div className="d-flex align-items-center"><CallMadeIcon className="headericons"/><span style={{fontSize:'1.5rem'}}>{new Intl.NumberFormat('en-IN').format(props.paymentMode?.summary[0]?.data?.find(vehicle => vehicle.type === 'car')?.count ?? 0)}</span></div>
                                    <div >ENTRY</div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={2}>
                                 <div className="d-flex align-items-end flex-column" style={{maxWidth: 'max-content'}}>
                                    <div className="d-flex align-items-center"><CallReceivedIcon className="headericons"/> <span style={{fontSize:'1.5rem'}}>{new Intl.NumberFormat('en-IN').format(props.paymentMode?.summary[1]?.data?.find(vehicle => vehicle.type === 'car')?.count ?? 0)}</span></div>
                                    <div>EXIT</div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={2}>
                                 <div className="d-flex align-items-end flex-column" style={{maxWidth: 'max-content'}}>
                                        <div className="d-flex align-items-center"><BeenhereIcon  className="headericons" /><span style={{fontSize:'1.5rem'}}>{new Intl.NumberFormat('en-IN').format(props.paymentMode?.summary[2]?.data?.find(vehicle => vehicle.type === 'car')?.count ?? 0)}</span></div>
                                        <div>ACTIVE</div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <div className="d-flex align-items-end flex-column" style={{maxWidth: 'max-content'}}>
                                        <div className="d-flex align-items-center"><div className="rupeeIcon">₹</div><div style={{padding:'10px 0px 6px 0px',fontSize:'1.5rem'}}>{new Intl.NumberFormat('en-IN').format(carData)}</div></div> 
                                        <div>REVENUE</div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className="paymentBody col-12 ">
                            
                            <Grid item direction='column' style={{borderRight:'2px solid rgb(222, 226, 230)',padding:'0 0 10px 0'}} className="col-xl-6  col-lg-6 col-md-12  d-flex align-items-center mb-4">
                                <Grid container className="align-items-center mb-3" direction='row' >
                                <Typography  variant={'h6'} style={{fontSize:'16px'}}>Utilization </Typography>
                                <FormControl   FormControl className="col-md-3 mt-3 stateSelection" style={{ marginLeft: "auto" }}>
                                    <InputLabel >PERIOD</InputLabel>
                                    <Select
                                        name="car"
                                        value={selectedCarState}
                                        onChange={handleChange}
                                        input={<Input id="ageSimple1" />}
                                    >
                                        {availableStates.map(period => (
                                            <MenuItem value={period}>
                                                {period}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                </Grid>
                                {
                                        utilizationCarData &&
                                        <ChartSales data={utilizationCarData} height={hasRevenue? 250 :400}/>
                                }
                            </Grid>
                            {
                                hasRevenue ?
                            <Grid container direction="column"  className="col-xl-6 col-lg-6 col-md-12 pb-3 d-flex align-items-center justify-content-center">
                                <Typography  variant={'h6'} style={{fontSize:'16px',alignSelf:'flex-start',marginTop:18}}>Payment Modes</Typography>
                                <Grid >
                                    <EZDoughnutSolid type='car' data={makePieChartData(props.paymentMode?.paymentMode?.find(vehicle => vehicle.title === 'car')?.data)}/>  
                                    {/* <EZDoughnut type='car' data={props.paymentMode?.paymentMode?.find(vehicle => vehicle.title === 'car')?.data} style={{padding:20,maxHeight:250}}/>   */}
                                </Grid>
                                <Grid item style={{ width: '100%' }}>
                                    <div className="d-flex flex-wrap justify-content-center w-100">
                                        {props.paymentMode?.paymentMode?.find(vehicle => vehicle.title === 'car')?.data.labels.map((label, i) => (
                                        <div key={i} className="d-flex align-items-center" style={{ margin: '0 6px 8px 0' }}>
                                            <div style={{ width: '12px', height: '12px', backgroundColor: colourPalette[i], marginRight: '5px', borderRadius: '50%' }} />
                                            <span>{label.toUpperCase()} - {props.paymentMode?.paymentMode?.find(vehicle => vehicle.title === 'car')?.data.datasets[0].data[i]}</span>
                                        </div>
                                        ))}
                                        
                                        <div className="d-flex align-items-center" style={{ margin: '0 6px 8px 0' }}>
                                        <div style={{ width: '12px', height: '12px', backgroundColor: '#999999', marginRight: '5px', borderRadius: '50%' }} />
                                        <span>Total - {props.paymentMode?.paymentMode?.find(vehicle => vehicle.title === 'car')?.data.datasets[0].data.reduce((acc, val) => acc + parseInt(val), 0)}</span>
                                        </div>
                                    </div>
                                    </Grid>
   
                                {/* </Grid>  */}
                                {/* // <BarChartt data={props.paymentMode?.paymentMode?.find(vehicle => vehicle.title === 'bike')?.data} style={{padding:4,alignSelf:'center'}}/> */}
                            </Grid>
                            :
                            <Grid item direction='column' style={{padding:'0 0 10px 0'}} className="col-lg-6 col-md-12 p-2 d-flex align-items-center justify-content-center">
                                <Typography  variant={'h6'} style={{fontSize:'16px',alignSelf:'flex-start',marginTop:18,marginBottom:10}}>Length of Stay</Typography>
                                <HorizontalBarChart data={lengthOfStayCarData}/>
                            </Grid>
                        }     
                        </Grid>
                        
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
const OverviewDetails = (props) =>{
    const classes = useStyles()
    console.log("OverviewDetails-",props.data)
    // console.log("OverviewDetails-",props.locationId)
    const isAurbis = props.data.theme === 'aurbis' ? false : false

    return (
        <>
            {/* <CardBox styleName="col-12" heading={" "}> */}
            {/* <Typography variant={'h5'}>Summary</Typography> */}
                {/* <Grid container direction='row' spacing={4}> */}
                    {/* {
                        props.data?.summary?.map(item=>(
                        <Grid item   xs={12} sm={6} md={4} lg={4} key={`key-${item.key}`}>
                            <div className="jr-card" style={{padding:10,border:'2px solid lightgrey',boxShadow:'unset'}}> 
                                <Grid container direction='row' style={{marginBottom:10}} >
                                    <Typography variant={'h4'} className={classes.labelText} >{item.title} </Typography>
                                    <Typography variant={'h4'} className={`${isAurbis ? classes.aurbisText : classes.text}`} >{item.totalCount}</Typography>
                                </Grid>
                                <Grid container direction='row' >
                                    {
                                        item.data.map(value=>(
                                            <Grid item xs={6} key={`type-${value.type}`} className="vehicle-card">
                                                {(value.type=='bike')?<MotorcycleIcon className={`${isAurbis ? "type-aurbis-icon" : "type-icon"}`} />:(value.type=='car')?<DriveEtaIcon className={`${props.locationId == 2092 ? "type-aurbis-icon" : "type-icon"}`}/>:<LocalShippingIcon className={`${props.locationId == 2092 ? "type-aurbis-icon" : "type-icon"}`}/>}
                                                <Typography className="type-text" >{value.type.toUpperCase()} {value.count}</Typography>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </div> 
                        </Grid>
                        ))
                    } */}

                        <div className="row row justify-content-center col-12 mt-5">
                        {
                            props.data?.summary?.map((item,index)=>(
                            <div className="col-xl-3 col-lg-6 col-md-10 col-sm-10 ">
                                <div style={{borderRadius:"10px"}} className={`jr-card p-0 overflow-hidden card-entire-box${index+1}`}>
                                    <div className='card-designer'></div>
                                    <div className={`row m-0 card-body box${index+1}`}>
                                        <div className={`rounded-container-parent card-entire-box${index+1}`}>
                                            <div className='rounded'>
                                                    <img className='rounded-image' alt='' src={item.title === 'Total Active' ?  liveDevice : item.title === 'Total Entry' ?  entry :item.title === 'Total Exit' ?  exit :  rupee}/>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-flex-start justify-content-center flex-column w-50 ml-2'>
                                            <Typography variant='h6' className='card-header-title text-white'>{item.title.split(' ')[1]}</Typography>
                                            <Typography variant='h5' className='text-white' style={{fontSize:'1.8rem'}}>{item.totalCount}</Typography>
                                        </div>

                                    </div>
                                </div>
                            </div>   
                            ))
                        }
                        {/* TOTAL REVENUE */}
                        
                        <div className="col-xl-3 col-lg-6 col-md-10 col-sm-10">
                            <div style={{borderRadius:"10px"}} className={`jr-card p-0 overflow-hidden card-entire-box${3+1}`}>
                                <div className='card-designer'></div>
                                <div className={`row m-0 card-body box${3+1}`}>
                                    <div className={`rounded-container-parent card-entire-box${3+1}`}>
                                        <div className='rounded'>
                                                <img className='rounded-image' alt='' src={rupee}/>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-flex-start justify-content-center flex-column w-50 ml-2'>
                                        <Typography variant='h6' className='card-header-title text-white'>Revenue</Typography>
                                        <Typography variant='h5' className='text-white' style={{fontSize:'1.8rem'}}>{new Intl.NumberFormat('en-IN').format(props.data?.paymentMode[0].data?.datasets[0]?.data.reduce((acc, val) => acc + val, 0))}</Typography>
                                    </div>

                                </div>
                            </div>
                        </div>  
                        
                        </div>
                {/* </Grid> */}
            {/* </CardBox> */}
            
            {/* { checkPaymentMode(props.data?.paymentMode[0].data?.datasets[0]?.data) && <PaymentModeCard paymentMode={props.data} ></PaymentModeCard> } */}
             <PaymentModeCard paymentMode={props.data} utilizationSata={props.utilizationSata} lengthOfStay={props.lengthOfStay} ></PaymentModeCard> 
            {
                props.data?.clientSlots&&
                <>
                    {/* <div class="d-flex  w-100 mb-3">
                        <Typography variant={'h5'}  >Parking Availability</Typography>
                    </div> */}
                    <ParkingAvailibity clientSlots={props.data.clientSlots} ></ParkingAvailibity>
                </>
            }
        </>
    )
    
}

export default OverviewDetails