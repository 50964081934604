import React, { useEffect, useState } from "react";
import { Formik, Form, connect, FieldArray } from "formik";
import { Button, Box, LinearProgress, Typography } from "@material-ui/core";
import FormField from "components/FormField";
import CardBox from "components/CardBox";
import SnackBar from "components/SnackBar";
import _ from "lodash";
import ContainerHeader from "components/ContainerHeader";
import * as Yup from "yup";
import API from "../../../../../util/Api";
import TerminalBox from "components/TerminalBox";

const EZField = connect(({ field, formik }) => {
  console.log("ezfield", field, formik);
  return !field.dependency ||
    (field.dependency &&
      field.dependencyValue.includes(formik.values[field.dependency])) ? (
    <div className="col-md-3 col-12">
      <FormField field={{ ...field, ...{ name: field.key } }} />
    </div>
  ) : null;
});

const ConfigDetails = connect(({ config, setConfig, formik }) => {
  console.log("formik", formik);
  useEffect(() => {
    if (formik.values.location) {
      let con = config;
      con.fields.parking_guard.options = formik.values.location.value.parkingGuards;
      setConfig(con);
      formik.setFieldValue("parking_guard", con.fields.parking_guard.options.length > 0 ? con.fields.parking_guard.options[0].value : "");
      formik.setFieldValue("api", "");
      formik.setFieldValue("txn_id", "");
      formik.setFieldValue("tag_id", "");
    }
  }, [formik.values.location]);

  return (
    <div className="row">
      <CardBox styleName="col-12" heading="">
        <Form>
          <div className="row">
            {Object.values(config.fields).map((field, index) => (
              <EZField field={field} key={`field-${index}`} />
            ))}
          </div>
          {/* BUTTONS */}
          <div className="row mt-2">
            <div className="col-lg-6 col-sm-12 col-12">
              <Box mt="20px">
                <Box mr="10px" display="inline">
                  <Button
                    variant="contained"
                    color="primary"
                    mr="5px"
                    type="submit"
                  >
                    {" "}
                    Submit{" "}
                  </Button>
                </Box>
                <Box mr="10px" display="inline">
                  <Button variant="outlined" color="secondary" type="reset">
                    Reset
                  </Button>
                </Box>
              </Box>
            </div>
          </div>
        </Form>
      </CardBox>
    </div>
  );
});

const ProgressBar = connect(({ formik }) => {
  console.log("progress-bar-formik", formik);
  return formik.isSubmitting ? (
    <div className="col-12">
      <LinearProgress color="primary" />
      <LinearProgress color="primary" />
    </div>
  ) : (
    <></>
  );
});

const FastagTester = (props) => {
  const [config, setConfig] = useState();
  const [callbackLogs, setCallbackLogs] = useState();
  const [response, setResponse] = useState();
  const [locSelected, setLocSelected] = useState();
  const [snackBar, setSnackBar] = useState({
    open: false,
    msg: "",
    type: "error",
  });
  const validations = Yup.object().shape({
    location: Yup.object().required("Required"),
    // api: Yup.array().of(Yup.string().required("Required")),
    api: Yup.string().required("Required"),
  });
  const handleSubmit = (e, { setSubmitting }) => {
    setSubmitting(true);
    setLocSelected(e.location);
    API.post("fastagTester", JSON.stringify({ ...e }))
      .then((res) => {
        setSubmitting(false);
        setResponse(res.data.data);
        if (res.data.data.txn_id?.length) {
          getCallbackLogs({ txn_id: res.data.data.txn_id[0] });
        } else {
          setCallbackLogs(undefined);
        }
      })
      .catch((err) => {
        setSnackBar({
          open: true,
          msg: err?.response?.data?.msg
            ? err.response.data.msg
            : "Something went wrong",
          type: "error",
        });
        setSubmitting(false);
        console.log("catch", err);
      });
  };
  const getCallbackLogs = (params) => {
    setCallbackLogs(['Refreshing...']);
    API.get("fastagCallbackLogs", { params: params })
      .then((res) => {
        if (res.data.data?.length) {
          setCallbackLogs(res.data.data);
        } else {
          setCallbackLogs([]);
        }
      })
      .catch((err) => {
        console.log("catch", err);
      });
  };

  useEffect(() => {
    API.get("fastagTester")
      .then((res) => {
        setConfig(res.data.data);
      })
      .catch((err) => {
        console.log("catch", err);
      });
  }, []);

  return (
    <>
      <ContainerHeader title={`FASTag Plaza Tester`} match={props.match} />
      {config && (
        <Formik
          onSubmit={handleSubmit}
          initialValues={config.values}
          validationSchema={validations}
        >
          <>
            <ConfigDetails config={config} setConfig={setConfig} />
            <ProgressBar />
          </>
        </Formik>
      )}

      {response && (
        <>
          <TerminalBox
            logs={response.request ? response.request : []}
            allowScroll={false}
            type={"Script"}
            name={"Request"}
          />
          <TerminalBox
            logs={response.response ? response.response : []}
            allowScroll={false}
            type={"Script"}
            name={"Response"}
          />
        </>
      )}
      {callbackLogs && (
        <TerminalBox
          logs={callbackLogs}
          allowScroll={false}
          type={"Callback"}
          name={locSelected.title}
          handleRefresh={() => { getCallbackLogs({ txn_id: response.txn_id[0] }) }}
        />
      )}
      <SnackBar
        open={snackBar.open}
        handleClose={() => {
          setSnackBar({ open: false, msg: "" });
        }}
        message={snackBar.msg}
        type={snackBar.type}
      />
    </>
  );
};
export default FastagTester;
