
import React, { useEffect, useState } from 'react'
import InvoiceDetails from './InvoiceDetails.js'
import API from 'util/Api'
import SimpleTable from 'components/ActionsTable/SimpleTable.js'
import ContainerHeader from "components/ContainerHeader"
import CardBox from 'components/CardBox'
import { Formik } from 'formik'
import CircularProgress from 'components/Loader';
import SnackBar from 'components/SnackBar';
import * as Yup from "yup";
import moment from 'moment'

const SignupSchema = Yup.object().shape({
    invoice_no: Yup.string()
        .required('Required').max(20),
    location: Yup.object()
        .required('Location is Required').nullable(),
    issue_date: Yup.string()
        .required('Required'),
    status: Yup.string()
        .required('Required')
  });

const InvoiceTracking = (props) => {

    const [config,setConfig] = useState(null)
    const [values,setValues] = useState(null)
    const [invoice,setInvoice] = useState(null)
    const [invoiceList,setInvoiceList] = useState(null)
    const [snackBar,setSnackBar] = useState({open:false,msg:'',type:'error'});
    const [resetF,setResetF] = useState(false);
    const saveInvoice = (e,{setSubmitting}) => {
        console.log("save config",e)
        setSubmitting(true)
        API.post("invoices",JSON.stringify(e)).then((res) => {
            console.log("saveTag", res.data);
            setSnackBar({open:true,msg:res.data.msg,type:'success'});
            setInvoiceList(res.data.data.invoices)
            resetInvoice()
            setSubmitting(false)
            
        })
        .catch((err) => {
            setSubmitting(false)
            try{
                setSnackBar({open:true,msg:err.response.data.msg,type:'error'});
            }catch{
                setSnackBar({open:true,msg:"Something went wrong",type:'error'});
            }
            // got an error
            console.log("configuration api error",err.response)
        })
    }

    const resetInvoice = () => {
        // todo: do we need to do anything here?
        setInvoice(null);
        setResetF(true);
        console.log("reset form ")        
    }
    useEffect(()=>{
        getInvoices()
    },[])
    const getInvoices = () =>{
        
        API.get("invoices")
        .then((res) => {
            console.log("getInvoices", res.data.data)
             setConfig(Object.values(res.data.data.config))
             setInvoiceList(res.data.data.invoices)
            let data = {}
            for(let key in res.data.data.config){
                data[key]  = (res.data.data.config[key].type=='datepicker' &&res.data.data.config[key].value!=null)? moment(res.data.data.config[key].value) : res.data.data.config[key].value
            }
            console.log('values',data);
            setValues(data)
        })
        .catch((err) => {
            console.log("err",err);
        })
    }
    const handleInvoiceEdit = (clickedUser) => {
        console.log("handleInvoiceEdit ",clickedUser)
        clickedUser = {
            id:clickedUser.id,
            invoice_no: clickedUser.invoice_no,
            issue_date: moment(new Date(clickedUser.issue_date)),
            location: {title:clickedUser.identifier,value:clickedUser.location_hidden},
            settled_date: (clickedUser.settled_date)?moment(clickedUser.settled_date):null,
            status: clickedUser.status.split(' ')[0]
        }
        setInvoice(clickedUser)
        // scroll to top 
        document.body.style.height = 'auto'            
            setTimeout(function(){
                document.body.style.height = '100%'
        },50)
    }

    return(
        <>
        <ContainerHeader title={`Parking Invoices`} match={props.match} />
        { (config && values) &&
        <Formik
            onSubmit={saveInvoice}
            onReset={resetInvoice}
            initialValues={values}
            validationSchema={SignupSchema}
            enableReinitialize={true}
        >
            <>
            <InvoiceDetails
                config={config}
                invoice={invoice}
                resetFormik={resetF}
                setFalse={()=>{setResetF(false)}}
            />        
            
            </>
        </Formik>        
        }
        {
            !config && !values && <CircularProgress/>
        }
        {invoiceList &&
            <div className="row">
                <CardBox styleName="col-12">
                <SimpleTable 
                    type={'invoice-tracking'}
                    tableHeading = {invoiceList.title}
                    table={invoiceList} 
                    onUpdate = {handleInvoiceEdit}
                    actions={true} 
                />
                </CardBox>
            </div>
        }
            <SnackBar 
                open={snackBar.open} 
                handleClose={()=>{setSnackBar({open:false,msg:""})}}
                message={snackBar.msg}
                type={snackBar.type}
            />        
        </>
    )
}

export default InvoiceTracking;