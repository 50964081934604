import React, { useEffect, useState } from "react";
import API from "util/Api";
import ActionsTable from "components/ActionsTable/TableList";
import { Formik } from "formik";
import SnackBar from "components/SnackBar";
import { Form, connect } from "formik";
import { Button, Box } from "@material-ui/core";
import FormField from "components/FormField";
import _ from "lodash";
import CardBox from "components/CardBox";
import * as Yup from "yup";
import ContainerHeader from "components/ContainerHeader";
import PopUpDialog from 'components/PopUpDialog';

const ConsoleUsersForm = connect((props) => {
    console.log('console-form-props', props)
    useEffect(() => {
        if (props.consoleUser) {
            let data = {};
            for (let key in props.consoleUser) {
                data[key] = props.consoleUser[key].value;
            }
            if (!_.isEqual(data, props.formik.values)) {
                console.log('setting-values', data)
                props.formik.setValues(data);
            }
            props.setConsoleUser(undefined)
        }
    }, [props.consoleUser]);

    useEffect(() => {
        const locValues = props.formik.values.locations;
        let config = _.cloneDeep(props.config);
        if (locValues.length > 0) {
            let clients = [];
            locValues.forEach((loc, index) => {
                clients = [...clients, ...loc.clients]
            })
            config.fields.clients.options = clients;
            props.setConfig(config)
        } else {
            config.fields.clients.options = [];
            props.setConfig(config)
        }
    }, [props.formik.values.locations])

    return (
        <CardBox styleName="col-12" heading={props.config.title}>
            <Form>
                {/* FIRST ROW */}
                <div className="row">
                    {Object.values(props.config.fields).map((field) => (
                        <div className="col-md-3 col-12" key={`field-${field.key}`}>
                            <FormField field={{ ...field, ...{ name: field.key } }} />
                        </div>
                    ))}
                </div>
                {/* BUTTONS */}
                <div className="row mt-2">
                    <div className="col-lg-6 col-sm-12 col-12">
                        <Box mt="20px">
                            <Box mr="10px" display="inline">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    mr="5px"
                                    type="submit"
                                >
                                    {" "}
                                    Submit{" "}
                                </Button>
                            </Box>
                            <Box mr="10px" display="inline">
                                <Button variant="outlined" color="secondary" type="reset">
                                    Reset
                                </Button>
                            </Box>
                        </Box>
                    </div>
                </div>
            </Form>
        </CardBox>
    );
})

const ConsoleUsers = (props) => {
    const [config, setConfig] = useState();
    const [consoleUsers, setConsoleUsers] = useState();
    const [consoleUser, setConsoleUser] = useState();
    const [snackBar, setSnackBar] = useState({
        open: false,
        msg: "",
        type: "error",
    });
    const [dialog, setDialog] = useState({open:false,content:'',header:'',agreeBtnTxt:'',disAgreeBtnTxt:'', type:'', callback:null});
    const validations = Yup.object().shape({
        fname: Yup.string().required("Required"),
        lname: Yup.string().required("Required"),
        phone: Yup.string().matches(/^\+?[1-9]\d{1,14}$/, 'Invalid phone number').required("Required"),
        email: Yup.string().email('Invalid email').required('Required'),
        permissions: Yup.array().required("Required"),
        locations: Yup.array().required("Required"),
        clients: Yup.array().required("Required"),
    });

    useEffect(() => {
        API.get("accessConsoleUsers")
            .then((res) => {
                setConfig(res.data.data.config);
                setConsoleUsers(res.data.data.consoleUsers);
            })
            .catch((err) => { });
    }, []);

    const handleSubmit = (data, form) => {
        API.post("accessConsoleUsers", JSON.stringify(data))
            .then((res) => {
                setConsoleUsers(res.data.data.consoleUsers);
                form.setSubmitting(false);
                form.resetForm();
                setSnackBar({ open: true, msg: res.data.msg, type: "success" });
            })
            .catch((err) => {
                form.setSubmitting(false);
                setSnackBar({
                    open: true,
                    msg: err?.response?.data?.msg ? err.response.data.msg : "Someting went wrong",
                    type: "error",
                });
            });
    }

    const handleEdit = (row) => {
        setConsoleUser(row)
        // scroll to top
        document.body.style.height = "auto";
        setTimeout(function () {
            document.body.style.height = "100%";
        }, 50);
    }

    const updateStatus = (id, status, cb) => {
        API.put("accessConsoleUserStatus", JSON.stringify({ id: id, active: status }))
            .then((res) => {
                cb(status)
            })
            .catch((err) => {
                console.log("error", err);
            });
        handleDialogClose();
    };

    const updateActiveStatus = (id, checked, cb)=> {
        let consoleUser = _.find(consoleUsers.body, (consoleUser) => consoleUser.id.value === id);

        setDialog({
            open:true,
            header:`${checked == true ? 'Activate': 'Deactivate'} Console User`,
            content:`Are you sure you want to ${checked ? 'activate': 'deactivate'} ${consoleUser.name.value}?`,
            agreeBtnTxt:'Confirm',
            disAgreeBtnTxt:'Cancel',
            type:'updateActiveStatus',
            consoleUser:{id:id, active:checked},
            callback:cb
        });
    }

    const confirmDialogSubmit = (type) =>{
        if(type === 'updateActiveStatus'){
            updateStatus(dialog.consoleUser.id, dialog.consoleUser.active, dialog.callback);
        }
    }

    const handleDialogClose = ()=>{
        setDialog({
            open:false,
            content:'',
            header:'',
            agreeBtnTxt:'',
            disAgreeBtnTxt:'',
            type:'',
            callback:null
        });
    }

    return (<>
        <ContainerHeader
            title={`Access Console Users`}
            match={props.match}
        />
        {config && <Formik
            onSubmit={handleSubmit}
            initialValues={config.values}
            validationSchema={validations}
        >
            <ConsoleUsersForm
                config={config}
                consoleUser={consoleUser}
                setConsoleUser={setConsoleUser}
                setConfig={setConfig}>
            </ConsoleUsersForm>
        </Formik>}
        {consoleUsers && consoleUsers.body.length > 0 &&
            <ActionsTable
                tableHeading={consoleUsers.title}
                table={consoleUsers}
                onEdit={handleEdit}
                updateStatus={updateActiveStatus}
                actions={true}
            />}
        <SnackBar
            open={snackBar.open}
            handleClose={() => {
                setSnackBar({ open: false, msg: "" });
            }}
            message={snackBar.msg}
            type={snackBar.type}
        />
        <PopUpDialog
            fullWidth
            open={dialog.open}
            header={dialog.header}
            content ={dialog.content}
            agreeBtnTxt ={dialog.agreeBtnTxt}
            disAgreeBtnTxt={dialog.disAgreeBtnTxt}
            agreeClicked ={() =>confirmDialogSubmit(dialog.type)}
            handleDialogClose={handleDialogClose}
        />    
    </>)
}
export default ConsoleUsers;