import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import CardBox from 'components/CardBox'
import {Box} from '@material-ui/core'
import MsgPopover from 'components/MsgPopover'
import {SwapHoriz} from '@material-ui/icons'
import ControllerDetails from './ControllerDetails'
import SimpleTable from 'components/ActionsTable/SimpleTable'
import SnackBar from 'components/SnackBar'
import API from '../../../../../util/Api'
import * as Yup from "yup"
import _ from 'lodash'

const Controller = (props) => {
    const [values,setValues] = useState(null)
    const [config,setConfig] = useState(null)
    const [controllerConfig,setControllerConfig] = useState(null)
    const [resetF,setResetF] = useState(false)
    const [controllerList,setControllerList] = useState(null)
    const [snackBar,setSnackBar] = useState({open:false,msg:'',type:'error'});
    
    const formatControllerList = (controllerList) =>{
        let tBody = []
            for(let body of controllerList.body) {
                console.log("tBody",body)
                tBody.push(body.tableList)
            }
            let table = {
                ...controllerList,
                'tbody':tBody
            }
        setControllerList(table)
    }
    const saveController = (e,{setSubmitting}) =>{
        
        setSubmitting(true)
        API.post("controller",JSON.stringify(e))
        .then((res) => {
            console.log("ledpanelpost",res.data.data.controller)
            if(controllerList){
                let list = _.cloneDeep(controllerList)
                let controller = res.data.data.controller[0]
                // Find item index 
                var index = _.findIndex(list.body, {id: controller.id});
                if(index!=-1){
                    // Replace item at index using native splice
                    list.body.splice(index, 1, controller)
                }else{
                    list.body.splice(0, 0, controller)
                }
                formatControllerList(list)

            }
            setSubmitting(false)
            setSnackBar({open:true,msg:res.data.msg,type:'success'});
            resetController()
        })
        .catch((err) => {
            try{
                setSnackBar({open:true,msg:err.response.data.msg,type:'error'});
            }catch{
                setSnackBar({open:true,msg:"Something went wrong",type:'error'});
            }
            setSubmitting(false)
            console.log("catch",err);
        })
        
    }
    const resetController =()=>{
        setControllerConfig(null)
        setResetF(true)
    }
    useEffect(()=>{
        
        API.get("controller").then((res) => {
            console.log("ControllerGet",res.data.data)
            setConfig(res.data.data.config.sections)
            formatControllerList(res.data.data.controllersList)
            
            let sections = res.data.data.config.sections
            let data = {}
            for(let section of sections){
                console.log("section",section)
                if(section.type==='fields'){
                    section.fields.map(field=>{
                        data[field.key]  = field.value
                    })
                    
                }else if(section.type==='fieldArray'){
                    data[section.key] = []
                    section.multiValues.map(field=>{
                        console.log("section.key",section.key,field)
                        data[section.key].push(field)
                    })
                }
                    
            }
            console.log("setValues",data,sections)
            setValues(data)
        })
        .catch((err) => {
            console.log("catch",err);
        })

    },[])
    const handleControllerEdit = (clickedId)=>{
        
        let controllerSec = _.find(controllerList.body,{id:clickedId}).sections
        console.log("handleStaffEdit ",clickedId,controllerSec)
        let data = {}
        for(let section of controllerSec){
            console.log("section",section)
            if(section.type==='fields'){
                section.fields.map(field=>{
                    data[field.key]  = field.value
                })
                
            }else if(section.type==='fieldArray'){
                data[section.key] = []
                section.multiValues.map(field=>{
                    console.log("section.key",section.key,field)
                    data[section.key].push(field)
                })
            }
                
        }
        data.id=clickedId
        console.log("setValues",data)
        setControllerConfig(data)
        // scroll to top 
        document.body.style.height = 'auto'            
            setTimeout(function(){
                document.body.style.height = '100%'
        },50)
    }
    return(
        <>
        {
        config && values &&
            <Formik
                onSubmit={saveController}
                onReset={resetController}
                initialValues={values}
                //validationSchema={validations}
                resetInitalValue={true}

            >
                <ControllerDetails 
                    controllerConfig={controllerConfig}
                    config={config}
                    resetFormik={resetF}
                    setFalse={()=>{console.log('setResetF');setResetF(false)}}
                />
            </Formik>
        }
        {
        controllerList&& 
            <div className="row">
                <CardBox styleName="col-12" headerOutside 
                    heading={
                        <React.Fragment>
                            {controllerList.title}
                            <MsgPopover msg='Big table, scroll horizontally' timeout='1500'>
                            <Box display={{xs:'inline',md:'none'}}><SwapHoriz color="primary" /></Box></MsgPopover>
                        </React.Fragment>
                    }>                        
                        <SimpleTable type={'controller'} table={controllerList} onEdit={handleControllerEdit}/>
                </CardBox>
            </div>
        }
        {
            <SnackBar 
                open={snackBar.open} 
                handleClose={()=>{setSnackBar({open:false, msg:""})}}
                message={snackBar.msg}
                type={snackBar.type}
            />
        }
        </>
    )
}
export default Controller