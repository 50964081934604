import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, connect, FieldArray } from "formik";
import { Button, Box, Typography, makeStyles } from "@material-ui/core";
import FormField from "components/FormField";
import CardBox from "components/CardBox";
import ContainerHeader from "components/ContainerHeader";
import API from "util/Api";
import _ from "lodash";
import * as Yup from "yup";
import moment from "moment";
import SnackBar from "components/SnackBar";

const useStyles = makeStyles((theme) => ({
    addButton: {
        margin: "10px 0",
    },
    title: {
        marginBottom: 10,
    },
    field: {
        paddingTop: 5,
        paddingBottom: 5,
    },
}));

const SubscriptionField = connect((props) => {
    console.log("subsc-field-props", props);
    const classes = useStyles();
    const values = _.cloneDeep(props.formik.values);
    return (
        <>
            {!Array.isArray(values[props.section.key]) ? (
                <div className="row mb-4">
                    {props.section.fields.map((field) => (
                        <div className="col-md-3 col-12" key={`field-${field.key}`}>
                            <FormField
                                field={{
                                    ...field,
                                    ...{ name: `${props.section.key}.${field.key}` },
                                }}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <>
                    <div className="row">
                        <div className="col-12">
                            <Typography
                                className={classes.title}
                                varient={"h5"}
                                component={"h5"}
                            >
                                {props.section.title}
                            </Typography>
                        </div>
                    </div>
                    <FieldArray
                        name={props.section.key}
                        render={(arrayHelpers) => (
                            <>
                                {values[props.section.key].map((item, index) => (
                                    <div className="row">
                                        {props.section.fields.map((field) => (
                                            <div
                                                className="col-md-3 col-12 w-100"
                                                key={`field-${field.key}`}
                                            >
                                                <FormField
                                                    field={{
                                                        ...field,
                                                        ...{
                                                            name: `${props.section.key}[${index}].${field.key}`,
                                                        },
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </>
                        )}
                    />
                </>
            )}
        </>
    );
});

const SubscriptionSections = connect((props) => {
    console.log("subsc-config-props", props);

    return (
        <CardBox styleName="col-12" heading="">
            <Form>
                {props.config.map((section, index) =>
                    <SubscriptionField section={section} key={`section-${index}`} />
                )}
                {/* BUTTONS */}
                <div className="row mt-2">
                    <div className="col-lg-6 col-sm-12 col-12">
                        <Box mt="20px">
                            <Box mr="10px" display="inline">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    mr="5px"
                                    type="submit"
                                >
                                    {" "}
                                    Submit{" "}
                                </Button>
                            </Box>
                            <Box mr="10px" display="inline">
                                <Button variant="outlined" color="secondary" type="reset">
                                    Reset
                                </Button>
                            </Box>
                        </Box>
                    </div>
                </div>
            </Form>
        </CardBox>
    );
});

const UpdateSubscription = (props) => {
    console.log("edit-subsc-props", props);
    const { id, type } = useParams()
    const [data, setData] = useState({});
    const [snackBar, setSnackBar] = useState({
        open: false,
        msg: "",
        type: "error",
    });

    const validations = (initials) => Yup.object().shape({
        subscriptionDetails: Yup.object().shape({
            first_name: Yup.string().required("Required"),
            ...(initials.subscriptionDetails.id && { // validation will work only in case of user subsc
                start: Yup.date().required("Required"),
                end: Yup.date().required("Required")
            })
        }),
        tagDetails: Yup.array().of(
            Yup.object().shape({
                tag: Yup.string().required("Required"),
            })
        )
    });
    const handleSubmit = (e, { setSubmitting }) => {
        console.log("submit-data", e);
        setSubmitting(true);
        API.put("access_subscription", JSON.stringify({ ...e, type: type }))
            .then((res) => {
                setSubmitting(false);
                setData({...data, values:e});
                setSnackBar({ open: true, msg: res.data.msg, type: "success" });
            })
            .catch((err) => {
                setSnackBar({
                    open: true,
                    msg: err?.response?.data?.msg
                        ? err.response.data.msg
                        : "Something went wrong",
                    type: "error",
                });
                setSubmitting(false);
                console.log("catch");
            });
    };

    useEffect(() => {
        API.get(`access_subscription?id=${id}&type=${type}`)
            .then((res) => {
                setData(res.data.data);
            })
            .catch((err) => {
                console.log("catch", err);
            });
    }, []);

    return (
        <>
            <ContainerHeader title={`Edit Subscription`} match={props.match} />
            {data.config &&
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={data.values}
                    validationSchema={validations(data.values)}
                    enableReinitialize={true}
                >
                    <SubscriptionSections config={data.config} />
                </Formik>}
            <SnackBar
                open={snackBar.open}
                handleClose={() => {
                    setSnackBar({ open: false, msg: "" });
                }}
                message={snackBar.msg}
                type={snackBar.type}
            />
        </>
    );
};
export default UpdateSubscription;
