import Hashids from 'hashids'
const hashKey = "V9J8a1XgEbYfFi9XrsuCBkGvRPk6";

export const encode = function(text){
    var hashids = new Hashids(hashKey);
    var encode = hashids.encode(text);
    return encode; 
}

export const decode = function(text){
    var hashids = new Hashids(hashKey);
    var decode = hashids.decode(text); 
    return decode; 
}
