import React, { useEffect, useState } from "react";
import API from "util/Api";
import { Formik } from "formik";
import SnackBar from "components/SnackBar";
import * as Yup from "yup";
import { Form, connect } from "formik";
import { Button, Box } from "@material-ui/core";
import FormField from "components/FormField";
import _ from "lodash";
import CardBox from "components/CardBox";
import ContainerHeader from "components/ContainerHeader";
import dummyData from "./dummy";

const UploadBuildForm = (props) => {
  return (
    <div className="row">
      <CardBox styleName="col-12">
      <Form>
        {/* FIRST ROW */}
        <div className="row">
          {props.config.fields.map((field) => (
            <div className="col-md-3 col-12" key={`field-${field.key}`}>
              <FormField field={{ ...field, ...{ name: field.key } }} />
            </div>
          ))}
        </div>
        {/* BUTTONS */}
        <div className="row mt-2">
          <div className="col-lg-6 col-sm-12 col-12">
            <Box mt="20px">
              <Box mr="10px" display="inline">
                <Button
                  variant="contained"
                  color="primary"
                  mr="5px"
                  type="submit"
                >
                  {" "}
                  Submit{" "}
                </Button>
              </Box>
              <Box mr="10px" display="inline">
                <Button variant="outlined" color="secondary" type="reset">
                  Reset
                </Button>
              </Box>
            </Box>
          </div>
        </div>
      </Form>
    </CardBox>
    </div>
  );
};

const UploadBuild = (props) => {
  const [initialValues, setInitialValues] = useState(null);
  const [config, setConfig] = useState(null);
  const [snackBar, setSnackBar] = useState({
    open: false,
    msg: "",
    type: "error",
  });
  const validations = Yup.object().shape({
    controller_type: Yup.string().required("Required"),
    version: Yup.number().required("Required"),
    build: Yup.string().required("Required"),
  });
  const submitBuild = (data, form) => {
    console.log("submit data", data);
    // const formData = new FormData();
    // formData.append("build", data.build);
    // formData.append("controller_type", data.controller_type);
    // formData.append("version", data.version);
    API.post("uploadBuild", data)
      .then((res) => {
        form.setSubmitting(false);
        form.resetForm();
        setSnackBar({ open: true, msg: res.data.msg, type: "success" });
      })
      .catch((err) => {
        form.setSubmitting(false);
        try {
          setSnackBar({
            open: true,
            msg: err.response.data.msg,
            type: "error",
          });
        } catch {
          setSnackBar({
            open: true,
            msg: "Something went wrong",
            type: "error",
          });
        }
      });
  };
  useEffect(() => {
    API.get("uploadBuild")
      .then((res) => {
        let values = {};
        res.data.data.config.fields.map((field) => {
          values[field.key] = field.value;
        });
        setInitialValues(values);
        setConfig(res.data.data.config);
      })
      .catch((err) => {});
  }, []);
  return (
    <>
      {config && <ContainerHeader
        title={config.title}
        match={props.match}
      />}
      {config && initialValues && (
        <Formik
          onSubmit={submitBuild}
          initialValues={initialValues}
          validationSchema={validations}
        >
          <UploadBuildForm config={config} />
        </Formik>
      )}
      <SnackBar
        open={snackBar.open}
        handleClose={() => {
          setSnackBar({ open: false, msg: "" });
        }}
        message={snackBar.msg}
        type={snackBar.type}
      />
    </>
  );
};
export default UploadBuild;
