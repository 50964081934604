import React, { Fragment } from 'react';
import {Table,Grid} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CellFormatting from './CellFormatting';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import ImageDialog from './imageDialog'
import OrderDetails from '../../app/routes/ManageOrders/routes/ManageOrders/OrderDetails'
import SearchBox from 'components/ActionsTable/SearchBox';
import PopUpDialog from 'components/PopUpDialog'
import _ from 'lodash'

class RowFormatting extends React.Component {
    constructor(props) {
        super(props);       
        this.state = {
          selectedpage: 0,
          rowsPerPage: 25,
          open: false,
          image: [],
          orderDetails: false,
          products: [],
          orderDetailsHeading: "",
          tableData: this.props.data,
          popupDialogOpen: false,
          popupHeader: "",
          popUpContent: "",
          showMessage: true,
          styleClass: this.props.tableClass ? this.props.tableClass : "",
        };
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
        this.handleImageDialog = this.handleImageDialog.bind(this)
        this.handleImageClose = this.handleImageClose.bind(this)
        this.hideOrderDetails = this.hideOrderDetails.bind(this)
        this.showOrderDetails = this.showOrderDetails.bind(this)
        console.log("RowFormatting",this.props)
    }

    componentDidUpdate(prvProps) {
        console.log(this.props.id," - getDerivedStateFromProps - prvProps")
        if (this.props.data.length !== prvProps.data.length || !_.isEqual(this.props.data,prvProps.data)) {

          this.setState({tableData:this.props.data})
        }
      }
    handleChangePage(event,page) {
      this.setState({ selectedpage: page})
    }
    handleChangeRowsPerPage(event) {
        this.setState({ rowsPerPage: event.target.value}) 
    }
    handleImageDialog(images){

        this.setState({open:true,image:images})
    }
    handleImageClose(){
        this.setState({open:false,image:[]})
    }
    showOrderDetails(order){
        var heading = order.table_name ? '#'+order.id+' - '+order.table_name+' - '+order.created_on : '#'+order.id+' - '+order.created_on;
        this.setState({orderDetails:true,products:order.button,orderDetailsHeading:heading})

    }
    hideOrderDetails(){
        this.setState({orderDetails:false,products:[]})

    }
    handlePopUpDialog=(header,content)=>{
        this.setState({popupDialogOpen:true,popupHeader:header,popUpContent:content})
    }
    handlePopUpClose=()=>{
        this.setState({popupDialogOpen:false,popupHeader:'',popUpContent:''})
    }
//filter table according to search
handleSearch = (searchValue)=>{
    console.log("handleSearch",this.state.tableData,searchValue)
    // lets find rowspans    
    let index= 0
    var filter = [];
    
    if(searchValue){
        while(index<this.props.data.length){
            let firstRow = this.props.data[index]
            let objs = Object.keys(firstRow).filter(key => {
                console.log("firstRowkey",key);
                return typeof firstRow[key] === "object" && firstRow[key] !== null && key !='button' && key!='comments' && key!='images' && key!='actions'
            });
            let maxSpan = 1
            if(objs.length>0){
                let maxRowspan = Math.max(
                    ...objs.map(key => parseInt(firstRow[key]?.style?.rowspan)) // Create an array of all rowspans
                );
                
                let maxKey = objs.find(key => parseInt(firstRow[key]?.style?.rowspan) === maxRowspan); // Find the key corresponding to maxRowspan
                
                if(maxKey){
                    maxSpan = Math.max(parseInt(firstRow[maxKey].style?.rowspan),1) 
                }
                console.log("checking key in Object",objs,firstRow,maxKey);
            }
            //for colspan filtter
            let colSpanFlag = false
            if( Number.isNaN(maxSpan)){
                colSpanFlag=true
                let tempIndex = index+1
                maxSpan=2
                while(this.props.data[tempIndex] && typeof this.props.data[tempIndex][objs[0]] != 'object'){
                    maxSpan++
                    tempIndex++
                }
            }
            let rowsAdded = false
            for(let i=index ;i <index+maxSpan && !rowsAdded;i++){                
                let row = this.props.data[i]
                for(let key in row){
                    if(!key.includes('hidden') && row[key] !== null){
                        let value = (typeof row[key] === "object" && key !='button' && key!='comments' &&  key!='images' &&  key!='actions' && row[key] ? row[key].title : row[key]).toString().toLowerCase()
                        if(value.includes(searchValue.toLowerCase())){
                            
                            if(colSpanFlag && typeof row[key] !=='object'){
                                maxSpan=1
                            }
                            for(let k=0;k<maxSpan;k++){
                                filter.push(this.props.data[index+k])
                            }                                
                            rowsAdded = true
                            break;
                        }
                    }                    
                }                
            }
            index+=maxSpan
        }

        console.log("filterdedArray",filter,this.props.data)
        this.setState({tableData:filter});
    }else{
        this.setState({tableData:this.props.data});
    }
}
     render(){ 
        return (
            <div className={this.state.styleClass}>
                <Grid item xs={12} style={{margin:12}}>
                    <SearchBox handleSearch={this.handleSearch.bind(this)}/>
                </Grid>
                <div className="table-responsive-material">
                    <Table>
                        <TableHead>
                            <TableRow >
                            {this.props.headers.map((n,index) => {
                                return <CellFormatting key ={this.props.tableName+'table-head-'+index} data={n} type='headers' />
                            })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                (this.state.rowsPerPage > 0
                                ? this.state.tableData.slice(this.state.selectedpage * this.state.rowsPerPage, this.state.selectedpage * this.state.rowsPerPage + this.state.rowsPerPage)
                                : this.state.tableData
                                ).map((n,index) => {
                                return (
                                    <TableRow key={this.props.tableName+'table-row-'+index}>
                                        <CellFormatting 
                                        handleImageDialog={this.handleImageDialog} 
                                        showOrderDetails={this.showOrderDetails} 
                                        renewSubscription={this.props.renewSubscription} 
                                        onEnable={this.props.onEnable}
                                        handlePopUpDialog={this.handlePopUpDialog.bind(this)}
                                        updteVehicleNo={this.props.updteVehicleNo} 
                                        updateAmount={this.props.updateAmount}
                                        onEdit={this.props.onEdit}
                                        updateParkingAmount={this.props.updateParkingAmount}
                                        downloadInvoice={this.props.downloadInvoice}
                                        downloadReceipt={this.props.downloadReceipt}
                                        deleteMaintenance={this.props.deleteMaintenance}
                                        downloadReport={this.props.downloadReport}
                                        closeBooking={this.props.closeBooking}
                                        data={n} index={index} type='body' />
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        {this.props.pagination ? <TableFooter>
                            <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50, { label: 'All', value: -1 }]}
                                count={this.state.tableData.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.selectedpage}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                            />
                            </TableRow>
                    </TableFooter> : '' }
                    </Table>
                    <ImageDialog open={this.state.open} image={this.state.image} handleImageClose={this.handleImageClose} />
                    <OrderDetails heading={this.state.orderDetailsHeading} dineInReports={true} loading = {this.state.orderDetails} isShowing={this.state.orderDetails} orderDetails={this.state.products} handleClose={this.hideOrderDetails} />                    
                    <PopUpDialog open={this.state.popupDialogOpen} header={this.state.popupHeader} content={this.state.popUpContent} showMessage={this.state.showMessage} handleDialogClose={this.handlePopUpClose.bind(this)}/>
                </div>
            </div>
        )
    }
    
}
export default RowFormatting
