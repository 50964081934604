import React, { Component, useEffect, useState } from 'react';
import _ from 'lodash';
import API from 'util/Api';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardBox from 'components/CardBox';
import ContainerHeader from 'components/ContainerHeader';
import { connect } from 'react-redux';
import { Box, Select, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, FormControl, InputLabel, MenuItem, Grid, FormHelperText } from '@material-ui/core'
import EditLocation from './EditLocation'
import LocationList from './LocationList'
import MsgPopover from 'components/MsgPopover'
import { SwapHoriz } from '@material-ui/icons'
import SnackBar from 'components/SnackBar';
import { DialogBox } from 'components/PopUpDialog';
import { encode, decode } from 'util/secure';

const SQSCommand = (props) => {
    console.log("SQSCommand-props", props)
    const [sqsCon, setSqsCon] = useState({
        loc_id: props.sqsConfig.id,
        type: '',
        guardId: []
    })
    const [error, setError] = useState({
        type: '',
        guardId: ''
    })
    const [guardList, setGuardList] = useState([{ id: 0, name: 'All Guards' }])
    useEffect(() => {
        setGuardList([...guardList, ...props.locGuards.guards])
    }, [])
    const onSubmit = () => {

        if (sqsCon.type.length === 0) {
            setError({ ...error, type: 'Required' })
        } else if (sqsCon.guardId.length === 0) {
            setError({ ...error, guardId: 'Required' })
        } else {
            setError({ type: '', guardId: '' })
            API.post('sendSqsCommands', JSON.stringify(sqsCon)).then((res) => {
                console.log('sendSqsCommands-response', res.data.data)
                setTimeout(() => { }, 3000);//close pop up 
            })
                .catch((err) => {
                    // got an error
                    console.log("sendSqsCommands-error", err)
                })
        }

    }
    console.log("SQSCommand-sqsCon", sqsCon)
    return (
        <DialogBox
            {...props}
            header={'Send SQS Command :- ' + props.sqsConfig.name}
            onSubmit={() => onSubmit()}
        >
            <div className={'row'}>
                <Grid xs={4} item style={{ padding: 10 }}>
                    <FormControl error={error.type ? true : false} fullWidth >
                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={sqsCon.type}
                            name={'type'}
                            onChange={e => setSqsCon({ ...sqsCon, type: e.target.value })}
                        >
                            <MenuItem value={''}>None</MenuItem>
                            <MenuItem value={'reload_subscription'}>Reload Subscriptions</MenuItem>
                            <MenuItem value={'reload_config'}>Reload Config</MenuItem>
                        </Select>
                        <FormHelperText>{error.type}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid xs={4} item style={{ padding: 10 }}>
                    <FormControl error={error.guardId ? true : false} fullWidth>
                        <InputLabel id="demo-simple-select-label">Guard</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={sqsCon.guardId}
                            name={'guardId'}
                            multiple
                            onChange={e => {
                                if (e.target.value.includes(0)) {
                                    setSqsCon({ ...sqsCon, guardId: [0] })
                                } else {
                                    setSqsCon({ ...sqsCon, guardId: e.target.value })
                                }
                            }}
                        >
                            {
                                guardList.map(guard => <MenuItem value={guard.id}>{guard.name}</MenuItem>)
                            }
                        </Select>
                        <FormHelperText>{error.guardId}</FormHelperText>
                    </FormControl>
                </Grid>
            </div>
        </DialogBox>
    )
}

class ParkingLocation extends Component {
    constructor(props) {
        super(props);
        const initialSnack = {
            snackOpen: false,
            snackMessage: '',
            snackType: '',
        }
        this.state = {
            match: props.match,
            table: {},
            selectedLocation: null,
            locations: null,
            vehicleTypes: [],
            period: ["Daily", "Monthly", "Annual"],
            reportTypes: ['Pay and park', 'Subscription', 'Both'],
            appState: 'loading',
            requestStatus: { msg: "", success: true },
            tags: [{ 'id': 0, 'name': 'Clear', 'tags': null }],
            errorMessage: '',
            cities: [],
            locationTags: [],
            fastTagBanks: [],
            deactivate: '',
            deactivateLocation: null,
            isShowing: false,
            isReportRequest: true,
            refreshForm: false,
            snackBar: initialSnack,
            sqsConfig: null,
            guards: [],
            fastagLocations: []
        }
        this.onEdit = this.onEdit.bind(this)
        this.onSettings = this.onSettings.bind(this)
        this.onEnable = this.onEnable.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onEnableConfirm = this.onEnableConfirm.bind(this)
        this.rateModel = this.rateModel.bind(this)
        this.parkingSlots = this.parkingSlots.bind(this)
    }

    componentDidMount() {
        if (this.props.reportFlags === undefined) {
            localStorage.removeItem("token")
        }

        API.get('locParkingConfigOptions').then((res) => {
            console.log('vehicleTypes', res.data.data)
            let data = res.data.data;
            this.setState({ vehicleTypes: data.vehicle_type, cities: data.cities, fastagLocations: data.fasttag_loc, locationTags: data.loc_tags, appState: 'active', errorMessage: "" })
        }).catch((err) => {
            this.setState({ errorMessage: "Please Try Again" });
        })

        // API.get('vehicle_types').then((res) => {
        //     console.log('vehicleTypes vehicle_type', res.data.data)
        //     this.setState({ vehicleTypes: res.data.data, appState: 'active', errorMessage: "" })
        // })
        //     .catch((err) => {
        //         // got an error
        //         this.setState({ errorMessage: "Please Try Again" });
        //     })
        // API.get('getCities').then((res) => {
        //     console.log('vehicleTypes cities', res.data.data)
        //     this.setState({ cities: res.data.data, errorMessage: "" })
        // })
        //     .catch((err) => {
        //         // got an error
        //         this.setState({ errorMessage: "Please Try Again" });
        //     })
        API.get('parkingLocationsAll').then((res) => {
            console.log('locations', res.data.data.locations)
            this.setState({ locations: res.data.data.locations, errorMessage: "", fastTagBanks: res.data.data.fastTagBanks })
        })
            .catch((err) => {
                // got an error
                this.setState({ errorMessage: "Please Try Again" });
            })
        API.get('location?guard=true').then((res) => {
            console.log('location-guard', res.data.data)
            this.setState({ guards: res.data.data, errorMessage: "" })
        })
            .catch((err) => {
                // got an error
                this.setState({ errorMessage: "Please Try Again" });
            })
        // API.get('fastag_location').then((res) => {
        //     this.setState({ fastagLocations: res.data.data, errorMessage: "" })
        // })
        //     .catch((err) => {
        //         // got an error
        //         this.setState({ errorMessage: "Please Try Again" });
        //     })
    }

    onEdit = (loc) => {
        console.log("edit clicked", loc)
        this.setState({ selectedLocation: loc })

        // force the page to scroll to top
        document.body.style.height = 'auto'
        setTimeout(function () {
            document.body.style.height = '100%'
        }, 70)
    }

    rateModel = (loc) => {
        window.open(`${this.props.match.url}/tariffs?id=${encode(loc.id)}&name=${encodeURIComponent(loc.name)}`, '_blank');

    }

    parkingSlots = (loc) => {
        window.open(`${this.props.match.url}/slots/${encode(loc.id)}`, '_blank');
    }

    onSettings = (loc) => {
        console.log("settings clicked", loc.name)
        window.open(`${this.props.match.url}/configuration?id=${encode(loc.id)}&name=${encodeURIComponent(loc.name)}`, '_blank');
    }

    onEnable = (loc, checked) => {
        console.log("enable clicked", loc, checked)
        this.setState({ deactivateLocation: loc, deactivate: !checked, isShowing: true })
    }

    onEnableConfirm = () => {
        let params = {
            id: this.state.deactivateLocation.id,
            confirm: this.state.deactivate === true ? 0 : 1
        }

        API.post('deactivateLocation', JSON.stringify(params))
            .then((res) => {
                let locations = _.cloneDeep(this.state.locations)
                let index = _.findIndex(this.state.locations.table, { id: params.id })
                locations.table[index].active = params.confirm

                this.setState({ isShowing: false, appState: 'active', errorMessage: "", locations: locations, deactivateLocation: null })
            })
            .catch((err) => {
                this.setState({ errorMessage: "Please Try Again" });
            })

    }

    onReset = () => {
        console.log("reset clicked")
    }

    onSubmit = (loc) => {
        console.log("Submit clicked", loc)
        API.post('parkingLocations', JSON.stringify(loc))
            .then((res) => {
                console.log("parkingLocationspost", res.data)
                this.setSnackState(true, res.data.msg, 'success');

                this.setState({
                    locations: res.data.data.locations,
                    selectedLocation: null,
                    refreshForm: true,
                });

            })
            .catch((err) => {
                try {
                    this.setSnackState(true, err.response.data.msg, 'error');
                } catch {
                    this.setSnackState(true, "Something went wrong", 'error');
                }
            })
    }

    handleClickOpen = () => {
        this.setState({ isShowing: true })
    }

    handleClose = () => {
        this.setState({ isShowing: false })
    }
    setSnackState = (open, msg, type) => {
        this.setState({ snackBar: { 'snackOpen': open, 'snackMessage': msg, 'snackType': type } })
    }
    sqsCommand = (loc) => {
        console.log("sqsCommand", loc)
        this.setState({ sqsConfig: loc })
    }
    renderState(state) {
        switch (state) {
            case "loading":
            default:
                return (<div style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}><CircularProgress color="secondary" size={50} /></div>)
            case "active":
            case "waiting":
                return (<React.Fragment> {this.renderPage()} </React.Fragment>)
        }
    }

    render() {
        return (<React.Fragment> {this.renderState(this.state.appState)} </React.Fragment>)
    }
    renderPage() {
        console.log("renderPage", this.state);
        return (
            <div>
                <ContainerHeader title="Edit Location" match={this.props.match} />
                {/* EDIT LOCTION SECTION */}
                <div className="row">
                    <CardBox styleName="col-12" heading='Location Details'>
                        {this.state.cities.length > 0 && this.state.vehicleTypes.length > 0 &&
                            <EditLocation
                                options={{ cities: this.state.cities, vehicleTypes: this.state.vehicleTypes, fastTagBanks: this.state.fastTagBanks, fastagLocations: this.state.fastagLocations, locationTags: this.state.locationTags }}
                                location={this.state.selectedLocation}
                                onReset={() => this.setState({ selectedLocation: null })}
                                onSubmit={this.onSubmit}
                                setRefresh={() => this.setState({ refreshForm: false })}
                                refreshForm={this.state.refreshForm}
                            />}
                    </CardBox>

                    {this.state.errorMessage !== '' &&
                        <h3 className="error"> {this.state.errorMessage} </h3>}

                    {this.state.deactivateLocation &&
                        <Dialog open={this.state.isShowing} onClose={this.handleClose}
                            disableBackdropClick
                            disableEscapeKeyDown
                        >
                            <DialogTitle disableTypography>
                                <Typography variant={"h6"} color={this.state.deactivate ? "error" : "secondary"}>
                                    {this.state.deactivate ? "Deactivate" : "Activate"}
                                </Typography>
                            </DialogTitle>

                            <DialogContent color="secondary">
                                <Typography variant={"body1"}>
                                    Are you sure you want to {(this.state.deactivate ? 'deactivate' : 'activate')} : {this.state.deactivateLocation.name} ?
                                </Typography>
                            </DialogContent>

                            <DialogActions>
                                <Box display="inline-block">
                                    <Button onClick={this.onEnableConfirm} variant="contained" color="primary">
                                        Yes
                                    </Button>
                                </Box>
                                <Box display="inline-block">
                                    <Button onClick={this.handleClose} variant="outlined" color="secondary">
                                        No
                                    </Button>
                                </Box>
                            </DialogActions>
                        </Dialog>
                    }
                </div>
                {/* LOCATION LIST SECTION */}
                {this.state.locations &&
                    <div className="row">
                        <CardBox styleName="col-12" headerOutside heading={
                            <React.Fragment>
                                {this.state.locations.title}

                                <MsgPopover msg='Big table, scroll horizontally' timeout='1500'>
                                    <Box display={{ xs: 'inline', md: 'none' }}><SwapHoriz color="primary" /></Box></MsgPopover>
                            </React.Fragment>}>
                            <LocationList
                                locations={this.state.locations}
                                onEdit={this.onEdit}
                                onEnable={this.onEnable}
                                onSettings={this.onSettings}
                                rateModel={this.rateModel}
                                sqsCommand={this.sqsCommand}
                                parkingSlots={this.parkingSlots}
                            />
                        </CardBox>
                    </div>
                }
                <SnackBar
                    open={this.state.snackBar.snackOpen}
                    handleClose={this.setSnackState.bind(this, false, "", this.state.snackBar.snackType)}
                    message={this.state.snackBar.snackMessage}
                    type={this.state.snackBar.snackType}
                />
                {
                    this.state.sqsConfig &&
                    <SQSCommand open={this.state.sqsConfig ? true : false} sqsConfig={this.state.sqsConfig} locGuards={_.find(this.state.guards, { id: this.state.sqsConfig.id })} onClose={e => this.setState({ sqsConfig: null })} />
                }
            </div>
        )
    }
}

const mapStateToProps = function (state) {
    try {
        return { reportFlags: state.auth.authUser.resources }
    } catch (err) {
    }
}

export default connect(mapStateToProps)(ParkingLocation);
