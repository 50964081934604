import React, {useState,useEffect,useRef} from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { makeStyles,useTheme } from '@material-ui/core/styles'

import _ from 'lodash'

const useStyles = makeStyles( (theme) =>(
    {
        caption: {
            color: theme.palette.primary.main,
            lineHeight:'1em'
          },
        text: {
            color: theme.palette.secondary.main
          },
        label:{
            color: theme.palette.primary.main,
        },
        button:{
            backgroundColor:theme.palette.secondary.light,
            color:'white'
        },
        underline:{
            display:'inline-block',
            borderBottomColor: theme.palette.primary.main,
            borderBottomWidth: '2px',
            borderBottomStyle: 'solid',
        },
        buttonProgress: {
            color: theme.palette.primary.main[500],
            position: 'fixed',
            marginLeft: -60,
            marginTop:6
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
            color: theme.palette.secondary.light
        },
        chipIcon:{
            color: theme.palette.primary.main,
            height: '10px',
            width: '10px'
        },
        md15:{marginBottom:"15px"},
        md20:{marginBottom:"20px"},
        md30:{marginBottom:"30px"},
      }
))

const RenewFilter =(props)=> {
    console.log(props)
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const classes = useStyles();
    
    const [input, setInput] = useState({
        location: 0,
        client: 0
    })

    const handleLocationChange = (e,value) => {
        if(value){
            console.log(" value - ",value, input)
            setInput((prevState) => ({...prevState, location: value.id, client: 0 }));
            props.setSelectedLoc({'id':value.id, 'name':value.name});
            props.setSelectedClient({'id':0, 'name':''});
        }
    }

    const handleClientChange =(e,value) =>{
        if (value === null) {
            setInput((prevState) => ({ ...prevState, client: 0 }));
            props.setSelectedClient({ 'id': 0, 'name': '' });
        } else {
            setInput((prevState) => ({ ...prevState, client: value.id }));
            props.setSelectedClient(value);
        }
    }
    
    return(
        <div>            
            {/* FIRST ROW */}
            <div className="row">
                {/* LOCATION DROPDOWN */}
                <div className="col-lg-3 col-sm-6 col-12">
                        <FormControl className="w-100">
                            <InputLabel shrink={true} htmlFor="location-input" className={classes.label}>Location</InputLabel>
                            <Autocomplete
                                // size={props.flags.multiLocation ? "small" : ""}
                                value={_.find(props.locations,{id:input.location})}
                                onChange={handleLocationChange}
                                id="location"
                                name="location"
                                style={{marginTop:0}}
                                options={props.locations}
                                getOptionLabel={(option) => option.identifier}
                                renderInput={(params) => (
                                    <TextField id="location-input" {...params} margin="normal"/>
                                )}
                            >
                            </Autocomplete>
                        </FormControl>
                    </div>
                {/* CLIENTS DROPDOWN */}
                <div className="col-lg-3 col-sm-6 col-12">
                    <FormControl className="w-100" mr="50px">
                        <InputLabel shrink={true} htmlFor="client-input" className={classes.label}>Clients</InputLabel>
                        <Autocomplete
                            value={_.find(props.clients, { id: input.client }) || 0}
                            onChange={handleClientChange}
                            id="client"
                            name="client"
                            style={{marginTop:0}}
                            options={_.find(props.locations, { id: input.location })?.clients || []}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField id="client-input" {...params} margin="normal"/>
                            )}
                        >
                        </Autocomplete>
                    </FormControl>
                </div>
               
                {/* BUTTONS */}
                {/* <div className="row mt-2">    */}
                <div className="col-lg-3 col-sm-12 col-12">
                    <Box mt="20px">
                        <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" onClick={() => props.getReportData(input)}> Get Details </Button></Box>
                    </Box>
                </div> 
                {/* </div> */}
            </div>
        </div>    
    )
}


export default RenewFilter