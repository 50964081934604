import React, { useState } from 'react';
import mapStyles from "components/Map/MISMapStyles.json"
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { GoogleApiWrapper } from 'google-maps-react';

const mapOptions = {
    mapTypeControl: false, // Disable map type controls
    streetViewControl: false, // Disable street view controls (optional)
    fullscreenControl: true, // Disable fullscreen control (optional)
    styles: mapStyles,
    scrollwheel: false
};
// Function to convert SVG string to data URL
const getIconDataURL = (svgString) => {
    const encodedSVG = encodeURIComponent(svgString);
    return `data:image/svg+xml;charset=utf-8,${encodedSVG}`;
};

const mapContainerStyle = {
    width: '100%',
    height: '400px',
    position: 'relative',
    margin: "20px auto 20px auto",
};
const CirclePos = `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="6" fill="#f39813" /></svg>`;
const CircleHw = `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="6" fill="#0077cc" /></svg>`;

const EZMarker = (props) => {
    const [showInfo, setShowInfo] = useState(false);

    return (
        <>
            <Marker
                position={props.position}
                icon={{
                    url: props.type == 'pos' ? getIconDataURL(CirclePos) : getIconDataURL(CircleHw), // URL of the marker icon
                }}
                title={props.name}
                onClick={() => setShowInfo(true)}
            />
            {showInfo &&
                <InfoWindow
                    position={props.position}
                    onCloseClick={() => setShowInfo(false)}
                >
                    <div>
                        <h4>{props.type}</h4>
                        <p>{props.name}</p>
                    </div>
                </InfoWindow>}
        </>
    )
}

const EZMap = (props) => {
    console.log('EZMapV2 props - ', props);

    return (
        <>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={4}
                options={mapOptions}
                center={{ lat: 20.5937, lng: 78.9629 }}
            >
                <>
                    {
                        props.markers.map((location, index) => (
                            location.type && <EZMarker
                                key={index}
                                position={{ lat: parseFloat(location.lat), lng: parseFloat(location.long) }}
                                type={location.type}
                                name={location.name}
                            />
                        ))
                    }
                </>
            </GoogleMap>
            <center>
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="7" fill="#f39813" /></svg> POS
                &nbsp;&nbsp;
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="7" fill="#0077cc" /></svg> Hardware
            </center>
        </>
    );
};

export default GoogleApiWrapper({ apiKey: process.env.REACT_APP_GMAP_KEY })(EZMap)