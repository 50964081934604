import React, { useEffect } from 'react';
import { Form, connect } from 'formik'
import FormField from 'components/FormField'
import { Box, Button } from '@material-ui/core'
import _ from 'lodash'


const EZField = connect((props) => {

    let field = _.cloneDeep(props.field)
    let values = props.values

    if(field.dependency){
        let fieldValue = field.dependency ? values[field.dependency]:null

        if(field.key === 'phone_no' && fieldValue && !field.dependencyValue.includes(fieldValue.value) && values['vehicle_no'].length > 4){
            let resetValue = field.value
            let currentValue = values[field.key]
            
            if(currentValue != resetValue){
                props.formik.setFieldValue(field.key,resetValue)
            }
            return(null)
        }
    }

    return (
        <div className="col-md-3 col-12" key={props.key} >
            <FormField
                field={{ ...props.field }}
            />
        </div>
    )
})

const FormDetails = (props) => {

    useEffect(() => {
        if (props.refresh === true) {
            props.formik.resetForm();
            props.resetRefresh();
        }
    }, [props.refresh])

    useEffect(() => {
        props.formik.setFieldValue('free_id', '');
        if (props.formik.values.location_id?.reasons) {
            const config = _.cloneDeep(props.initialConfig);
            config.free_id.options = props.formik.values.location_id.reasons;
            props.updateConfig(config);
        }
    }, [props.formik.values.location_id])

    return (
        <Form>
            {/* FIRST ROW */}
            <div className="row">
                {
                    Object.values(props.initialConfig).map(field => {

                        return (
                            !field.hidden && <EZField
                                key={`field-${field.key}`}
                                field={{
                                    ...field,
                                    ...{ name: field.key }
                                }}
                                values={props.formik.values}
                            />
                        )
                    })
                }
            </div>
            <div className="row mt-2">
                <div className="col-lg-6 col-sm-12 col-12">
                    <Box mt="20px">
                        <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" type="submit" > Submit </Button></Box>
                        <Box mr="10px" display="inline"><Button variant="outlined" color="secondary" type="reset">Reset</Button></Box>
                    </Box>
                </div>
            </div>
        </Form>
    )
}
export const AddFreeDetails = connect(FormDetails)