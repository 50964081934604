import React, { useEffect, useState } from "react";
import API from "util/Api";
import { Formik } from "formik";
import SnackBar from "components/SnackBar";
import { Form, connect } from "formik";
import { Button, Box } from "@material-ui/core";
import FormField from "components/FormField";
import ContainerHeader from "components/ContainerHeader";
import _ from "lodash";
import CardBox from "components/CardBox";
import * as Yup from "yup";
// import dummyData from "./dummy";

const UpdateRateConfig = connect((props) => {
  console.log('rate-config-props',props);
  const [fields, setFields] = useState(props.section.fields);
  useEffect(()=>{
    let fieldsClone = _.cloneDeep(props.section.fields);
    fieldsClone.vehicle_type_id.options = _.filter(props.section.fields.vehicle_type_id.options, { 'location_id': props.formik.values.location_id?.value });
    fieldsClone.rate_model_id.options = _.filter(props.section.fields.rate_model_id.options, {'location_id': props.formik.values.location_id?.value, 'vehicle_type_id': props.formik.values.vehicle_type_id });
    setFields(fieldsClone);
    props.formik.setFieldValue('rate_model_id',"");
    console.log('fields',fields)
  },[props.formik.values.location_id, props.formik.values.vehicle_type_id])
  
  return (
    <div className="row">
      <CardBox styleName="col-12">
      <Form>
        {/* FIRST ROW */}
        <div className="row">
          {Object.values(fields).map((field) => (
           field && <div className="col-md-3 col-12" key={`field-${field.key}`}>
              <FormField field={{ ...field, ...{ name: field.key } }} />
            </div>
          ))}
        </div>
        {/* BUTTONS */}
        <div className="row mt-2">
          <div className="col-lg-6 col-sm-12 col-12">
            <Box mt="20px">
              <Box mr="10px" display="inline">
                <Button
                  variant="contained"
                  color="primary"
                  mr="5px"
                  type="submit"
                >
                  {" "}
                  Update{" "}
                </Button>
              </Box>
              <Box mr="10px" display="inline">
                <Button variant="outlined" color="secondary" type="reset">
                  Reset
                </Button>
              </Box>
            </Box>
          </div>
        </div>
      </Form>
    </CardBox>
    </div>
  );
});

const UpdateRateModel = (props) => {
  const [config, setConfig] = useState(null);
  const [initialValues, setInitialValues] = useState();
  const [snackBar, setSnackBar] = useState({
    open: false,
    message: "",
    type: "error",
  });
  const validations = Yup.object().shape({
    location_id: Yup.object().required("Required"),
    vehicle_type_id: Yup.string().required("Required"),
    vehicle_no: Yup.string().required("Required"),
    rate_model_id: Yup.string().required("Required"),
  });

  const update = (data, form) => {
    API.put("parkingRate", JSON.stringify(data))
      .then((res) => {
        form.setSubmitting(false);
        form.resetForm();
        setSnackBar({
          open: true,
          message: res.data.msg,
          type: "success",
        });
      })
      .catch((err) => {
        console.log("error", err);
        form.setSubmitting(false);
        setSnackBar({
          open: true,
          message: err?.response?.data?.msg?err.response.data.msg:"Something went wrong",
          type: "error",
        });
      });
  };
  useEffect(() => {
    API.get("parkingRate")
      .then((res) => {
        // res.data=dummyData;
        setConfig(res.data.data);
        setInitialValues(res.data.data.values);
      })
      .catch((err) => {
        setSnackBar({
          open: true,
          message: err?.response?.data?.msg?err.response.data.msg:"Something went wrong",
          type: "error",
        });
      });
  }, []);

  return (
    <>
    {config && <ContainerHeader
        title={config.title}
        match={props.match}
      />}
      {config && initialValues && (
        <Formik
          onSubmit={update}
          initialValues={initialValues}
          validationSchema={validations}
        >
        <UpdateRateConfig section={config} />
        </Formik>
      )}

      <SnackBar
        {...snackBar}
        handleClose={() => {
          setSnackBar({ open: false, message: "" });
        }}
      />
    </>
  );
};
export default UpdateRateModel;
