import React from 'react'
import ConfigPage from '../../../Resources/routes/ParkingLocation/ConfigPage'

/**
 * Parent component for the page
 * 
 * @param {*} props 
 */
const Configurations = (props) => {
    
    return(
        <ConfigPage url='valetV2LocConfig' title='Valet Locations' {...props} />
    ) 
}

export default Configurations;