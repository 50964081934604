import React, { useEffect, useState } from "react";
import API from "util/Api";
import ActionsTable from "components/ActionsTable/TableList";
import { Formik } from "formik";
import SnackBar from "components/SnackBar";
import { Form, connect, useFormikContext } from "formik";
import { Button, Box } from "@material-ui/core";
import FormField from "components/FormField";
import _ from "lodash";
import CardBox from "components/CardBox";
import * as Yup from "yup";
import PopUpDialog from 'components/PopUpDialog';

const LocationForm = connect((props) => {
    const { isSubmitting } = useFormikContext();
    console.log('location-form-props', props)
    useEffect(() => {
        if (props.location) {
            let data = {};
            for (let key in props.location) {
                data[key] = props.location[key].value;
            }
            if (!_.isEqual(data, props.formik.values)) {
                props.formik.setValues(data);
            }
            props.setLocation(undefined)
        }
    }, [props.location]);

    return (
        <CardBox styleName="col-12" heading={props.section.title}>
            <Form>
                {/* FIRST ROW */}
                <div className="row">
                    {props.section.fields.map((field) => (
                        <div className="col-md-3 col-12" key={`field-${field.key}`}>
                            <FormField field={{ ...field, ...{ name: field.key } }} />
                        </div>
                    ))}
                </div>
                {/* BUTTONS */}
                <div className="row mt-2">
                    <div className="col-lg-6 col-sm-12 col-12">
                        <Box mt="20px">
                            <Box mr="10px" display="inline">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    mr="5px"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    {" "}
                                    Submit{" "}
                                </Button>
                            </Box>
                            <Box mr="10px" display="inline">
                                <Button variant="outlined" color="secondary" type="reset" disabled={isSubmitting}>
                                    Reset
                                </Button>
                            </Box>
                        </Box>
                    </div>
                </div>
            </Form>
        </CardBox>
    );
})

const AccessLocations = (props) => {
    const [config, setConfig] = useState();
    const [locations, setLocations] = useState();
    const [location, setLocation] = useState();
    const [snackBar, setSnackBar] = useState({
        open: false,
        msg: "",
        type: "error",
    });
    const [dialog, setDialog] = useState({open:false,content:'',header:'',agreeBtnTxt:'',disAgreeBtnTxt:'', type:'', callback:null});
    const validations = Yup.object().shape({
        name: Yup.string().required("Required"),
        city: Yup.object().required("Required"),
    });

    useEffect(() => {
        API.get("accessLocations")
            .then((res) => {
                setConfig(res.data.data.config)
                setLocations(res.data.data.locations)
            })
            .catch((err) => { });
    }, []);

    const handleSubmit = (data, form) => {
        API.post("accessLocations", JSON.stringify(data))
            .then((res) => {
                setLocations(res.data.data);
                form.setSubmitting(false);
                form.resetForm();
                setSnackBar({ open: true, msg: res.data.msg, type: "success" });
            })
            .catch((err) => {
                form.setSubmitting(false);
                setSnackBar({
                    open: true,
                    msg: err?.response?.data?.msg ? err.response.data.msg : "Someting went wrong",
                    type: "error",
                });
            });
    }

    const handleEdit = (row) => {
        setLocation(row)
        // scroll to top
        document.body.style.height = "auto";
        setTimeout(function () {
            document.body.style.height = "100%";
        }, 50);
    }

    const subLocation = (clickedLoc) => {
        console.log('sub-loc-clicked', props)
        props.history.push({
            pathname: `${props.match.url}/sub-locations/${clickedLoc.name.value.replace(/\s+/g, "-").toLowerCase()}`,
            state: clickedLoc,
        });
    };

    const updateStatus = (id, status, cb) => {
        API.put("accessLocationStatus", JSON.stringify({ id: id, active: status }))
            .then((res) => {
                setSnackBar({ open: true, msg: res.data.msg, type: "success" });
                cb(status)
            })
            .catch((err) => {
                console.log("error", err);
                try {
                    setSnackBar({ open: true, msg: err.response.data.msg, type: "error" });
                } catch {
                    setSnackBar({ open: true, msg: "Something went wrong", type: "error" });
                }
            });
        handleDialogClose();
    };

    const updateActiveStatus = (id, checked, cb)=> {
        let accessLocation = _.find(locations.body, (accessLocation) => accessLocation.id.value === id);
        setDialog({
            open:true,
            header:`${checked == true ? 'Activate': 'Deactivate'} Access Location`,
            content:`Are you sure you want to ${checked ? 'activate': 'deactivate'} ${accessLocation.name.value}?`,
            agreeBtnTxt:'Confirm',
            disAgreeBtnTxt:'Cancel',
            type:'updateActiveStatus',
            location:{id:id, active:checked},
            callback:cb
        });
    }

    const confirmDialogSubmit = (type) =>{
        if(type === 'updateActiveStatus'){
            updateStatus(dialog.location.id, dialog.location.active, dialog.callback);
        }
    }

    const handleDialogClose = ()=>{
        setDialog({
            open:false,
            content:'',
            header:'',
            agreeBtnTxt:'',
            disAgreeBtnTxt:'',
            type:'',
            location:null,
            callback:null
        });
    }

    return (
        <>
            {/* <ContainerHeader
                title={`Access Locations`}
                match={props.match}
            /> */}
            {config && <Formik
                onSubmit={handleSubmit}
                initialValues={config.values}
                validationSchema={validations}
            >
                <LocationForm section={config} location={location} setLocation={setLocation} />
            </Formik>}
            {locations && <ActionsTable
                tableHeading={locations.title}
                table={locations}
                onEdit={handleEdit}
                subLocation={subLocation}
                updateStatus={updateActiveStatus}
                actions={true}
            />}
            <SnackBar
                open={snackBar.open}
                handleClose={() => {
                    setSnackBar({ open: false, msg: "" });
                }}
                message={snackBar.msg}
                type={snackBar.type}
            />
            <PopUpDialog
                fullWidth
                open={dialog.open}
                header={dialog.header}
                content ={dialog.content}
                agreeBtnTxt ={dialog.agreeBtnTxt}
                disAgreeBtnTxt={dialog.disAgreeBtnTxt}
                agreeClicked ={() =>confirmDialogSubmit(dialog.type)}
                handleDialogClose={handleDialogClose}
            />
        </>
    );
}
export default AccessLocations;