import React, { useEffect, useState, useRef } from "react";
import { Dialog, AppBar, IconButton, Toolbar, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close'
import * as Yup from "yup";
import _ from "lodash";
import { useParams } from 'react-router-dom';
import SnackBar from "components/SnackBar";
import ContainerHeader from "components/ContainerHeader";
import CardBox from "components/CardBox";
import SimpleTable from "components/ActionsTable/SimpleTable";
import EZForm from "components/EZForm";
import API from "util/Api";
import { DialogForm } from "components/PopUpDialog";
import { decode } from 'util/secure';

const MarkSlot = (props) => {
    console.log('MarkSlot-props', props);
    const canvasRef = useRef();
    const [slot, setSlot] = useState({ x: 0, y: 0, h: 0, w: 0 });

    const useStyles = makeStyles((theme) => (
        {
            mapButton: {
                marginTop: '17px',
                color: theme.palette.secondary.light,
            },
            label: {
                color: theme.palette.primary.main,
                marginTop: '0px'
            },
            appBar: {
                position: 'relative',
            },
            title: {
                marginLeft: theme.spacing(2),
                flex: 1,
            },
            imgErr: {
                color: 'red'
            }
        }
    ))
    const classes = useStyles();

    const handleMouseDown = (e) => {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        const canvasRelativeX = x * canvas.width / rect.width;
        const canvasRelativeY = y * canvas.height / rect.height;
        setSlot({ x: canvasRelativeX, y: canvasRelativeY, h: 0, w: 0 })
    };

    const handleMouseUp = (e) => {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        const canvasRelativeX = x * canvas.width / rect.width;
        const canvasRelativeY = y * canvas.height / rect.height;
        setSlot({ w: Math.abs(canvasRelativeX - slot.x), h: Math.abs(canvasRelativeY - slot.y), x: slot.x, y: slot.y })
    };

    const clearCanvas = () => {
        const image = new Image();
        const canvas = canvasRef.current;

        image.src = props.data.floor_map_url;
        image.onload = () => {
            const context = canvas.getContext('2d');
            context.reset();
            // Set canvas size to match image size
            canvas.width = image.width;
            canvas.height = image.height;
            context.drawImage(image, 0, 0);
        };

    }

    useEffect(() => {
        if (props.data) {
            clearCanvas();
            if (props.data.slot_coordinates) {
                setSlot(JSON.parse(props.data.slot_coordinates));
            }
        }
    }, [props.data])

    useEffect(() => {
        console.log('slot', slot);
        const canvas = canvasRef.current;
        if (canvas) {
            const context = canvas.getContext('2d');
            if (slot.x && slot.y && slot.h && slot.w) {
                context.fillStyle = "#f9a825";
                context.fillRect(slot.x, slot.y, slot.w, slot.h);
            } else {
                clearCanvas();
            }
        }
    }, [slot]);

    return (
        <>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => { props.setDialog({ open: false }) }} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Mark Slot for <span style={{ textDecoration: "underline" }}>{props.data?.slot_no}</span>
                    </Typography>
                    <Button autoFocus variant="contained" color="secondary" onClick={() => { props.onSubmit({ id: props.data?.id, coord: slot }) }}>
                        save
                    </Button>
                </Toolbar>
            </AppBar>
            <div style={{ height: '100%', overflowY: 'auto' }}>
                <canvas
                    ref={canvasRef}
                    style={{ width: '100%' }}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                />
            </div>
        </>
    )
}

const ParkingSlots = (props) => {
    const { id } = useParams();
    const locId = decode(id)[0];
    const [config, setConfig] = useState();
    const [editConfig, setEditConfig] = useState();
    const [table, setTable] = useState();
    const [snackBar, setSnackBar] = useState({
        open: false,
        message: "",
        type: "error",
    });
    const [dialog, setDialog] = useState({ open: false });
    const [editDialog, setEditDialog] = useState({ open: false });

    const validations = Yup.object().shape({
        floor: Yup.object().required("Required"),
        slots: Yup.string().required("Required"),
    });

    const slotValidation = Yup.object().shape({
        floor: Yup.object().required("Required"),
        slot_no: Yup.string().required("Required"),
    });

    const handleChange = (formik, con, setCon) => {
        console.log('handleChange - ', formik)
        const values = formik.values;
        if (values.floor && !_.isEqual(values.floor.areas, con.fields.area_id.options)) {
            formik.setFieldValue('area_id', '');
            const configData = _.cloneDeep(con);
            configData.fields.area_id.options = values.floor.areas;
            setCon(configData);
        }
    }

    const handleSubmit = (data, form) => {
        API.post("parkingSlots", JSON.stringify({ ...data, location_id: locId }))
            .then((res) => {
                setTable(res.data.data);
                form.setSubmitting(false);
                form.resetForm();
                setSnackBar({
                    open: true,
                    message: res.data?.msg,
                    type: "success",
                });
            })
            .catch((err) => {
                console.log("error", err);
                form.setSubmitting(false);
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong",
                    type: "error",
                });
            });
    }

    const handleSlotMark = (data) => {
        API.post("parkingSlotMap", JSON.stringify(data))
            .then((res) => {
                dialog.setCoord(res.data.data?.slot_coordinates);
                setDialog({ open: false })
                setSnackBar({
                    open: true,
                    message: res.data.msg,
                    type: "success",
                });
            })
            .catch((err) => {
                console.log("error", err);
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong",
                    type: "error",
                });
            });
    }

    const openDialog = (slot, setCoord) => {
        API.get("parkingSlotMap", { params: { id: slot.id } })
            .then((res) => {
                setDialog({ open: true, data: res.data.data, setCoord: setCoord })
            })
            .catch((err) => {
                console.log("error", err);
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong",
                    type: "error",
                });
            });
    }

    const updateSlot = (data, form) => {
        API.put("parkingSlot", JSON.stringify(data))
            .then((res) => {
                setEditDialog({ open: false })
                setTable(res.data.data);
                form.setSubmitting(false);
                form.resetForm();
                setSnackBar({
                    open: true,
                    message: res.data?.msg,
                    type: "success",
                });
            })
            .catch((err) => {
                console.log("error", err);
                setEditDialog({ open: false })
                form.setSubmitting(false);
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ?? "Something went wrong",
                    type: "error",
                });
            });
    }

    const handleEdit = (slot) => {
        API.get("parkingSlot", { params: { id: slot.id } })
            .then((res) => {
                setEditDialog({ open: true })
                setEditConfig(res.data.data)
            })
            .catch((err) => {
                console.log("error", err);
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ?? "Something went wrong",
                    type: "error",
                });
            });
    }

    useEffect(() => {
        API.get("parkingSlots", { params: { location_id: locId } })
            .then((res) => {
                setConfig(res.data.data.config);
                setTable(res.data.data.table);
            })
            .catch((err) => {
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong",
                    type: "error",
                });
            });
    }, []);

    return (
        <>
            <ContainerHeader title={config?.title} match={props.match} />
            {config &&
                <EZForm
                    fields={Object.values(config.fields)}
                    handleChange={(formik) => handleChange(formik, config, setConfig)}
                    onSubmit={handleSubmit}
                    validationSchema={validations}
                />}
            {table &&
                <div className="row">
                    <CardBox styleName="col-12">
                        <SimpleTable
                            type={'parkingSlots'}
                            tableHeading={table.title}
                            table={table}
                            markSlot={openDialog}
                            onEdit={handleEdit}
                        />
                    </CardBox>
                </div>
            }
            {editConfig &&
                <DialogForm
                    open={editDialog.open}
                    header="Update Slot"
                    fields={Object.values(editConfig.fields)}
                    validationSchema={slotValidation}
                    onClose={() => {
                        setEditDialog({ open: false })
                        setEditConfig(null)
                    }}
                    onSubmit={updateSlot}
                    handleChange={(formik) => handleChange(formik, editConfig, setEditConfig)}
                />
            }
            <SnackBar
                {...snackBar}
                handleClose={() => {
                    setSnackBar({ open: false, message: "" });
                }}
            />
            <Dialog
                open={dialog.open}
                fullScreen={true}
                disableEscapeKeyDown
            >
                <MarkSlot data={dialog.data} setDialog={setDialog} onSubmit={handleSlotMark} />
            </Dialog>
        </>
    )
}
export default ParkingSlots;